import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

import {AuthService} from '../auth/auth_service';
import {Asset} from '../services/asset_service';
import {SnackBarService} from '../services/snackbar_service';

/**
 * For internal users shows a button to copy asset name. For external users
 * turns into an icon or nothing based on settings.
 */
@Component({
  selector: 'mam-asset-info-icon-button',
  templateUrl: './asset_info_icon_button.ng.html',
  styleUrls: ['./asset_info_icon_button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetInfoIconButton {
  @HostBinding('class.internal') internal = this.isInternal();
  @HostBinding('class.compact')
  get compact() {
    return !this.showIconForExternalUsers;
  }
  @HostBinding('class.spinner')
  get isSpinner() {
    return this.icon === 'spinner';
  }

  /** Asset whose name will be copied. */
  @Input() asset?: Asset;

  /**
   * Icon to use. Defaults to bug_report.
   * Set to 'spinner' to display mat-spinner.
   */
  @Input() icon = 'bug_report';

  /** Force a red spinner. */
  @Input() redSpinner: boolean = true;

  /**
   * Whether to hide the button completely for external users or keep
   * showing the icon.
   */
  @Input() showIconForExternalUsers = false;

  @Input() tooltip = '';

  constructor(
      private readonly snackbar: SnackBarService,
      private readonly authService: AuthService) {}

  showAssetInfoCopiedMessage() {
    this.snackbar.message('Asset name copied');
  }

  getAssetInfo() {
    return this.asset?.name ?? '';
  }

  isInternal() {
    return this.authService.isInternalUser();
  }
}
