import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isErrorResponse } from '../error_service/error_response';
import { BinService } from '../services/bin.service';

@Component({
  selector: 'mam-export-monitor-clipbin-cell',
  templateUrl: './export_monitor_clip_bin_cell.ng.html',
  styleUrls: ['./export_monitor_clip_bin_cell.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMonitorClipBinCell implements OnInit, OnChanges {
  /** Clip bin name to fetch title */
  @Input({required: true}) clipBinName: string = '';
  /** If title already loaded, send the title to maintain performance and avoid re-loading */
  @Input() title: string = '';
  clipBinTitle$: Observable<string> | undefined;

  constructor(protected readonly binService: BinService) { }

  ngOnChanges( value: SimpleChanges) {
    if(value['clipBinName'].currentValue !== value['clipBinName'].previousValue) {
      this.fetchClipBinTitle();
    }
  }

  ngOnInit() {
    this.fetchClipBinTitle();
  }

  fetchClipBinTitle() {
    this.clipBinTitle$ = this.binService.getBin(this.clipBinName).pipe(
      map( binResponse =>
        isErrorResponse(binResponse) ? '--' : binResponse.title
      ),
    );
  }
}
