import { Timestamp } from '@firebase/firestore';

import { UserAccess } from '../../admin/admin_users_service';
import { ApiCopyStateDetail } from '../../api/ias/model/models';

export enum PermissionEnum {
  GRANTED = 'GRANTED',
}

export interface PermissionDetail {
  userId: string;
  displayName: string;
  permission: PermissionEnum;
  updatedAt: Timestamp;
  updatedBy: string;
  validUntil?: Timestamp;
}

export type ResourceAccessTypeEnum = 'RESTRICTION';

export type ResourceTypeEnum = 'ASSET' | 'CUTDOWN';

export interface ResourceAccessInfo {
  resourceId: string;
  resourceAccessType: ResourceAccessTypeEnum;
  resourceType: ResourceTypeEnum;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  permissions: PermissionDetail[];
  params?:string;
  /**Firebase documentId */
  documentId?: string;
  /**Title */
  title:string;
}

export interface ResourceAccessCutDown {
  /**
   * The original asset from a Cutdown
   */
  originalAssetId: string;
  resourceAccessType: ResourceAccessTypeEnum;
  resourceType: ResourceTypeEnum;
  oldResourceType: ResourceAccessTypeEnum;
  title:string;
  /**
   * Is the property name on cutdown
   */
  resourcePathId: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  permissions: PermissionDetail[];
  documentId: string;
  cutDownState: ApiCopyStateDetail.StateEnum;
  parentAssetNameId: string;
  convertToAsset?: boolean;
  convertedToAssetAt?: Timestamp;
}

export interface ResourceAccessUser {
  displayName: string;
  email: string;
  access: UserAccess;
}
