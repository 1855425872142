import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {debounceTime, ReplaySubject, takeUntil} from 'rxjs';

import {assertExists} from 'asserts/asserts';

import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {FacetBucket, FacetChangeEvent, FacetGroup, SearchFacetService} from '../services/search_facet_service';
import {SnackBarService} from '../services/snackbar_service';

/**
 * Component for a facet group which include facet string and facet results
 */
@Component({
  selector: 'mam-search-facet-group',
  templateUrl: './search_facet_group.ng.html',
  styleUrls: ['./search_facet_group.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFacetGroup implements OnDestroy {
  @ViewChildren('matCheckbox') matCheckboxList?: QueryList<MatCheckbox>;

  @Input()
  set facetGroup(facetGroup: FacetGroup) {
    if (this.enableFavoritesFF) {
      this.originalFavoriteOptions = this.searchFacetService.getFavoriteOptions(facetGroup.facet);
      facetGroup.facetBuckets.forEach(b => b.isFavorite = this.originalFavoriteOptions.includes(b.value));
    }

    this.facetGroupInternal = facetGroup;
  }
  get facetGroup(): FacetGroup {
    return this.facetGroupInternal;
  }

  private facetGroupInternal!: FacetGroup;

  @Input() facetQuery = '';

  @Output() readonly facetChange = new EventEmitter<FacetChangeEvent>();
  @Output() readonly menuClosed = new EventEmitter<string>();
  menuStatus = MenuStatus.CLOSED;
  readonly MenuStatus = MenuStatus;

  /** Selected or favorite buckets. */
  prioritizedBuckets: FacetBucket[] = [];
  /** Remain buckets. */
  alphabeticalBuckets: FacetBucket[] = [];

  /** Form control for options filtering input. */
  filterOptionsControl = new FormControl<string>('');

  originalFavoriteOptions: string[] = [];

  lastClickedBucket?: FacetBucket;

  /** Flag to enable filtering. */
  readonly enableFilteringFF =
    this.featureService.featureOn('enable-filtering-for-facets-dropdowns');

  /** Flag to enable favorites. */
  readonly enableFavoritesFF =
    this.featureService.featureOn('enable-favorites-for-facets-dropdowns');

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
      private readonly cdr: ChangeDetectorRef,
      private readonly searchFacetService: SearchFacetService,
      private readonly snackBar: SnackBarService,
      private readonly featureService: FeatureFlagService,
  ) {
    this.filterOptionsControl.valueChanges
        .pipe(takeUntil(this.destroyed$), debounceTime(300))
        .subscribe(value => {
            const filterText = value?.toLowerCase() || '';
            const filteredBuckets = this.facetGroupInternal.facetBuckets.filter(b =>
                b.value.toLowerCase().includes(filterText));
            [this.prioritizedBuckets, this.alphabeticalBuckets] = this.splitBuckets(filteredBuckets);
            this.cdr.detectChanges();
    });
  }

  get numOfBuckets(): number {
    return this.facetGroupInternal.facetBuckets.length;
  }

  /**
   * If no facet option in the group is selected, the text is the
   * display name of the facet group. If only one facet option in the group is
   * selected, the text is "display name: selected option value". If multiple
   * options in the group are selected, text is "display name: first selected
   * option value +number"
   */
  getDisplayText(): string {
    const selectedBucketsNum = this.getSelectedBucketsNum();
    if (selectedBucketsNum === 0) {
      return this.facetGroup.displayedName;
    }

    const firstSelectedBucket =
        this.facetGroup.facetBuckets.find(bucket => bucket.isSelected);

    assertExists(firstSelectedBucket);

    if (selectedBucketsNum === 1) {
      return `${this.facetGroup.displayedName}: ${firstSelectedBucket.value}`;
    }

    return `${this.facetGroup.displayedName}: ${firstSelectedBucket.value} + ${
        selectedBucketsNum - 1}`;
  }

  getSelectedBucketsNum(): number {
    if (!this.facetGroup) {
      return 0;
    }

    return this.facetGroup.facetBuckets.filter(bucket => bucket.isSelected).length;
  }

  onChange(event: MatCheckboxChange) {
    // Each time when selecting or unselecting a facet option, makes up the
    // facetQuery based on the current event.
    let facetBucket!: FacetBucket;
    try {
      facetBucket = JSON.parse(event.source.value) as FacetBucket;
    } catch {
      this.snackBar.error(
          'Failed to update search facets', undefined,
          {'facet': event.source.value});
      return;
    }

    this.facetQuery = this.searchFacetService.makeQuery({
      checked: event.checked,
      id: event.source.id,
      facetGroup: this.facetGroup,
      facetBucket,
    });
  }

  /**
   * Passes facetQuery to parent component and append it to the url when the
   * menu is closed.
   */
  onMenuClose() {
    this.menuStatus = MenuStatus.CLOSED;
    this.menuClosed.emit(this.facetQuery);

    if (this.enableFavoritesFF) {
      const actualFavoriteOptions = this.calculateActualFavoriteOptions();
      if (!compare(actualFavoriteOptions, this.originalFavoriteOptions)) {
        this.searchFacetService.updateFavoriteOptions(this.facetGroup.facet, actualFavoriteOptions);
        this.originalFavoriteOptions = actualFavoriteOptions;
      }
    }
  }

  private calculateActualFavoriteOptions() {
    const allOptions = new Set(this.facetGroup.facetBuckets.map(b => b.value));
    const markedFavoriteOptions = this.facetGroup.facetBuckets.filter(b => b.isFavorite).map(b => b.value);
    const originalFavoriteOptions = this.originalFavoriteOptions.filter(o => !allOptions.has(o));
    const actualFavoriteOptions = [...markedFavoriteOptions, ...originalFavoriteOptions];
    return actualFavoriteOptions;
  }

  onMenuOpen() {
    this.filterOptionsControl.reset();
    this.menuStatus = MenuStatus.OPENED;
    [this.prioritizedBuckets, this.alphabeticalBuckets] = this.splitBuckets(this.facetGroup.facetBuckets);

    this.cdr.detectChanges();
    this.extractLabelsScrollWidth();
  }

  /**
   * Order facet values alphabetically, with selected ones before unselected
   * ones.
   */
  private orderBuckets(facetBuckets: FacetBucket[]) {
    return [...facetBuckets].sort((bucketA, bucketB) => {
      // Higher priority for selected buckets.
      if (bucketA.isSelected && !bucketB.isSelected) return -1;
      if (!bucketA.isSelected && bucketB.isSelected) return 1;

      // Higher priority for favorite buckets.
      if (bucketA.isFavorite && !bucketB.isFavorite) return -1;
      if (!bucketA.isFavorite && bucketB.isFavorite) return 1;

      // Then for the same selection state, order alphabetically.
      const [valueA, valueB] = [bucketA.value, bucketB.value];

      // Use descending alphabetical order for years.
      if (this.isYearValue(valueA) && this.isYearValue(valueB)) {
        return valueB.toLocaleLowerCase().localeCompare(
            valueA.toLocaleLowerCase());
      }

      // Ascending otherwise.
      return valueA.toLocaleLowerCase().localeCompare(
          valueB.toLocaleLowerCase());
    });
  }

  /**
   * Splits buckets into two parts:
   * - selected or/and favorite buckets (prioritized buckets);
   * - remain buckets;
   */
  private splitBuckets(facetBuckets: FacetBucket[]) {
    const orderedBuckets = this.orderBuckets(facetBuckets);
    const prioritizedBucketsNum = orderedBuckets.filter(b => b.isSelected || b.isFavorite).length;

    return [orderedBuckets.slice(0, prioritizedBucketsNum), orderedBuckets.slice(prioritizedBucketsNum)];
  }

  /**
   * Whether the value is likely a year or month for which we want descending
   * alphabetical order.The first four digits must be between 1900 and 2099.
   *
   * Valid examples:
   * - 2022
   * - 1950-2000
   * - 2021-22
   */
  private isYearValue(value: string): boolean {
    return /(19|20)\d{2}(?:[- :]\d{2,4})?$/.test(value);
  }

  tooltipDisabled(facetBucket: FacetBucket): boolean {
    return (facetBucket.scrollWidth || 0) <= this.calculateMaxWidth();
  }

  private calculateMaxWidth(): number {
    const widths = this.matCheckboxList?.map(matCheckbox => this.getLabelElement(matCheckbox).clientWidth);
    return widths ? Math.max(...widths) : 0;
  }

  private getLabelElement(matCheckbox: MatCheckbox): HTMLElement {
    return matCheckbox._elementRef.nativeElement.firstChild?.lastChild as HTMLElement;
  }

  private extractLabelsScrollWidth() {
    const buckets = [...this.prioritizedBuckets, ...this.alphabeticalBuckets];
    this.matCheckboxList?.forEach((matCheckbox, index) => {
      const labelElement = this.getLabelElement(matCheckbox);
      buckets[index].scrollWidth = labelElement.scrollWidth;
    });
  }
}

enum MenuStatus {
  CLOSED,
  OPENED
}

function compare(array1: string[], array2: string[]) {
  return JSON.stringify(array1.sort()) === JSON.stringify(array2.sort());
}
