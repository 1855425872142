import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'mam-empty-assets-card',
	templateUrl: './empty-assets-card.component.html',
	styleUrl: './empty-assets-card.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyAssetsCard {
	@Input() assetsType: string = 'videos';
  @Input() hasSearchText: boolean = false;
	@Input() helperText: boolean = false;
	@Input() loading: boolean = false;
}
