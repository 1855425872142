/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  signal,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  asyncScheduler,
  combineLatestWith,
  concatMap,
  delay,
  EMPTY,
  finalize,
  map,
  Observable,
  of,
  ReplaySubject,
  scheduled,
  take,
  takeUntil, throwError
} from 'rxjs';

import { AuthService } from '../auth/auth_service';
import { ErrorService } from '../error_service/error_service';
import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { FirebaseViewsService } from '../firebase/firebase_views_service';
import { ResizeObserverService } from '../services/resize_observer_service';
import { SnackBarService } from '../services/snackbar_service';
import { StateService } from '../services/state_service';
import { TableUtils } from '../services/table_utils';

import { DeleteViewDialog } from './ui-table-view-delete-dialog/delete-view-dialog';
import { UiTableViewNotFoundDialog } from './ui-table-view-not-found-dialog/ui-table-view-not-found-dialog';
import { UpdateViewDialog } from './ui-table-view-update-dialog/update-view-dialog';
import { ComponeUiTableViewsDialogComponent } from './ui-table-views-dialog/ui-table-views-dialog.component';
import { ComponeUiTableViewsShareDialogComponent } from './ui-table-views-share-dialog/ui-table-views-share-dialog.component';
import { RowEvent, TableCol, TableOpt, TableSort, TableSortDirection, TableTypes } from './ui_table.type';
import { IasTableViewName, IasViewsSharing, IasViewTable, IasViewUser, ViewsShared } from './ui_table_view.interface';

@Component({
  selector: 'mam-ui-table',
  templateUrl: './ui_table.ng.html',
  styleUrls: ['./ui_table.scss'],
  //* Turn on ViewEncapsulation.None for avoid ng-deep on _table_mixins
  //* we need to turn this component to get styles from global css class,
  //* for example we use ng-template to create dynamic column, and we need to
  //* set comm styles on it, to avoid duplicate class let's use what is done before
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTableComponent<T extends object> implements AfterViewInit, AfterContentInit, OnDestroy, OnInit {
  /**
   * Cols from Input, default cols
   */
  @Input({ required: true }) cols: TableCol[] = [];
  @Input({ required: true  }) data: T[] = [];
  @Input() tableType: TableTypes = 'default';
  @Input() options!: TableOpt;
  @Input() emptyMessage: string = 'No data available.';
  @Input() expandedRows = new Set<string>();
  @Input() selectedRows = new Set<string>();
  @Input() activeRows = new Set<string>();
  @Input() approvedRows = new Set<string>();
  @Input() turnPublicRows = new Set<string>();
  @Input() isRowHidden: (row: any) => boolean = () => false;
  @Input() isRowDeleted: (row: any) => boolean = () => false;
  /**
   * Delay of the loading animation in milliseconds(ms).
   * Used to mask the table resize on first load
   */
  @Input() loadingDelay: number = 0;

  @Output() rowClick = new EventEmitter<any & RowEvent>();
  @Output() sortClick = new EventEmitter<TableSort>();
  @Output() containerResize = new EventEmitter<number>();

  @ContentChild('headerTpl') headerTpl!: TemplateRef<any>;
  @ContentChild('cellTpl') cellTpl!: TemplateRef<any>;
  @ContentChild('footerTpl') footerTpl!: TemplateRef<any>;
  @ContentChild('pipeTpl') pipeTpl!: TemplateRef<any>;
  @ContentChild('multiTpl') multiTpl!: TemplateRef<any>;
  @ViewChild('uiTable') uiTable!: ElementRef<HTMLDivElement>;
  @ViewChild('table', {read: ElementRef}) table!: ElementRef;
  @ViewChildren('th') ths: QueryList<ElementRef> = new QueryList();

  displayCols = signal<string[]>([]);
  uiTableWidth: number = 0;
  tableLoaded: boolean = false;
  tableResized: boolean = false;
  showUpContainer: boolean = false;

  sortDirection?: TableSortDirection;
  sortActive?: string;
  isViewSame: boolean = true;

  @Input() set activeSort(sort: TableSort) {
    this.sortDirection = sort.direction;
    this.sortActive = sort.active;
  }

  viewSelectedName?: string;
  /**
   * Cols used for changes
   */
  colsManipulation!: TableCol[];
  /**
   *  Cols copy of the default view
   */
  colsDefault!: TableCol[];
  /**
   * Cols populated with Firebase info
   */
  colsCustom: TableCol[] = [];

  readonly DEFAULT_VIEW_ID: string = 'default';
  readonly DEFAULT_VIEW_NAME: string = 'Default View';
  viewSelected: string = this.DEFAULT_VIEW_ID;
  viewsOwned: IasTableViewName | undefined;
  viewsSharedWithMe: IasTableViewName | undefined;
  viewUser: IasViewUser = {};
  showViewsIcon: boolean = false;
  // Boolean to highlight "Select View" menu item
  viewListTag: boolean = false;
  // Boolean to highlight "Views Actions" menu item
  actionListTag: boolean = false;

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private readonly analyticsService: FirebaseAnalyticsService,
    private readonly dialog: MatDialog,
    private readonly renderer: Renderer2,
    private readonly snack: SnackBarService,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly resizeObserver: ResizeObserverService,
    private readonly firebaseViewsService: FirebaseViewsService,
    private readonly stateService: StateService,
    private readonly tableUtils: TableUtils,
    private readonly errorService: ErrorService,
  ) {}

  ngOnInit() {
    /**
     * Keep the copy from original columns to recreate.
     */
    this.colsManipulation = structuredClone(this.cols);
    this.colsDefault = structuredClone(this.cols);
    this.colsCustom = structuredClone(this.cols);
  }

  ngAfterContentInit() {
    this.populateView();

    this.stateService.togglePersistentPanel$
    .pipe(
      takeUntil(this.destroyed$),
      delay(16)
    ).subscribe({
      next: async () => {
        if(this.isDefaultView() && this.isViewSame) {
          await this.setColsWidth();
        }
      }
    });
  }

  ngAfterViewInit() {
    if (!this.uiTable) return;
    this.resizeObserver.observe(this.uiTable?.nativeElement).pipe(
       take(1),
       takeUntil(this.destroyed$),
       map((uiTable) => uiTable.width),
     ).subscribe((value) => {
          this.uiTableWidth = value;
          this.containerResize.emit(value);
     });

     // Adjust table when tables are loaded
     this.ths.changes.pipe(
       take(1),
       takeUntil(this.destroyed$)
     ).subscribe();
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Sets the columns to be displayed in the table.
   * It takes into account the hidden columns and sets the columns to be displayed
   * in the order of the `order` property.
   */
  setDisplayCols() {
    const columnsToShowUp: TableCol[] = this.getColumnsNotHidden();
    columnsToShowUp.sort((a, b) => a.order - b.order);
    const colKeys = columnsToShowUp.map((c) => c.key);
    this.cols = [...columnsToShowUp];
    this.displayCols.set(colKeys);
    this.cdr.detectChanges();
    this.checkIsViewSame();
  }

  /**
   * Set Cols Width based on the widths inside colsCustom object.
   */
   async setColsWidth(tableReady: boolean = true) {
    try {
      let widthSum = 0;
      const mainElIndex = this.options?.mainColIdx ?? 0;
      const colsBase = this.isDefaultView() ? this.colsDefault : this.colsCustom;
      this.displayCols().map((column, i) => {
        if (i === mainElIndex) return;
        let columnElIdx = 0;
        const columnEl = colsBase.find((el, idx) => {
          columnElIdx = idx;
          return el.key === column;
        });
        // assigns the width
        const elWidth = columnEl?.headerStyle?.width;
        // get the template element and applies the width
        const thEl = this.ths.get(i)?.nativeElement;
        if (thEl && elWidth) {
          this.renderer.setStyle(thEl, 'width', elWidth);
        }
        if (['checkbox', 'select', 'icon'].includes(column)) {
          widthSum = widthSum - 46;
        }
        if (!(elWidth?.includes('var') || elWidth?.includes('calc'))) {
          widthSum = widthSum + Number(elWidth?.replace('px', ''));
        }
        this.colsManipulation[columnElIdx].headerStyle.width = elWidth;
        this.colsCustom[columnElIdx].headerStyle.width = elWidth;
      });

      // set main column width
      const mainEl = this.ths?.get(mainElIndex)?.nativeElement as ElementRef<HTMLTableCellElement>;
      if (tableReady && !mainEl) throwError(() => new Error('Main column not found'));

      if (this.isDefaultView()) {
        const widthContainerTable = this.uiTable.nativeElement.clientWidth;
        const widthMinMainCol = colsBase[mainElIndex].headerStyle?.minWidth ?? '100px';
        const widthTableSize = this.uiTable?.nativeElement.querySelector('table')?.clientWidth;
        if (!widthTableSize) return;
        //Container width, table width, padding width
        const gap = this.options?.widthGap ?? 0;
        const widthGapToSubtract = (!tableReady && (gap > 0)) ? (92 + gap) : 92;
        const widthFillMainCol = widthContainerTable - widthSum - (widthGapToSubtract);
        let resultSize = (widthFillMainCol) + 'px';
        if (widthFillMainCol < 0 || widthFillMainCol < +widthMinMainCol.replace('px', '')) {
          resultSize = widthMinMainCol;
        }

        this.renderer.setStyle(mainEl, 'width', resultSize);
        this.colsManipulation[mainElIndex].headerStyle.width = resultSize;
        this.colsDefault[mainElIndex].headerStyle.width = resultSize;
      }

      else {
        const newMainWidth = colsBase[mainElIndex]?.headerStyle?.width || '100px';
        this.renderer.setStyle(mainEl, 'width', newMainWidth);
      }
    }
    finally {
      this.checkIsViewSame();
      this.cdr.detectChanges();
    }
  }

  /**
   * Reset the size of the columns to the original width
   */
  async onResetColsSize() {
    await this.setColsWidth();
    this.checkIsViewSame();
    this.cdr.detectChanges();
  }

  /**
   * Reset order and width of the columns to the original values
   */
  async onResetView() {
    this.colsManipulation = structuredClone(
      this.viewSelected === this.DEFAULT_VIEW_ID ? this.colsDefault : this.mergeColumns(this.colsCustom),
    );
    this.setDisplayCols();
    await this.onResetColsSize();
    this.checkIsViewSame();
    this.cdr.detectChanges();
  }

  /**
   * Handles the sorting of the table data when a column header is clicked.
   *
   * This method is responsible for:
   * 1. Determining the new sort direction based on the clicked column.
   * 2. Updating the `sortActive` and `sortDirection` properties of the component.
   * 3. Emitting a `sortClick` event with the new sort parameters to notify the
   *    parent component of the sort change.
   *
   * @param col - The clicked `TableCol` object for sorting.
   */
  onSort(col: TableCol) {
    if (this.options.disableSorting) return;

    this.sortDirection = this.handleSortDirection(col.key);

    this.sortActive = col.key;
    this.sortClick.emit({ direction: this.sortDirection, active: this.sortActive });
  }

  /**
   * Handles the sorting direction for the given column ('asc' or 'desc') based on the
   * currently active sort column and the clicked column.
   * If the clicked column is different, it resets the sortDirection property to
   * ascending ('asc').
   *
   * @param columnKey - The key of the column that was clicked for sorting.
   * @returns The new sorting direction ('asc' or 'desc').
   */
  private handleSortDirection(columnKey: string): 'asc' | 'desc' {
    if (columnKey !== this.sortActive) return 'asc';
    return this.sortDirection === 'asc' ? 'desc' : 'asc';
  }

  /**
   * Handles columns Drag and drop rearranging the columns order
   *
   * @param e // CdkDragDrop event
   */
  onDrop(e: CdkDragDrop<string[], any, TableCol>) {
    const col = this.cols?.find((c: TableCol, i: number) => i === e.currentIndex && !c?.dragger);
    const { previousIndex, currentIndex } = e;
    if (col || previousIndex === currentIndex) return;
    moveItemInArray(this.displayCols(), previousIndex, currentIndex);
    of('')
      .pipe(delay(500))
      .subscribe({
        next: () => {
          this.reorderColumnsProperties();
        },
      });
  }

  /**
   * Set the new index on the dragged item and reset the order values on array
   *
   */
  private reorderColumnsProperties() {
    this.colsManipulation.forEach((col, index) => {
      const indexToMove = this.displayCols().indexOf(col.key);
      col.order = indexToMove !== -1 ? indexToMove : index;
    });
    const length = this.colsManipulation.length - 1;
    this.colsManipulation[length].order = length;
    this.checkIsViewSame();
  }

  /**
   * handles the row click
   *
   * @param row // current row
   * @param event // MouseEvent
   * @param index // index row number
   */
  onRowClick(row: any & RowEvent, event: MouseEvent, index: number) {
    row.event = event;
    row.index = index;
    this.rowClick.emit(row);
  }

  /**
   * Track By Index
   *
   * @param i // index
   */
  trackBy = (i: number) => i;

  /**
   * Get all the active columns, meaning, columns which the 'hidden' field set as false.
   *
   * @returns columns which is not hidden
   */
  private getColumnsNotHidden(): TableCol[] {
    const columns: TableCol[] = [];
    this.colsManipulation.forEach((column) => {
      if (!column.hidden) {
        columns.push(column);
      }
    });
    return columns;
  }

  /**
   * Toggles the visibility of the given column and updates the displayed columns.
   *
   * @param col - The column to toggle.
   */
  addAndRemoveColumns(col: TableCol) {
    col.hidden = !col.hidden;
    this.setDisplayCols();
    this.checkIsViewSame();
  }

  /**
   * Populates the viewSelected and viewsOwned properties from the 'ias-views-users' Firestore collection.
   * It is called on AfterViewInit lifecycle hook.
   */
  private populateView() {
    this.tableLoaded = false;
    scheduled(this.getViewUser(), asyncScheduler)
      .pipe(
        take(1),
        finalize(()=> this.tableLoaded = true),
        combineLatestWith(this.getViewsShared().pipe(take(1))),
        concatMap(([viewUser, viewShared]) => {
          viewUser ??= {};
          this.viewUser = viewUser;
          const { viewSelectedId, viewsOwned } = viewUser[this.options.tableInfo.id] ?? {};
          this.viewSelected = viewSelectedId ?? this.DEFAULT_VIEW_ID;
          this.viewsOwned = viewsOwned ?? {};
          this.viewsSharedWithMe = this.buildViewsSharedList(viewShared);
          //if the selected view doesn't exist on viewsSharedWithMe, select the default and pop up the dialog
          if (!(this.viewsOwned[viewSelectedId] || this.viewsSharedWithMe?.[viewSelectedId] || this.viewSelected === this.DEFAULT_VIEW_ID)) {
            this.viewSelected = this.DEFAULT_VIEW_ID;
            this.openViewNotFoundAlert();
          }

          return (this.viewSelected === this.DEFAULT_VIEW_ID
            ? of({ viewTable: { columns: this.colsManipulation } } as unknown as IasViewTable)
            : scheduled(this.getViewsTable(this.viewSelected), asyncScheduler)).pipe(take(1));
        }),
        concatMap((view) => {
          // manipulate the response from table data
          this.colsManipulation = this.mergeColumns(view?.columns ?? []);
          this.setDisplayCols();
          return this.setColsWidth(false);
        })
      )
      .subscribe({
        next: () => {
          this.tableResized = true;
          this.showViewsIcon = true;
          this.tableLoaded = true;
          this.cdr.detectChanges();
        },
        error: async (err) => {
          this.errorService.handle(`List views - PopulationView error: ${err}`);
          this.openViewNotFoundAlert();
          await this.setViewTableSelected(this.DEFAULT_VIEW_ID, this.DEFAULT_VIEW_NAME);

          this.tableResized = true;
          this.showViewsIcon = true;
          this.tableLoaded = true;
        }
      });
  }

  buildViewsSharedList(viewShared: IasViewsSharing[]): IasTableViewName | undefined {
    if (!viewShared?.length) return undefined;

    return viewShared.reduce<IasTableViewName>((acc: IasTableViewName, curr: IasViewsSharing) => {
      acc = { ...acc, [curr.viewId]: { name: curr.viewName } };
      return acc;
    }, {});
  }

  /**
   * Populates the colsManipulation property by merging the columns from 'ias-views' Firestore collection
   * with the columns in the component.
   */
  populateColumns() {
    scheduled(this.getViewsTable(this.viewSelected), asyncScheduler)
      .pipe(
        take(1),
        takeUntil(this.destroyed$),
        concatMap((viewTable) => {
          this.colsManipulation = this.mergeColumns(viewTable?.columns ?? []);
          this.setDisplayCols();
          return this.setColsWidth();
        })
      )
      .subscribe({
        error: (err) => {
          this.errorService.handle(`Populate columns error: ${err}`);
        }
      });
  }

  /**
   * Merges the  given columns from Firestore with the local columns.
   *
   * @param columnsFromFirestore Array of columns retrieved from Firestore.
   * @returns Array of merged columns.
   */
  mergeColumns(columnsFromFirestore: Partial<TableCol>[]) {
    return this.colsManipulation.map((colData, index) => {
      return { ...colData, ...columnsFromFirestore[index] };
    });
  }

  /**
   * Create a partial 'columns' object from the TableCol interface
   * to be saved in 'ias-views' Firestore collection.
   *
   * @param cols The columns to create the partial column objects from.
   * @returns An array of partial column objects.
   */
  private createColumnObjectToSave(cols: TableCol[]): Partial<TableCol>[] {
    return cols.map((c) => {
      const width = c.headerStyle?.width ?? null;
      return {
        key: c.key,
        hidden: !!c.hidden,
        order: c.order,
        headerStyle: {
          width
        },
      };
    }) as Partial<TableCol>[];
  }

  /**
   * Retrieves the view table for the given viewSelected ID as an Observable.
   *
   * This method fetches the view data from Firestore, validates and fixes column keys,
   * updates the `colsCustom` property, and handles cases where the view has no columns.
   *
   * @param viewSelected - The ID of the view to be retrieved.
   * @returns An Observable that emits the `IasViewTable` object.
   */
  async getViewsTable(viewSelected: string): Promise<IasViewTable> {
    const data = await this.firebaseViewsService.getViewById(viewSelected);
    const columns = data.data() as IasViewTable;

    // validate and fix the key if the column values are correctly set
    columns.columns.forEach((column, idx) => {
      if (!column.key || !column.key.includes('-')) return;
      column.key = this.tableUtils.kebabToCamel(column.key as string);
      columns.columns[idx] = {...column, key: this.tableUtils.kebabToCamel(column.key as string,)} as Partial<TableCol>;
    });

    this.colsCustom = structuredClone(columns?.columns ?? []) as TableCol[];
    if (!columns?.columns?.length) {
      this.deleteAndSetDefaultView(viewSelected);
    }

    return structuredClone(columns);
  }

  private deleteAndSetDefaultView(viewSelected: string) {
    delete this.viewsOwned?.[viewSelected];
    delete this.viewsSharedWithMe?.[viewSelected];
    this.deleteViewFromOwnerUser(viewSelected)
      .subscribe({
        next: async () => {
          this.analyticsService.logEvent('View deleted due to error', {
            eventType: AnalyticsEventType.CLICK,
          });
          this.openViewNotFoundAlert();
          await this.setViewTableSelected(this.DEFAULT_VIEW_ID, this.DEFAULT_VIEW_NAME);
          this.tableLoaded = true;
        }
      });
  }

  openViewNotFoundAlert() {
    this.dialog.open(UiTableViewNotFoundDialog);
  }

  /**
   * Retrieves the user view inside the 'ias-views-users' Firestore collection
   * using the currently authenticated user's email.
   *
   * @returns A promise that resolves to the IasViewUser object. Promise<IasViewUser>
   */
  async getViewUser(): Promise<IasViewUser> {
    const data = await this.firebaseViewsService.getViewsUserById(this.authService.getUserEmail());
    return data.data() as IasViewUser;
  }

  async inactiveViewsShared(viewId: string): Promise<void> {
    await this.firebaseViewsService.deactivateSharedView(viewId);
  }

  /**
   *
   * @returns all views Ids shared with the logged user
   */
  getViewsShared(): Observable<IasViewsSharing[]> {
    return this.firebaseViewsService.getViewSharedWithUser(this.authService.getUserEmail(), this.options.tableInfo.id);
  }

  openAddNewViewDialog() {
    this.dialog
      .open(ComponeUiTableViewsDialogComponent)
      .afterClosed()
      .subscribe({
        next: async (name) => {
          if (name) {
            await this.saveNewView(name);
          }
        },
      });
  }

  /**
   * Creates a new view object, assigns it a unique identifier,
   * and saves it to the 'ias-views-users' Firestore collection.
   *
   * @param name - The name for the new view to be saved. {string}
   */
  async saveNewView(name: string) {
    const columns = this.createColumnObjectToSave(this.colsManipulation);
    const createdBy = this.authService.getUserEmail();
    const view: IasViewTable = {
      columns,
      createdBy,
      fromTable: this.options.tableInfo,
      name,
    };
    const viewId = crypto.randomUUID();
    const viewName: IasTableViewName = { [viewId]: { name } };
    const viewUser: IasViewUser = {
      [this.options.tableInfo.id]: {
        viewsOwned: { ...this.viewsOwned, ...viewName },
        viewSelectedId: viewId,
      },
    };

    const getViewsUser$ = scheduled(this.getViewUser(), asyncScheduler);
    const setDocumentView$ = scheduled(this.firebaseViewsService.setDocumentViews(viewId, view), asyncScheduler);
    const setDocumentViewsUser$ = scheduled(
      this.firebaseViewsService.setDocumentViewsUser(this.authService.getUserEmail(), viewUser),
      asyncScheduler,
    );

    setDocumentView$
      .pipe(concatMap(() => setDocumentViewsUser$.pipe(concatMap(() => getViewsUser$))))
      .subscribe((views) => {
        this.analyticsService.logEvent(
          `New view created on table ${this.options.tableInfo.name} for ${this.authService.getUserEmail()}`, {
          eventType: AnalyticsEventType.CLICK,
        });
        this.viewsOwned = views[this.options.tableInfo.id].viewsOwned ?? {};
        this.viewSelected = viewId;
        this.viewSelectedName = name;
        this.colsCustom = structuredClone(this.colsManipulation);

        this.checkIsViewSame();
      });
  }

  /**
   * Updates the view selected by the user in the 'ias-views-users' Firestore collection.
   * If the viewId is 'default', resets the columns to the original columns
   * and set the display columns. Otherwise, it will populate the columns from the
   * selected view.
   *
   * @param viewId - The id of the view to be selected. {string}
   * @param viewName The name of the view. {string}
   */
  async setViewTableSelected(viewId: string, viewName: string) {
    if (viewId === this.viewSelected) return;

    await this.firebaseViewsService.updateFieldFromViewUser(
      this.authService.getUserEmail(),
      `${this.options.tableInfo.id}.viewSelectedId`,
      viewId,
    );
    this.viewSelected = viewId;
    this.viewSelectedName = viewName;

    if (viewId === this.DEFAULT_VIEW_ID) {
      this.colsManipulation = structuredClone(this.colsDefault);
      this.setDisplayCols();
      await this.setColsWidth();
    } else {
      this.populateColumns();
    }
  }

  /**
   * Opens a dialog to share the currently selected view with other users.
   * Allows managing view access by adding or removing users.
   *
   * @param name - The name of the view being shared.
   */
  openShareViewDialog(name: string) {
    scheduled(this.firebaseViewsService.getSharedViewById(this.viewSelected), asyncScheduler).subscribe({
      next: (viewsSharing: IasViewsSharing) => {
        const sharedWith: string[] = viewsSharing?.sharedWith ?? [];

        this.dialog
            .open(ComponeUiTableViewsShareDialogComponent, { data: { name, sharedWith }, disableClose: true })
            .afterClosed()
            .pipe(
                concatMap((viewSharing: ViewsShared) => {
                    if (viewSharing) {
                        const data: IasViewsSharing = {
                            ownerId: this.authService.getUserEmail(),
                            sharedWith: viewSharing.sharedWith,
                            viewName: name ?? '',
                            viewId: this.viewSelected,
                            tableId: this.options.tableInfo.id,
                            isActive: true
                        };

                        return scheduled(
                            this.firebaseViewsService.setDocumentSharedView(this.viewSelected, data),
                            asyncScheduler
                        );
                    }
                    return EMPTY;
                })
            )
            .subscribe({
                next: () => {
                  this.analyticsService.logEvent(
                    `View ${this.viewSelected} shared with ${sharedWith}}`, {
                    eventType: AnalyticsEventType.LOG,
                  });
                  this.snack.message('Shared!');
                },
                error: () => {
                  this.errorService.handle(`View ${this.viewSelected} sharing failed!`);
                    this.snack.error('Something went wrong, Try again!');
                }
            });
      },
    });
  }

  /**
   * Opens a confirmation dialog to delete the currently selected view.
   * If the user confirms, it will proceed to delete the view.
   */
  openDeleteViewDialog() {
    const viewId = this.viewSelected;
    this.dialog
      .open(DeleteViewDialog)
      .afterClosed()
      .subscribe( async (confirm) => {
        if (confirm) {
          await this.setViewTableSelected(this.DEFAULT_VIEW_ID, this.DEFAULT_VIEW_NAME);
          this.deleteView(viewId);
        }
      });
  }

  /**
   * Deletes the view with the specified viewId.
   * It updates the 'ias-views-users' Firestore collection and removes the view from 'ias-views' Firestore collection.
   *
   * @param viewId - The ID of the view to be deleted. {string}
   */
  deleteView(viewId?: string) {
    if (!viewId) return;

    if (this.viewsOwned?.[viewId]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.viewsOwned[viewId];
    }
    const deleteView$ = scheduled(this.firebaseViewsService.deleteViewDocById(viewId), asyncScheduler);
    const deleteUserView$ = this.deleteViewFromOwnerUser(viewId);
    const getViewUser$ = scheduled(this.getViewUser(), asyncScheduler);
    const inactiveViewShared$ = scheduled(this.inactiveViewsShared(viewId), asyncScheduler);

    deleteView$
      .pipe(
        concatMap(() => deleteUserView$.pipe(
          concatMap(() => inactiveViewShared$.pipe(
            concatMap(() => getViewUser$))
          )
        )),
        take(1)
      )
      .subscribe({
        next: (viewsUser) => {
          this.viewsOwned = viewsUser[this.options.tableInfo.id].viewsOwned ?? {};
          this.snack.message('Deleted');
          this.checkIsViewSame();
        },
        error: (error) => {
          this.errorService.handle(`View ${this.viewsOwned?.[viewId]} deletion failed. Error: ${error}`);
            this.snack.error('Something went wrong, Try again!');
        }
      });
  }

  /**
   * Deletes a specific view from the user's owned views in Firestore.
   * It removes the view's information from the 'ias-views-users' collection,
   * specifically from the `viewsOwned` object for the current table.
   *
   * @param viewId - The ID of the view to delete from the user's owned views.
   * @returns An Observable that completes when the deletion operation is successful.
   */
  private deleteViewFromOwnerUser(viewId: string) {
    return scheduled(
      this.firebaseViewsService.deleteFieldFromViewsUser(
        this.authService.getUserEmail(),
        `${this.options.tableInfo.id}.viewsOwned.${viewId}`
      ),
      asyncScheduler
    ).pipe(take(1));
  }

  /**
   * Opens a dialog to rename the selected custom view.
   *
   * @param name - The new name of the custom view.
   */
  openRenameViewDialog(name: string) {
    const viewId = this.viewSelected;
    const updateViewName$ = (newName: string) =>
      scheduled(
        this.firebaseViewsService.updateFieldFromViewUser(
          this.authService.getUserEmail(),
          `${this.options.tableInfo.id}.viewsOwned.${viewId}.name`,
          newName,
        ),
        asyncScheduler,
      ).pipe(
        concatMap(() =>
          scheduled(this.firebaseViewsService.updateViewField(viewId, 'name', newName), asyncScheduler)
            .pipe(
              (concatMap(() => scheduled(this.firebaseViewsService.updateNameSharedView(viewId, newName), asyncScheduler)))
            ),
        ),
        concatMap(() => scheduled(this.getViewUser(), asyncScheduler)),
        combineLatestWith(of(newName))
      );

    this.dialog
      .open(ComponeUiTableViewsDialogComponent, {
        data: {
          id: viewId,
          name,
        },
      })
      .afterClosed()
      .pipe(concatMap((newName: string) => (newName ? updateViewName$(newName) : EMPTY)))
      .subscribe({
        next: ([viewsUser, updatedName]) => {
          //set the new name here
          this.viewsOwned = { ...this.viewsOwned, ...viewsUser[this.options.tableInfo.id].viewsOwned };
          this.viewSelectedName = updatedName;
          this.snack.message('Success');
          this.checkIsViewSame();
        },
      });
  }

  /**
   * Updates the selected view.
   * Opens a confirmation dialog to ensure the user wants to update the view.
   * If confirmed, it will update the view in both the 'ias-views-users' and 'ias-views' Firestore collections.
   */
  updateSelectedView() {
    const columns = this.createColumnObjectToSave(this.colsManipulation);

    this.dialog
      .open(UpdateViewDialog)
      .afterClosed()
      .pipe(
        concatMap((confirm) =>
          confirm
            ? scheduled(
              this.firebaseViewsService.updateViewField(this.viewSelected, 'columns', columns),
              asyncScheduler,
            )
            : EMPTY,
        ),
      )
      .subscribe({
        next: () => {
          this.snack.message('Success');
          this.colsCustom = structuredClone(columns) as TableCol[];
          this.checkIsViewSame();
        },
      });
  }

  /** Checks if the current view is the default view. */
  isDefaultView(): boolean {
    return this.viewSelected === this.DEFAULT_VIEW_ID;
  }

  /** Checks if the current view is **identical** to the original view. */
  checkIsViewSame(): void {
    const compareObject = JSON.stringify(this.createObjectToCompare(this.colsManipulation));
    const compareOriginalView = this.isDefaultView() ?  JSON.stringify(this.createObjectToCompare(this.colsDefault)) : JSON.stringify(this.createObjectToCompare(this.colsCustom));
    this.isViewSame = compareObject === compareOriginalView;
  }


  /**
   * Creates an array of objects for comparing column states (order, width, hidden).
   *
   * @param cols - The table columns.
   * @returns An array of objects, each representing a column's state.
   */
  createObjectToCompare(
    cols: TableCol[],
  ): { [key: string]: { order: number; width: string | undefined; hidden: boolean } }[] {
    return cols.map((col) => {
      return {
        [col.key]: {
          order: col.order,
          width: col.headerStyle?.width,
          hidden: !!col.hidden,
        },
      };
    });
  }

  /**
   * Updates the width of the given column.
   *
   * @param width - Width of the column in pixels.
   * @param key - Key of the column.
   */
  updateWidth(width: number, key: string) {
    const widthPx = width + 'px';
    const columnIndex = this.colsManipulation.findIndex((c) => c.key === key);
    if (this.colsManipulation[columnIndex]?.headerStyle.width !== widthPx) {
      this.colsManipulation[columnIndex].headerStyle.width = widthPx;
    }
    this.checkIsViewSame();
  }

  /** Sort the list of columns to be displayed in the Settings dropdown */
  sortByOrderMenu(cols: TableCol[]) {
    return cols.sort((a, b) => a.orderMenu - b.orderMenu);
  }
}
