/* eslint-disable unicorn/prefer-ternary */
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  map,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
  timestamp,
} from 'rxjs';

import { Clip } from '../../services/asset_service';
import { Bin } from '../../services/bin.service';
import { StateService } from '../../services/state_service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinBinMoveDialog } from '../../shared/clipbin_bin_move_dialog/clipbin_bin_move_dialog';
import {
  ClipBinFolderDeleteDialog,
} from '../../shared/clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import {
  ClipBinFolderMoveDialog,
} from '../../shared/clipbin_folder_move_dialog/clipbin_folder_move_dialog';
import { DeleteBinDialog } from '../../shared/delete_bin_dialog';
import { CUSTOM_BREAKPOINTS, FIXED_ROW_NUMBER } from '../clip-bin-section/breakpoints.config';
import { DialogOpener } from '../clip-bin-section/service/dialog-opener.service';
import { ResourceTypes } from '../clip-bin-section/service/resource-types';
import {
  PaginationInfo,
  Resource,
  ResourceContent,
  ResourceService,
} from '../clip-bin-section/service/resource.service';
import { SelectionService } from '../clip-bin-section/service/selection.service';

export interface ContentEvent {
    moved: boolean;
    deleted: boolean;
    id: string;
    name: string;
    title: string;
    retitled: string;
}

type ResultItem = Resource | Bin | Clip;

@Component({
    selector: 'mam-folder-content',
    templateUrl: './folder-content.component.html',
    styleUrls: ['./folder-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderContentComponent implements AfterViewInit, OnDestroy {
    @Input() isAdmin: boolean = false;
    @Input() userEmail: string = '';

    @Input() folderId!: string;
    @Input() showAllFolders: boolean = false;
    @Input() searchTerm: string = '';
    @Input() pageIndex$!: Subject<number>;

    @Output() folderContentLoading = new EventEmitter<boolean>();

    currentBreakpoint!: { source: string; value: BreakpointState; timestamp: number };
    resourceContent: ResourceContent = {} as ResourceContent;
    hasContent = false;
    skeletonItems = Array.from({ length: 6 });
    contentRef$ = new BehaviorSubject<Resource[]>([]);
    currentPage = 0;
    startAfter: string = '';
    private readonly destroyed$ = new ReplaySubject<void>(1);
    private lastResourcePerPage: { pageIndex: number; resourceId: string }[] = [];

    constructor(
        private cdr: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private resourceService: ResourceService,
        private stateService: StateService,
        private readonly breakpointObserver: BreakpointObserver,
        public selectionService: SelectionService,
        private dialogOpener: DialogOpener
    ) {}

    _resultsLoading = true;

    get resultsLoading() {
        return this._resultsLoading;
    }

    set resultsLoading(value: boolean) {
        this._resultsLoading = value;
        this.folderContentLoading.emit(value);
    }

    private _displayMode: DisplayMode = DisplayMode.GRID;

    get displayMode() {
        return this._displayMode;
    }

    @Input() set displayMode(mode: DisplayMode) {
        this._displayMode = mode;
        this.cdr.detectChanges();
    }

    get content() {
        return this.contentRef$.getValue();
    }

    ngAfterViewInit(): void {
        this.resultsLoading = true;
        this.cdr.markForCheck();

        const breakpoint$ = this.breakpointObserver
            .observe([
                CUSTOM_BREAKPOINTS.XS_MAX,
                CUSTOM_BREAKPOINTS.MD_MIN,
                CUSTOM_BREAKPOINTS.LG_MIN,
                CUSTOM_BREAKPOINTS.XL_MIN,
                CUSTOM_BREAKPOINTS.XXL_MIN
            ])
            .pipe(
                timestamp(),
                map(({ value, timestamp }) => ({
                    source: 'breakpoint',
                    value: value as BreakpointState,
                    timestamp
                }))
            );

        combineLatest([this.route.params, this.route.queryParams, this.pageIndex$, breakpoint$])
            .pipe(
                switchMap(([params, queryParams, pageIndex, breakpoint]) => {
                    if (
                        this.currentBreakpoint &&
                        this.currentBreakpoint.value.breakpoints !== breakpoint.value.breakpoints
                    ) {
                        this.currentBreakpoint = breakpoint;
                        this.pageIndex$.next(0);
                        this.cdr.markForCheck();
                        return EMPTY;
                    }

                    this.currentBreakpoint = breakpoint;

                    this.currentPage = pageIndex;
                    this.folderId = params['folderId'];
                    this._displayMode = queryParams['viewMode'] || DisplayMode.GRID;
                    this.resultsLoading = true;
                    this.cdr.markForCheck();
                    return this.getFolderContent(pageIndex);
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe((resourceContent: ResourceContent) => {
                if (resourceContent.paginationData?.totalPages && resourceContent.paginationData?.totalPages > 1) {
                    this.startAfter = '.';
                }
                this.resourceContent = resourceContent;
                this.hasContent = resourceContent?.parent?.directChildrenCount > 0;
                this.stateService.currentSelectedResource$.next(resourceContent);
                this.contentRef$.next(resourceContent.parent.children);

                const children = resourceContent.parent.children;
                if (children && children.length > 0) {
                    const lastID = children[children.length - 1].id as string;
                    this.addOrUpdateLastResourcePerPage(this.currentPage, lastID);
                }
                this.resultsLoading = false;
                this.cdr.markForCheck();
            });
    }

    /**
     * Retrieves the pagination limit based on the breakpoint pagination options
     * and active display mode.
     */
    retrievePaginationLimit(): number {
        if (this.displayMode === 'list') return 12;
        if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.XXL_MIN)) {
            return 6 * FIXED_ROW_NUMBER;
        } else if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.XL_MIN)) {
            return 5 * FIXED_ROW_NUMBER;
        } else if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.LG_MIN)) {
            return 4 * FIXED_ROW_NUMBER;
        } else if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.MD_MIN)) {
            return 3 * FIXED_ROW_NUMBER;
        } else {
            return 2 * FIXED_ROW_NUMBER;
        }
    }

    getFolderContent(pageIndex: number, searchTerm: string = '') {
        this.resourceService.hasGetFolderContentBeenCalled$.next({ pageIndex, searchTerm });
        const paginationOptions: PaginationInfo = {
            limit: this.retrievePaginationLimit(),
            offset: pageIndex
        };

        if (pageIndex > 0) {
            const prevPageCursor = this.lastResourcePerPage.find((x) => x.pageIndex === pageIndex - 1);
            if (prevPageCursor) {
                paginationOptions.startAfter = prevPageCursor.resourceId;
            } else {
                paginationOptions.startAfter = undefined;
            }
        }

        if (searchTerm && searchTerm.trim().length > 0) {
            paginationOptions.searchTerm = searchTerm;
        }

        this.resultsLoading = true;

        return this.resourceService.getResourceChildren(ResourceTypes.FOLDER, this.folderId, paginationOptions).pipe(
            switchMap(() => this.resourceService.currentContext$),
            takeUntil(this.destroyed$)
        );
    }

    addOrUpdateLastResourcePerPage(pageIndex: number, resourceId: string) {
        const idx = this.lastResourcePerPage.findIndex((x) => x.pageIndex === pageIndex);
        if (idx === -1) {
            this.lastResourcePerPage.push({ pageIndex, resourceId });
        } else {
            this.lastResourcePerPage[idx].resourceId = resourceId;
        }
    }

    onFolderCrumbClick(folderId?: string) {
        const params = {
            queryParamsHandling: 'merge' as const,
            queryParams: {}
        };

        if (this.displayMode === 'list') {
            params.queryParams = { viewMode: DisplayMode.LIST };
        }
        this.router.navigate(folderId ? [`/folders/${folderId}`] : ['/'], params);
        this.cdr.detectChanges();
    }

    trackBy(index: number, item: Resource) {
        return item.id;
    }

    isClipBin(folder: Resource) {
        return folder.type !== 'folder';
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public reloadFolderContent(searchTerm: string = ''): void {
        this.resultsLoading = true;
        this.getFolderContent(0, searchTerm);
    }

    // --------------------- bulk actions

    handleBulkDelete(selectedItems: Set<ResultItem>): void {
        const folders: Resource[] = [];
        const bins: Resource[] = [];

        selectedItems.forEach((item) => {
            if (this.isResource(item)) {
                if (item.type === 'folder') {
                    folders.push(item);
                } else if (item.type === 'clipbin') {
                    bins.push(item);
                }
            }
        });

        if (folders.length > 0) {
            this.openDeleteFolders(new Set(folders));
        }

        if (bins.length > 0) {
            this.openDeleteBins(bins);
        }
    }

    handleBulkMove(selectedItems: Set<ResultItem>): void {
        const folders: Resource[] = [];
        const bins: Resource[] = [];

        selectedItems.forEach((item) => {
            if (this.isResource(item)) {
                if (item.type === 'folder') {
                    folders.push(item);
                } else if (item.type === 'clipbin') {
                    bins.push(item);
                }
            }
        });
        if (folders.length > 0) {
            this.moveFoldersToFolder(new Set(folders));
        }
        if (bins.length > 0) {
            this.moveBinsToFolder(bins);
        }
    }

    isResource(obj: ResultItem): obj is Resource {
        return 'type' in obj && typeof obj.type === 'string';
    }

    moveBinToFolder = (bin: Resource) => {
        bin['displayMode'] = 'list';
        this.dialogOpener.openDialog(ClipBinBinMoveDialog, ClipBinBinMoveDialog.dialogOptions, bin);
    };

    openDeleteBin = (bin: Resource) => {
        this.dialogOpener.openDialog(DeleteBinDialog, DeleteBinDialog.dialogOptions, { resource: bin });
    };

    moveBinsToFolder = (bins: Resource[]) => {
        bins.forEach((bin) => (bin['displayMode'] = 'list'));
        this.dialogOpener.openDialog(ClipBinBinMoveDialog, ClipBinBinMoveDialog.dialogOptions, bins);
    };

    openDeleteBins = (bins: Resource[]) => {
        this.dialogOpener.openDialog(DeleteBinDialog, DeleteBinDialog.dialogOptions, { resources: bins });
    };

    moveFolderToFolder = (folder?: Resource) => {
        if (folder) folder['displayMode'] = 'list';
        this.dialogOpener.openDialog(ClipBinFolderMoveDialog, ClipBinFolderMoveDialog.dialogOptions, folder);
    };

    moveFoldersToFolder = (folders?: Set<ResultItem>) => {
        let folders_: ResultItem[] = [];
        if (folders) {
            folders_ = Array.from(folders);
        }
        this.dialogOpener.openDialog(ClipBinFolderMoveDialog, ClipBinFolderMoveDialog.dialogOptions, folders_);
    };

    openDeleteFolder = (folder?: Resource) => {
        this.dialogOpener.openDialog(ClipBinFolderDeleteDialog, ClipBinFolderDeleteDialog.dialogOptions, folder);
    };

    openDeleteFolders = (folders?: Set<ResultItem>) => {
        if (folders) {
            const folders_ = Array.from(folders);
            this.dialogOpener.openDialog(ClipBinFolderDeleteDialog, ClipBinFolderDeleteDialog.dialogOptions, folders_);
        }
    };

    toggleSelect(resource: ResultItem) {
        this.selectionService.toggleSelect(resource);
    }

    toggleAllSelect(checked: boolean) {
        this.selectionService.setSelectAll(checked);
    }
}
