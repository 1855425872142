<mat-toolbar class="upper-toolbar">
  <a class="title">
    <span class="product-icon">
      <img src="images/Logo_Collab.svg" width="35px" height="35px" alt="IAS logo">
    </span>
    <h1>Intelligent Asset Service</h1>
  </a>

  <div class="center" *ngIf="link">
    <div>
      <div class="titles">
        <h1
          [attr.title]="link.title"
          matTooltip="{{link.title}}"
          matTooltipPosition="below"
          matTooltipClass="mobile-tooltip">{{link.title}}</h1>
      </div>
    </div>
  </div>

  <div class="right shared-link-download-buttons" *ngIf="link?.downloadable || originalHash">
    <a #downloadAnchor>
      <button
          class="download-original-button"
          mat-flat-button
          mam-firebase-ga="Download original video file"
          *ngIf="!rawSourceUrl"
          [mam-firebase-resource]="link?.name"
          [matTooltip]="getDownloadTooltip()"
          (click)="downloadFile()">
        <mat-icon>download</mat-icon>
        Download
      </button>

      <button
          class="download-original-button"
          mat-flat-button
          aria-label="Download menu"
          *ngIf="rawSourceUrl"
          [matMenuTriggerFor]="downloadMenu">
        <mat-icon>arrow_drop_down</mat-icon>
        Download
      </button>
      <mat-menu #downloadMenu="matMenu">
        <button
            mat-menu-item
            mam-firebase-ga="Download mezzanine video file"
            [mam-firebase-resource]="link?.name"
            [matTooltip]="getDownloadTooltip()"
            (click)="downloadFile()">
          Mezzanine
        </button>

        <button
            mat-menu-item
            mam-firebase-ga="Download raw video file"
            [mam-firebase-resource]="link?.name"
            matTooltip="Download raw video file"
            (click)="downloadFile(true)">
          Raw source
        </button>
    </mat-menu>
    </a>
  </div>
</mat-toolbar>

<!-- Details view -->
<router-outlet *ngIf="link"></router-outlet>
