<div #uiTable class="ui-table" [ngClass]="'type_' + tableType">
  <div class="shadow-container__ui-table" *ngIf="showUpContainer" (click)="showUpContainer = false">
  </div>

  <div class="setting-bar">
    <!-- Settings Dropdown -->
    <ng-template #columnsSelector #menu>
      <div cdkMenu class="menu-settings" >
        <div class="menu-settings_views">
          <h4 class="menu-settings__title non-clickable">Views</h4>
          <button
            class="menu-settings__trigger-for"
            [class.active]="viewListTag"
            cdkMenuItem
            [cdkMenuTriggerFor]="viewsList"
            (cdkMenuOpened)="viewListTag = true"
            (cdkMenuClosed)="viewListTag = false"
            (cdkMenuItemTriggered)="viewListTag = !viewListTag"
          >
            <span> Select View </span>
            <mat-icon>arrow_forward_ios</mat-icon>
          </button>

          <button
            class="menu-settings__trigger-for"
            cdkMenuItem
            [cdkMenuTriggerFor]="viewActions"
            [class.non-clickable]="viewSelected === DEFAULT_VIEW_ID || viewsSharedWithMe?.hasOwnProperty(viewSelected)"
            [cdkMenuItemDisabled]="viewSelected === DEFAULT_VIEW_ID || viewsSharedWithMe?.hasOwnProperty(viewSelected)"
            [disabled]="viewSelected === DEFAULT_VIEW_ID || viewsSharedWithMe?.hasOwnProperty(viewSelected)"
            [class.active]="actionListTag"
            (cdkMenuOpened)="actionListTag = true"
            (cdkMenuClosed)="actionListTag = false"
            (cdkMenuItemTriggered)="actionListTag = !actionListTag"
          >
            <span>
              Views Actions
              <small>
                {{ viewSelected === DEFAULT_VIEW_ID ? DEFAULT_VIEW_NAME : (viewsOwned?.[viewSelected]?.name ??
                viewsSharedWithMe?.[viewSelected]?.name) ?? '' | truncate : 25 }}
              </small>
            </span>
            <mat-icon>arrow_forward_ios</mat-icon>
          </button>
        </div>

        <mat-divider></mat-divider>

        <h4 class="menu-settings__title non-clickable">COLUMNS</h4>
        <div class="menu-settings_columns">
          <ng-container *ngFor="let col of sortByOrderMenu(colsManipulation)">
              <mat-checkbox
                color="primary"
                class="menu-settings_item"
                [class.non-clickable]="col?.disabled"
                *ngIf="col.name"
                [checked]="!col.hidden"
                [disabled]="col?.disabled"
                (change)="addAndRemoveColumns(col)"
              >
                {{ col?.name ?? '' | truncate : 18 }}
              </mat-checkbox>
          </ng-container>
        </div>

        <mat-divider></mat-divider>

        <div class="menu-settings__save">
          <button mat-button (click)="openAddNewViewDialog()" [disabled]="isViewSame">
            <mat-icon>add</mat-icon>
            Create new view
          </button>
        </div>
      </div>
    </ng-template>

    <!-- Views list -->
    <ng-template #viewsList>
      <div cdkMenu class="menu-view-list menu--margin" (closed)="viewListTag = false">
        <button mat-menu-item class="default-view" (click)="setViewTableSelected(DEFAULT_VIEW_ID, DEFAULT_VIEW_NAME)">
          <span>{{ DEFAULT_VIEW_NAME }}</span>
          <mat-icon [style.opacity]="DEFAULT_VIEW_ID === viewSelected ? 1 : 0">done</mat-icon>
        </button>
        <ng-container *ngIf="viewsOwned && (viewsOwned | keyvalue).length > 0">
          <mat-divider></mat-divider>

          <button
            *ngFor="let view of viewsOwned | keyvalue"
            mat-menu-item
            (click)="setViewTableSelected(view.key, viewsOwned[view.key].name ?? '')"
          >
            <span
              [matTooltipDisabled]="viewsOwned[view.key].name!.length < 30"
              [matTooltip]="viewsOwned[view.key].name || ''"
            >
              {{ viewsOwned[view.key].name ?? '' | truncate : 30 }}
            </span>
            <mat-icon [style.opacity]="view.key === viewSelected ? 1 : 0">done</mat-icon>
          </button>
        </ng-container>

        <ng-container *ngIf="viewsSharedWithMe && (viewsSharedWithMe | keyvalue).length > 0">
          <mat-divider></mat-divider>
          <h5 class="menu-settings__title_secondary non-clickable">Shared With Me</h5>
          <button
            *ngFor="let view of viewsSharedWithMe | keyvalue"
            mat-menu-item
            (click)="setViewTableSelected(view.key, viewsSharedWithMe[view.key].name ?? '')"
          >
            <span
              [matTooltipDisabled]="viewsSharedWithMe[view.key].name!.length < 30"
              [matTooltip]="viewsSharedWithMe[view.key].name || ''"
            >
              {{ viewsSharedWithMe[view.key].name ?? '' | truncate : 30 }}
            </span>
            <mat-icon [style.opacity]="view.key === viewSelected ? 1 : 0">done</mat-icon>
          </button>
        </ng-container>
      </div>
    </ng-template>

    <!-- Actions list -->
    <ng-template #viewActions>
      <div #menu="cdkMenu" cdkMenu class="menu-settings menu-settings_actions menu--margin">
        <h5 class="menu-settings__title_secondary with_bottom-border non-clickable" #viewTitle>
          {{ (viewsOwned?.[viewSelected]?.name
              ?? viewsSharedWithMe?.[viewSelected]?.name) ?? '' | truncate : 25}}
        </h5>

        <button mat-menu-item (click)="openRenameViewDialog(viewTitle.textContent?.trim() ?? '')">
          <span>Rename view</span>
          <mat-icon>text_fields</mat-icon>
        </button>
        <button mat-menu-item (click)="updateSelectedView()" [disabled]="isViewSame">
          <span>Update view</span>
          <mat-icon>upload</mat-icon>
        </button>
        <button mat-menu-item (click)="openDeleteViewDialog()">
          <span>Delete view</span>
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-menu-item (click)="openShareViewDialog(viewTitle.textContent?.trim() ?? '')">
          <span>Share view</span>
          <mat-icon>share</mat-icon>
        </button>
      </div>
    </ng-template>
  </div>

  <!-- Settings Button -->
  <div #settingsBtn
    class="setting-bar-btn"
    *ngIf="!!data && showViewsIcon && tableLoaded"
    [cdkMenuTriggerFor]="columnsSelector"
    (cdkMenuOpened)="showUpContainer = true"
    (cdkMenuClosed)="showUpContainer = false"
    (cdkMenuItemTriggered)="showUpContainer = !showUpContainer"
  >
    <mat-icon
      class="setting-bar__icon"
      matTooltip="Table Settings"
    >
      settings
    </mat-icon>

    <mat-icon matTooltip="Update Pending" class="update-pending" *ngIf="!isViewSame">
      info
    </mat-icon>
  </div>

<!-- Loading table -->
  @if (!tableLoaded && !tableResized) {
    <div class="skeleton_container">
      <h2>Loading data...</h2>
    </div>
  }

  <!-- Table element -->
  <table
    #table
    mat-table
    [dataSource]="data"
    [trackBy]="trackBy"
    [multiTemplateDataRows]="options.multi"
    fixedLayout
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="onDrop($event)"
    class="table ui-table__table"
    [class.loading]="options.loading"
    [class.invisible]="!(tableLoaded && tableResized)"
    [class.table-rendered]="(tableLoaded && tableResized)"
  >

  <ng-container
    [cdkColumnDef]="col.key"
    [sticky]="col.sticky"
    [stickyEnd]="col.stickyEnd"
    *ngFor="let col of cols; let idx = index; trackBy: trackBy; last as isLast; first as isFirst"
  >

    <th
      #th
      cdk-header-cell
      *cdkHeaderCellDef
      cdkDrag
      cdkDragLockAxis="x"
      [cdkDragStartDelay]="50"
      [cdkDragData]="col"
      [id]="col.key"
      cdkDragBoundary=".ui-table"
      [cdkDragDisabled]="!col.dragger"
      [hidden]="col.hidden"
      [mamTableStyle]="col.headerStyle"
      class="ui-table__th"
      [class.default-first]="isFirst && !!col.name"
      [class.col-sticky--start]="col.stickyStart"
      [class.col-sticky--end]="col.stickyEnd"
      [class.col-draggable]="col.dragger"
    >
      <div class="th-wrapper">
        <div cdkDragHandle class="th-name" [class.th_checkbox-col]="col.key === 'checkbox' || col.key === 'select' || col.key === 'icon'">
          <div class="th-name_wrapper" [class]="col.class">
            <ng-container *ngIf="col.headerTpl; else defaultHeaderTpl">
								<ng-container [ngTemplateOutlet]="headerTpl" [ngTemplateOutletContext]="{$implicit: col}" />
							</ng-container>

							<ng-template #defaultHeaderTpl> {{col.name}} </ng-template>

          </div>

          <div class="sorter" *ngIf="col.sorter">
            <button
              matRipple
              (click)="onSort(col)"
              class="sorter-btn"
              [class.sorter-btn--active]="sortActive === col.key"
              [disabled]="options.disableSorting"
            >
              <div
                [style.transform]="(sortActive === col.key && sortDirection === 'desc') ? 'rotate(180deg)' : 'rotate(0deg)'"
                [style.color]="sortActive === col.key ? '#dadce0' : 'inherit'"
              >
                <svg
                  width="24"
                  height="24"
                  fill="currentColor"
                  stroke="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.707,13.293c-0.391-0.391-1.023-0.391-1.414,0L13,15.586V8c0-0.552-0.447-1-1-1s-1,0.448-1,1v7.586l-2.293-2.293 c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L12,19.414l4.707-4.707C17.098,14.316,17.098,13.684,16.707,13.293z"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
        <div class="th-wrapper-actions" *ngIf="col.name">
          <div class="th-actions">
            <div [ngStyle]="{'display': col.optioner ? 'flex' : 'none'}">
              <div [cdkMenuTriggerFor]="optionermenu" class="optioner">
                <mat-icon>more_vert</mat-icon>
              </div>

              <ng-template #optionermenu>
                <div cdkMenu class="optioner-menu">
                  <button
                    id="ui_table__autosize_columns"
                    cdkMenuItem
                    matRipple
                    (click)="onResetColsSize()"
                    class="option-item"
                  >
                    <mat-icon class="option-item__icon material-icons-outlined">view_week</mat-icon>
                    <span class="option-name">Autosize Columns</span>
                  </button>

                  <hr />

                  <button
                    id="ui_table__reset_view"
                    cdkMenuItem
                    matRipple
                    (click)="onResetView()"
                    class="option-item"
                  >
                    <mat-icon class="option-item__icon option-item__icon--rotate material-icons-outlined">expand</mat-icon>
                    <span class="option-name">Reset View</span>
                  </button>
                </div>
              </ng-template>
            </div>
          </div>

          <div
            class="bar"
            [class.has-resizer]="col.resizer"
            [resizable]="!!col.resizer"
            [element]="th"
            (resizingEnd)="updateWidth($event, col.key)"
          >
            <div class="line"></div>
          </div>
        </div>
      </div>
    </th>

    <td
      cdk-cell
      *cdkCellDef="let row; let i = index"
      [hidden]="col.hidden"
      [mamTableStyle]="col.cellStyle"
      [class.col-sticky--start]="col.stickyStart"
      [class.col-sticky--end]="col.stickyEnd"
      [ngClass]="col.classCel"
      class="ui-table__td col-key--{{col.key}}"
    >
      <!-- Custom Cell, when cellTpl is defined -->
      <ng-container *ngIf="col.cellTpl; else defaultCellTpl">
        <ng-container [ngTemplateOutlet]="cellTpl" [ngTemplateOutletContext]="{$implicit: row, col, i}" />
      </ng-container>

      <!-- Default Cell -->
      <ng-template #defaultCellTpl>
        {{col.pipe ? (row[col.key] | pipe: col.pipe : col.pipeArg) : (row[col.key])}}
      </ng-template>
    </td>

    <!-- Skeleton Row -->
<!--    <ng-container *ngIf="options?.loading">-->
<!--      <tr *cdkNoDataRow>-->
<!--        <td cdk-cell *ngFor="let col of displayCols()">-->
<!--          <div class="skeleton"></div>-->
<!--        </td>-->
<!--      </tr>-->
<!--    </ng-container>-->

    <!-- Footer cell -->
    <td cdk-footer-cell *cdkFooterCellDef [hidden]="col.hidden" [mamTableStyle]="col.footerStyle">
      <ng-container [ngTemplateOutlet]="footerTpl" [ngTemplateOutletContext]="{$implicit: col}" />
    </td>
  </ng-container>

  <ng-container cdkColumnDef="multi">
    <td cdk-cell *cdkCellDef="let row" [attr.colspan]="displayCols().length">
      <ng-container [ngTemplateOutlet]="multiTpl" [ngTemplateOutletContext]="{$implicit: row}" />
    </td>
  </ng-container>


  <!-- Table construction -->
  <tr cdk-header-row *cdkHeaderRowDef="displayCols(), sticky: true" ></tr>
  <tr
    cdk-row
    *cdkRowDef="let row; columns: displayCols();let i = dataIndex"
    (click)="onRowClick(row, $event, i); $event.stopPropagation();"
    [hidden]="isRowHidden(row)"
    [style.cursor]="options.multi ? 'pointer' : 'default'"
    [ngClass]="{
      'selected': selectedRows.has(row.name ?? row.id),
      'active': activeRows.has(row.name ?? row.id),
      'turn-public': turnPublicRows.has(row.documentId),
      'approved': row.approved || approvedRows.has(row.name ?? row.id),
      'deleted': row.isDeleted || isRowDeleted(row),
      'error': row.hasError
    }"
  ></tr>
  <tr cdk-row *cdkRowDef="let row; columns: ['multi']" [hidden]="!expandedRows.has(row.id)" class="multi-row"></tr>
  <tr cdk-footer-row *cdkFooterRowDef="displayCols()" [hidden]="!options.footer"></tr>
</table>
</div>

  @if (ths.length && !data.length && (tableLoaded && tableResized)) {
    <div class="empty-message listview_empty-table">
      {{ emptyMessage }}
    </div>
  }
