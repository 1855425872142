import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';

import { FeatureFlagService } from '../feature_flag/feature_flag_service';
import { DialogResourceRefreshService } from '../landing/clip-bin-section/service/dialog-resource-refresh-service';
import { ResourceTypes } from '../landing/clip-bin-section/service/resource-types';
import { Resource, ResourceService } from '../landing/clip-bin-section/service/resource.service';
import { SelectionService } from '../landing/clip-bin-section/service/selection.service';
import { BinSectionContent, BinService } from '../services/bin.service';
import { SnackBarService } from '../services/snackbar_service';
import { StateService } from '../services/state_service';
import { SharedLinkClipBinService } from '../shared_clipbin/services/shared_link_clipbin.service';

export interface DeleteBinDialogData {
    resource?: Resource;
    resources?: Resource[];
}

/** Clipbin deletion dialog */
@Component({
    selector: 'mam-delete-bin-dialog',
    templateUrl: './delete_bin_dialog.ng.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteBinDialog implements OnInit {
    static readonly dialogOptions = {
        hasBackdrop: true
    };

    contextId = window.location.pathname.split('/').pop() || '';

    constructor(
        readonly dialogRef: MatDialogRef<DeleteBinDialog>,
        private readonly snackBar: SnackBarService,
        private readonly resourceService: ResourceService,
        private readonly featureService: FeatureFlagService,
        private readonly binService: BinService,
        private readonly sharedLinkClipBinService: SharedLinkClipBinService,
        private readonly stateService: StateService,
        private selectionService: SelectionService,
        private dialogRefreshService: DialogResourceRefreshService,
        @Inject(MAT_DIALOG_DATA) public data: DeleteBinDialogData
    ) {}

    ngOnInit() {
        this.deleteBinSubscribe();
    }

    deleteBinSubscribe() {
        if (this.featureService.featureOn('enable-clip-bin-organization')) {
            const isInFolderPath = this.contextId.length > 0;
            this.dialogRef.afterClosed().subscribe((deletionConfirmed) => {
                if (!deletionConfirmed) return;
                if (this.data && this.data.resource) {
                    this.resourceService
                        .deleteResource(
                            isInFolderPath ? ResourceTypes.FOLDER : ResourceTypes.CLIPBIN,
                            ResourceTypes.CLIPBIN,
                            this.data.resource.iasData.label.name,
                            this.contextId
                        )
                        .pipe(take(1))
                        .subscribe({
                            next: () => {
                                if (this.data && this.data.resource) {
                                    this.snackBar.message('Clip bin has been deleted successfully.');
                                    this.stateService.searchModeSelected$.next(BinSectionContent.BIN);
                                    this.sharedLinkClipBinService.revokeClipbinShareLink(
                                        this.data.resource.iasData.label.name
                                    );
                                    this.dialogRefreshService.reloadResourcesAndClose(this.dialogRef, this.data);
                                }
                            },
                            error: (error) => {
                                this.snackBar.error('Clip bin could not be deleted.', undefined, error);
                            }
                        });
                } else if (this.data.resources) {
                  const clipBins: string[] = [];
                  this.data.resources.forEach((bin) => clipBins.push(bin.iasData.label.name));

                  this.selectionService
                      .bulkDeleteCLipBins(clipBins)
                      .pipe(take(1))
                      .subscribe({
                          next: (response: { message: string, clipbin: string }[]) => {
                              if (this.data && this.data.resources) {
                                  response.forEach(item => {
                                      this.snackBar.message(item.message);
                                      this.sharedLinkClipBinService.revokeClipbinShareLink(item.clipbin);
                                  });
                                  this.stateService.searchModeSelected$.next(BinSectionContent.BIN);
                                  this.dialogRefreshService.reloadResourcesAndClose(this.dialogRef, this.data);
                              }
                          },
                          error: (error) => {
                              this.snackBar.error('Clip bins could not be deleted.', undefined, error);
                          }
                      });
              }
            });
        } else {
            this.dialogRef.afterClosed().subscribe((deletionConfirmed) => {
                if (!deletionConfirmed) return;
                if (this.data && this.data.resource) {
                    this.binService.delete(this.data.resource.iasData.label.name).subscribe({
                        next: () => {
                            if (this.data && this.data.resource) {
                                this.snackBar.message('Clip bin has been deleted successfully.');
                                this.sharedLinkClipBinService.revokeClipbinShareLink(
                                    this.data.resource.iasData.label.name
                                );
                            }
                        },
                        error: (error) => {
                            this.snackBar.error('Clip bin could not be deleted.', undefined, error);
                        }
                    });
                }
            });
        }
    }
}
