import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject, QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute} from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

import { assertTruthy } from 'asserts/asserts';
import { SharedLink } from 'models';

import { ClipBinsInfoService } from '../clip_bins/services/clipbins_info.service';
import {
  DetailsNavigationService,
  GET_CURRENT_URL,
  SHARED_CLIPBIN_URL,
} from '../details/details_navigation_service';
import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { FirebaseFirestoreDataService } from '../firebase/firebase_firestore_data_service';
import { IClipBinStorage } from '../models/storage.model';
import { BytesPipe } from '../pipes/bytes_pipe';
import { LOCATION_ORIGIN, SharedLinksService } from '../services/shared_links_service';
import { SnackBarService } from '../services/snackbar_service';
import { ClipbinStorageService } from '../services/storage/clip_bin_storage.service';

import { SharedLink as ClipSharedLink, PersistedSharedLink } from './models/shared_link_clipbin.model';
import { SharedLinkClipBinService } from './services/shared_link_clipbin.service';
import { SharedLinkClipbinNavigationService } from './services/shared_link_clipbin_navigation.service';

/**
 * Page allowing to play and download a video until the link used to access it
 * expires.
 */
@Component({
    selector: 'mam-shared-clipbin',
    templateUrl: './shared_link_clipbin.ng.html',
    styleUrls: ['./shared_link_clipbin.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedLinkClipBin {
    link?: SharedLink;
    linkTitle?: string;
    linkHash = '';
    clipBin = '';
    clipBinTitle?: string;
    rawSourceUrl?: string;
    url = new URL(this.getCurrentUrl());
    originalHash = this.url.searchParams.get('originalHash');

    clipList: PersistedSharedLink[] = [];

    filteredClips$: Observable<PersistedSharedLink[]>;

    readonly loadingClips$ = new BehaviorSubject(true);

    currentIndex = 0;

    /** Used to dynamically set a `href` and trigger a download from it. */
    @ViewChild('downloadAnchor') downloadAnchor!: ElementRef<HTMLAnchorElement>;
    @ViewChild('scrollView') scrollView?: ElementRef<HTMLElement>;
    @ViewChildren('clipCard', { read: ElementRef }) clipCardList!: QueryList<ElementRef>;

    readonly sharedClipsSearchControl = new UntypedFormControl();

    constructor(
        @Inject(GET_CURRENT_URL) private readonly getCurrentUrl: () => string,
        public navigationService: SharedLinkClipbinNavigationService,
        private readonly cdr: ChangeDetectorRef,
        private readonly detailsNavigation: DetailsNavigationService,
        private readonly clipBinFirestoreService: SharedLinkClipBinService,
        private readonly clipbinStorageService: ClipbinStorageService,
        private readonly clipBinsInfoService: ClipBinsInfoService,
        private readonly dataService: FirebaseFirestoreDataService,
        private readonly sharedLinks: SharedLinksService,
        private readonly snackbar: SnackBarService,
        private readonly bytesPipe: BytesPipe,
        private activatedRoute: ActivatedRoute,
        analyticsService: FirebaseAnalyticsService,
        @Inject(LOCATION_ORIGIN) private readonly origin: string,
    ) {

        this.detailsNavigation.muteErrorAssetUndefined = true;
        analyticsService.logEvent('Visited shared clipbin', {
            eventType: AnalyticsEventType.NAVIGATION,
            path: SHARED_CLIPBIN_URL,
            string2: SHARED_CLIPBIN_URL,
        });

        this.filteredClips$ = this.sharedClipsSearchControl.valueChanges.pipe(
            startWith(''),
            map((searchText) => this.filterClipBinLinks(searchText))
        );

        this.activatedRoute.paramMap.subscribe((params) => {
            this.linkHash = params.get('linkhash') ?? '';
            this.clipBin = params.get('clipbinname') ?? '';
        });

        this.readDefaultAudioTracks();

        this.clipBinFirestoreService
            .retrieveIASClipBinShareLink(encodeURIComponent(this.clipBin))
            .pipe(take(1))
            .subscribe(async (result) => {
              if (!result.length) {
                this.snackbar.error('Clip Bin is missing\\deleted or shared link is expired\\revoked.');
                detailsNavigation.navigateTo404();
                return;
              }

              const clipBinLink = result[0];
              this.clipBinTitle = clipBinLink.clipBinTitle;

              if (clipBinLink.clipSharedLinks) {
                const originals = this.collectOriginals(clipBinLink.clipSharedLinks);
                const deletedAssetsData = await firstValueFrom(this.dataService.getDeletedAssets(originals));
                const deletedAssets = deletedAssetsData.map(item => item.data()['name']) as string[];
                this.clipList = clipBinLink.clipSharedLinks.filter(l => !l.originalName || !deletedAssets.includes(l.originalName));
              }

              this.loadingClips$.next(false);
              this.sharedClipsSearchControl.setValue('');
              this.cdr.markForCheck();

              this.navigateFirstClip();
            });
    }

    private collectOriginals(clipSharedLinks: ClipSharedLink[]) {
      const originals = clipSharedLinks.filter(l => l.originalName).map(l => l.originalName) as string[];
      return Array.from(new Set(originals));
    }

    private navigateFirstClip() {
      const clipIndex = this.clipList.findIndex((clip) => clip.link === this.linkHash);
      if (clipIndex !== -1) {
        this.currentIndex = clipIndex;
        this.navigateClip(this.linkHash);
      } else {
        if (this.clipList.length) {
          this.navigateClip(this.clipList[0].link);
        }
      }
    }

    private navigateClip(clipLink: string) {
      this.detailsNavigation
          .navigate([SHARED_CLIPBIN_URL, this.clipBin, 'clip', clipLink], {})
          .then((result) => {
              // trick code allows to force navigation service to re(evaluate) context
              this.detailsNavigation.index$.next(undefined);
              this.detailsNavigation
                  .getSharedLinkContext(clipLink)
                  .pipe(take(1))
                  .subscribe((context) => {
                      if (!context.link) {
                        this.snackbar.error('Video not available.');
                      }

                      this.link = context.link;
                      this.linkTitle = context.link?.title;
                      this.rawSourceUrl = this.link?.userRenditions.renditionMap['RAW_SOURCE']?.url;
                      this.cdr.markForCheck();
                    });
              return result;
          });
    }

    navigateNext() {
        this.filteredClips$.pipe(take(1)).subscribe((clips) => {
            if (this.currentIndex < clips.length - 1) {
                this.currentIndex++;
                this.clickClipCard(this.clipList[this.currentIndex].link, this.currentIndex);
            }
        });
    }

    navigatePrevious() {
        this.filteredClips$.pipe(take(1)).subscribe(() => {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.clickClipCard(this.clipList[this.currentIndex].link, this.currentIndex);
            }
        });
    }

    private filterClipBinLinks(searchText: string): PersistedSharedLink[] {
        if (!searchText) {
            return this.clipList;
        }
        return this.clipList.filter((clip) => clip.title.toLowerCase().includes(searchText.toLowerCase()));
    }

    /** Triggers a browser download of the video. */
    async downloadFile(rawSource = false) {
        const link = this.link;
        assertTruthy(link, 'SharedLink.downloadOriginal: Link does not exist');
        let linkName = link.name;

        if (this.originalHash) {
            const originalContext = await this.detailsNavigation
                .getSharedLinkContext(this.originalHash)
                .pipe(take(1))
                .toPromise();
            linkName = originalContext?.link ? originalContext.link.name : link.name;
        }

        // Fetch the same link again so that we get a fresh signed URL for the
        // rendition and its raw source, since it has a very short expiration time.
        this.sharedLinks.getPreview(linkName).subscribe((freshLink) => {
            const url = rawSource ? this.getRawSourceUrl(freshLink) : this.getOriginalUrl(freshLink);

            if (!url) {
                this.snackbar.error('Video cannot be downloaded.');
                return;
            }

            // Set the anchor's `href` to the newly signed original URL, then click it.
            const anchor = this.downloadAnchor.nativeElement;
            anchor.href = url;
            anchor.click();

            // Download popup has been opened, clear the anchor href so that next time
            // we click on it, we don't open the obsolete href and go through this
            // freshness call again.
            // The setTimeout does this on the next tick and
            // allows our unit tests to check the actual `href` property.
            setTimeout(() => {
                anchor.removeAttribute('href');
            });
        });
    }

    getDownloadTooltip() {
        if (!this.link?.downloadableSize || this.link.downloadableSize === '0') {
            return 'Download video';
        } else {
            const bytes = this.bytesPipe.transform(this.link.downloadableSize);
            return `Download video (${bytes})`;
        }
    }

    clickClipCard(clipLink: string, index: number) {
        this.currentIndex = index;
        this.navigateClip(clipLink);
        this.scrollToTop();
    }

    private scrollToTop() {
      if (this.scrollView) {
        this.scrollView.nativeElement.scrollTop = 0;
      }
    }

    clearClipSearch() {
        this.sharedClipsSearchControl.setValue('');
    }

    private getOriginalUrl(link: SharedLink | null): string | undefined {
        return link?.renditions.find((r) => r.version === 'ORIGINAL')?.url;
    }

    private getRawSourceUrl(link: SharedLink | null): string | undefined {
        return link?.userRenditions.renditionMap['RAW_SOURCE']?.url;
    }

    private async readDefaultAudioTracks() {
      const clipBinsInfo = await firstValueFrom(this.clipBinsInfoService.getIASClipBins(this.clipBin, true));
      if (clipBinsInfo.length) {
        this.clipbinStorageService.setClipBinSharing({ clips: clipBinsInfo[0].clips } as IClipBinStorage);
      }
    }
}
