<div infinite-scroll (nearBottom)="onNearBottomForBinsArea()" #scrollableView class="landing-scrollableView" (scroll)="onScroll($event)">
  <div class="centered-content">
    @if(stateService.isRecentVideosLoaded$ | async){
      <!-- Recent Videos Section -->
      <h1>Recent videos</h1>
      <section class="recent" [class.recent--empty]="!isAssetsLoading && !recentAssets.length" [class.loading]="isAssetsLoading"
        [class.overflow-hidden]="scrubbingService.scrubbing$ | async">
        <!-- Assets -->
        @if(!isAssetsLoading){
          <ng-container *ngIf="recentAssets.length; else noAssets">
            <mam-recent-asset *ngFor="let asset of recentAssets; let index = index; trackBy: trackName" [asset]="asset"
              [routerLink]="['/asset', asset.name]" [queryParams]="{'type': 'recent'}" queryParamsHandling="merge"
              mam-firebase-ga="Open recent video" [mam-firebase-resource]="asset.name"
              [isClipbinsAvailable]="cachedBins && cachedBins.length>0"></mam-recent-asset>
          </ng-container>
        }
      </section>
    }

    @if(isCboAvailable) {
      <!-- Clip Bins Section -->
      <mam-clip-bin-section></mam-clip-bin-section>
    } @else {
      @if(hasClipBinsLoaded$ | async) {
        <ng-container *ngIf="bins$ | async as bins">
          <mat-card appearance="outlined" class="empty-bins" *ngIf="!bins.length; else displayBins">
            <img alt="Empty bins" src="images/empty_contents_logo.svg" />
            <div>
              <h1>You have no Clip Bins.</h1>
              <h2>Use the <span><mat-icon>add</mat-icon> New</span> button in the top
                left to create one and start collecting clips.</h2>
            </div>
          </mat-card>

          <ng-template #displayBins>
            <div class="clip-bin-bar">
              <h1 class="clip-bin-header">My clip bins</h1>
            </div>
            <section class="bins">
              <mam-clip-bin-preview *ngFor="let bin of bins; trackBy: trackName" [bin]="bin">
              </mam-clip-bin-preview>
            </section>
          </ng-template>
        </ng-container>
      }
    }
  </div>
</div>

<ng-template #noAssets>
  <mam-empty-assets-card assetsType="Recent videos" [helperText]="true">
    <p>
      Currently, there are no recent videos available to you.
    </p>
  </mam-empty-assets-card>
</ng-template>
