import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DocumentReference } from "firebase/firestore";
import { asyncScheduler, concatMap, EMPTY, first, forkJoin, map, Observable, of, scheduled } from "rxjs";

import { ApiCopyStateDetail } from "../../api/ias/model/models";
import { Metadata } from "../../models";
import { AssetCopy } from "../../services/asset_service";
import { AccessConfirm, AccessConfirmDialog } from "../component/access-confirm-dialog/access-confirm-dialog.component";
import { AccessRestrictionAssetDialog } from "../component/access-restriction-asset-dialog/access-restriction-asset-dialog.component";
import { PermissionDetail, ResourceAccessCutDown } from "../models/access_management.model";

import { AccessManagementService } from "./access_management.service";
import { RestrictionResult } from "./access_management_actions.service";

export type CutdownRestriction = (AssetCopy & Partial<RestrictionResult>);

@Injectable({
  providedIn: 'root'
})
export class AccessManagementCutDownActionsService {

  constructor(
    private readonly dialog: MatDialog,
    private readonly accessManagementService: AccessManagementService,
  ) {}

  getRestrictionAndPopulatePermission(cutdowns: CutdownRestriction[]): Observable<CutdownRestriction[]> {
    if (!cutdowns?.length) return of();

    const cutdownsNames = cutdowns.map(c => c.name);
    const cutdownsDone = cutdowns.map(c => c.vodAssetName).filter(d => !!d) as string[];

    return forkJoin([
      this.accessManagementService.getListCutdownByPathId('RESTRICTION', cutdownsNames, ['CUTDOWN', 'ASSET']),
      this.accessManagementService.getListResourceAccessInfoByResources('RESTRICTION',cutdownsDone,'ASSET')
    ]
      )
      .pipe(
        first(),
        map(([cutdownPermissions, assetsPermissions]) => {
          const result: CutdownRestriction[] = [];

          for (const cutdown of cutdowns) {
            const hasCutdownPermissions = cutdownPermissions?.find((r) => r.resourcePathId === cutdown.name);

            if (hasCutdownPermissions) {
              cutdown.documentId = hasCutdownPermissions.documentId;
              cutdown.permissions = hasCutdownPermissions.permissions;
            }

            const hasAssetPermissions = assetsPermissions?.find((r) => r.resourceId === cutdown.vodAssetName);

            if (hasAssetPermissions) {
              cutdown.documentId = hasAssetPermissions.documentId;
              cutdown.permissions = hasAssetPermissions.permissions;
            }

            result.push(cutdown);
          }

          return result;
        })
      );
  }

  openAccessManagement(peopleAccess: PermissionDetail[], documentId: string) {
    return this.dialog.open(AccessRestrictionAssetDialog,
      {
        autoFocus: false,
        data: {
          permissions: peopleAccess.map((p) => { return { email: p.userId, displayName: p.displayName }; }),
          documentId: documentId
        }
      }).afterClosed()
      .pipe(
        concatMap((dataConfirm) => {
          if (dataConfirm == false) return EMPTY;

          return of(dataConfirm);
        }),
      );
  }

  openRestrictDialog(cutdown: AssetCopy, parentAssetNameId: string, parentPermissions?: PermissionDetail[]): Observable<undefined | DocumentReference> {

    const data: AccessConfirm = {
      icon: 'restrict__custom',
      title: 'Make Cutdown Restricted?',
      content: 'Are you sure you want to restrict this cutdown?',
      buttons: [
        {
          text: 'CANCEL',
          type: 'default',
          value: false
        },
        {
          text: 'CONFIRM',
          type: 'default',
          value: true
        }
      ]
    };

    return this.dialog.open(AccessConfirmDialog, { data })
      .afterClosed()
      .pipe(
        concatMap((confirm) => {
          if (confirm && cutdown) {
            return this.createCutDownRestriction(parentPermissions ?? [], cutdown, parentAssetNameId);
          }

          return EMPTY;
        }),
      );
  }

  createCutDownRestriction(parentPermissions: PermissionDetail[], cutdown: AssetCopy, parentAssetNameId: string) {

    const title = new Metadata(cutdown.metadata).jsonMetadata['Title'] ?? '';
    const data: Partial<ResourceAccessCutDown> = {
      permissions: parentPermissions ?? [],
      resourceAccessType: 'RESTRICTION',
      resourceType: 'CUTDOWN',
      title,
      resourcePathId: cutdown.name,
      cutDownState: cutdown.state,
      parentAssetNameId
    };

    return scheduled(this.accessManagementService.createResourceAccessInfo(data), asyncScheduler).pipe(first());
  }

  openPublicDialog(documentId: string): Observable<void> {

    //Make Asset Public
    const data: AccessConfirm = {
      icon: 'public',
      title: 'Make Asset Public?',
      content: 'This will make the cutdown accessible to everyone. Ensure you\'re ready to share it publicly, as this action may impact privacy and access controls.',
      buttons: [
        {
          text: 'CANCEL',
          type: 'default',
          value: false
        },
        {
          text: 'C0NFIRM',
          type: 'default',
          value: true
        }
      ]
    };

    return this.dialog.open(AccessConfirmDialog, { data })
      .afterClosed()
      .pipe(concatMap((confirm) => {
        if (confirm) {
          return this.makeCutdownPublic(documentId);
        }

        return EMPTY;
      })
      );
  }

  makeCutdownPublic(documentId: string): Observable<void> {
    return scheduled(this.accessManagementService.deleteDocument(documentId), asyncScheduler).pipe(first());
  }

  updateCutDownStatus(documentId: string, cutdownState: ApiCopyStateDetail.StateEnum): Observable<void> {
    return scheduled(this.accessManagementService.updateCutDownState(documentId, cutdownState), asyncScheduler).pipe(first());
  }

  updateCutDownTitle(documentId: string, cutdown: AssetCopy): Observable<void> {
    const title = new Metadata(cutdown.metadata).jsonMetadata['Title'] ?? '';

    return scheduled(this.accessManagementService.updateCutDownTitle(documentId, title), asyncScheduler).pipe(first());
  }
}
