<h1 mat-dialog-title>Move</h1>
<div mat-dialog-content>
  <div class="move-folder__wrapper" [class.loading]="isLoading">
    <h2 class="sub-title">
      <span class="sub-title__text">Current Location:</span>
      <span class="move-folder__folder-icon"><img src="../../images/folder_icon_light.svg" alt="Folder icon"></span>
      @for (folder of data.breadcrumb; track folder) {
        <mat-icon class="move-folder__separator">chevron_right</mat-icon>
        <span class="move-folder__folder-name">{{folder.name}}</span>
      }
      <mat-icon class="move-folder__separator">chevron_right</mat-icon>
      <span class="move-folder__folder-name">{{data.name}}</span>
    </h2>
    <!-- <span class="error" *ngIf="isMaximumLevelError$ | async">You can't move this folder into the selected folder due to maximum depth level.</span> -->
    @if (selectedNestedFolder != null) {
      <div class="move-folder__selected-nested-folder">
        <div class="move-folder__selected-nested-folder__content">
          <div class="icon" (click)="backClicked()">
            <mat-icon>arrow_back</mat-icon>
          </div>
          <div class="folder">
            <span>{{selectedFolder?.name}}</span>
          </div>
        </div>
      </div>
    }

    <hr class="top_hr"/>

    <mat-progress-bar *ngIf="isLoading" mode="indeterminate">
    </mat-progress-bar>

    <div
      [ngClass]="{'move-folder__content--not-nested': !isNestedFolder, 'move-folder__content--nested': isNestedFolder}"
      class="move-folder__content">
      @if (isLoading === false || ((resources$ | async) || []).length) {
        <ul>
          @if (!isNestedFolder) {
            <ng-container  *ngTemplateOutlet="renderSelectHome"></ng-container>
          }
          @for (folder of resources$ | async; track folder) {
            <li
              data-id={{folder.id}}
              (click)="checkDisabledFolder(folder) ? null : selectFolder(folder)"
              class="move-folder_list"
            [ngClass]="{
              'move-folder__content__selected-folder': (selectedFolder && selectedFolder.id === folder.id),
              'move-folder__content__disabled-folder': checkDisabledFolder(folder)
              }">
              <span class="move-folder__folder-icon">
                @if (folder.type === 'folder') {
                  <img src="../../images/folder_icon_light.svg" alt="Folder icon">
                }
                @if (folder.type !== 'folder') {
                  <mat-icon role="img" aria-hidden="true"> subscriptions </mat-icon>
                }
              </span>
              <mat-icon class="move-folder__separator">chevron_right</mat-icon>
              <span class="move-folder__folder-name">{{folder.name}}</span>
              @if (folder.directChildrenCount! > 0) {
                <mat-icon class="move-folder__separator">chevron_right</mat-icon>
              }
            </li>
          }
        </ul>
      } @else {
        <div class="move-folder__loading-content">
          <h1 class="loading_title">Loading results...</h1>
          <p class="loading_message">Please wait</p>
        </div>
      }
    </div>
    <hr />
    <div class="move-folder__selected-folder">
      @if (isMaximumLevelError$ | async) {
        <span class="error">You can't move this folder into the selected folder due
        to maximum depth level.</span>
      }

      @if (hasClipBinInside$ | async) {
        <span class="error">You can't move this folder into the selected folder because
        it has one or more clip bins.</span>
      }

      @if (!isMaximumLevelError$.value && !hasClipBinInside$.value && selectedFolder != null) {
        <div class="move-folder__selected-folder__content"
          >
          <span class="move-folder__selected-folder__text">Selected Location:</span>
          <div class="move-folder__selected-folder__path">
            <span class="move-folder__folder-icon move-folder__move-home">
              <img src="../../images/folder_icon_light.svg" alt="Folder icon">
            </span>
            @if (!isNestedFolder || selectedNestedFolder == null) {
              <mat-icon class="move-folder__separator">chevron_right</mat-icon>
              <span class="move-folder__folder-name">{{selectedFolder.name}}</span>
            } @else {
              <!-- start of path -->
              @for (folder of selectedNestedFolder.breadcrumb; track folder) {
                <span class="move-folder__path">
                  <mat-icon class="move-folder__separator">chevron_right</mat-icon>
                  <span
                    class="move-folder__folder-name text-ellipsis"
                    [matTooltip]="folder.name"
                    matTooltipPosition="below"
                    >
                    {{folder.name}}
                  </span>
                </span>
              }
              <!-- last part of path -->
              @if (selectedFolder) {
                <span class="move-folder__path">
                  <mat-icon class="move-folder__separator">chevron_right</mat-icon>
                  <span
                    class="move-folder__folder-name text-ellipsis"
                    [matTooltip]="selectedFolder.name"
                    matTooltipPosition="below"
                    >
                    {{selectedFolder.name}}
                  </span>
                </span>
              }
            }
          </div>
        </div>
      }

    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    mam-firebase-ga="Cancel folder move" [mam-firebase-resource]="data.name"
    >
    Cancel
  </button>
  <button
    mat-button
    class="primary"
    color="primary"
    #primary
    mam-firebase-ga="Confirm folder move"
    [mam-firebase-resource]="data.name"
    [mat-dialog-close]="true"
    [disabled]="moveButtonDisableCondition()">
    Move
  </button>
</div>



<ng-template #renderSelectHome>
  <li data-id="0" (click)="selectHome()"
    [ngClass]="{'move-folder__content__selected-folder': selectedFolder$.value && selectedFolder$.value.id === '0'}">
    <span class="move-folder__folder-icon">
      <img src="../../images/folder_icon_light.svg" alt="Folder icon">
    </span>
    <span class="move-folder__folder-name move-folder__move-home">Home</span>
  </li>
</ng-template>
