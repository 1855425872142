/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { FirebaseOptions } from 'firebase/app';
import { DurationLike } from 'luxon';

export type EnvironmentType =  'mock' | 'local' | 'localbff' | 'dev' | 'int' | 'preprod' | 'prod' | 'qa' | 'canaryprod'| 'pilotprod'| 'sandbox';

/** Angular runtime environment */
export interface Environment {
  /** Angular environment config tag (ngenv). Must match this filename. */
  tag: EnvironmentType;

  /** Pantheon project id */
  projectId: string;

  /**
   * OAuth2 client id. If set then AuthGuard will be enabled and real api
   * services will be used.
   */
  clientId: string;

  /** OAuth2 Google API scopes. */
  scope?: string;

  /** Configuration for using MAM API. */
  mamApi: MamApiConfiguration;

  /** Configuration for using Pubsub API. */
  pubsubApi?: PubsubApiConfiguration;

  stackDriverErrorReportingApiKey: string;

  resourcesApi?: string;

  /**
   * Configuration for Firebase performance and analytics services.
   */
  firebaseConfig?: FirebaseOptions;

  /** Controls state of Maintenance Mode */
  maintenance: boolean;

  /** Indicates if this is production environment, visible to the customer. */
  isProd: boolean;

  /** If build optimizations should be enabled for this environment */
  compiled: boolean;

  /** Configuration for HLS playback. */
  hlsPlayback?: HlsPlaybackConfiguration;

  /** Table Ids to use on firebase to store views data */
  tableInfoId: {[key:string]: string};

  /** Default suggestions for the search dropdown. */
  defaultSuggestions?: string;
}

/** Empty values for optional properties */
export const environmentDefaults: Readonly<Omit<Environment, 'tag'>> = {
  projectId: '',
  clientId: '',
  scope:
    'https://www.googleapis.com/auth/cloud-platform https://www.googleapis.com/auth/pubsub openid profile email',
  mamApi: {
    baseUrl: '',
    parent: '',
  },

  resourcesApi: '',
  stackDriverErrorReportingApiKey: '',

  maintenance: false,
  isProd: false,
  compiled: true,
  tableInfoId: {}
};

/** Media Asset API configuration */
export interface MamApiConfiguration {
  /** Api base url. E.g. https://intelligentassetservice.googleapis.com */
  baseUrl: string;

  /** API entity prefix. For example `projects/123/locations/global`. */
  parent: string;

  /** Bucket Root Uri prefix. For example `gs://bucket-name/`. */
  bucketRootUri?:string
}

/** Pubsub API configuration */
export interface PubsubApiConfiguration {
  /**
   * The endpoint of Pubsub API:
   * https://cloud.google.com/pubsub/docs/reference/rest#service-endpoint.
   */
  baseUrl: string;

  /** API entity prefix. For example `projects/123/topics/event-uri`. */
  topic: string;
}

export interface HlsPlaybackConfiguration {
  signatureTtl: DurationLike,
  urlMapping: {
    from: string,
    to: string
  }
}
