<mat-card class="empty-bins">
  <img alt="Empty bins" src="images/empty_contents_logo.svg" />
  <div>
    @if (loading) {
      <h1>Loading results...</h1>
      <p>Please wait</p>
    } @else {
      @if (hasSearchText) {
        <h1>Your search did not match any {{ assetsType }}.</h1>
        <p>Please try a different search or create new {{ assetsType }}.</p>
      } @else {
        <h1>You have no {{ assetsType }}.</h1>
        <ng-container *ngIf="helperText">
          <ng-content></ng-content>
        </ng-container>
      }
    }
  </div>
</mat-card>
