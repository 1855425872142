<mat-card
    *ngIf="clip"
    [class.active]="active"
    [class.clip_card--list]="viewMode === 'list'"
    [class.mam-elevation-1]="viewMode === 'grid'"
    [mam-firebase-resource]="clip.title"
    [queryParams]="{'type': 'bin'}"
    [routerLink]="['/clipbin', clip.label, 'clip', clip.name]"
    appearance="outlined"
    class="clip_card"
    mam-firebase-ga="Open clip from its preview"
    queryParamsHandling="merge"
>
    <div class="card-header-image">
        <mam-seekable-preview
            [asset]="clip"
            [previewUrl]="(clip | bestSeekUrl: clip.duration| async)!"
        ></mam-seekable-preview>
    </div>
    <div class="card-content">
        <div class="card-content-width">
            <div [attr.title]="clip.title" class="card-content-title">{{ clip.title }}</div>
            <div class="card-content-subtitle">Duration: {{ getDuration(clip.duration) }}</div>
        </div>
    </div>
</mat-card>
