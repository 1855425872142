import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from '@angular/router';

import { AuthService } from '../../../auth/auth_service';
import { ClipbinsOwner } from '../../../services/bin_api.service';

import { ResourceTypes } from './resource-types';
import { PaginationInfo, ResourceService } from './resource.service';
import { SelectionService } from './selection.service';

/**
 * Service to handle refreshing resources and closing dialogs after actions like creation, deletion, or updates.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogResourceRefreshService {
  constructor(
    private router: Router,
    private resourceService: ResourceService,
    private selectionService: SelectionService,
    private authService: AuthService
  ) {}

  /**
   * Reloads resources based on the current context (folder or top-level) and closes the dialog.
   *
   * @param dialogRef - Reference to the MatDialog.
   * @param data - Optional data passed from the dialog, used to determine pagination.
   */
  reloadResourcesAndClose(dialogRef: MatDialogRef<unknown>, data?: unknown): void {
    const isFolderContext = this.isFolderUrl();
    isFolderContext
      ? this.refreshFolderContent(this.getCurrentUrlPath(), dialogRef)
      : this.refreshTopLevel(dialogRef, data);
  }

  /**
   * Checks if the current URL indicates a folder context.
   *
   * @private
   * @returns True if the URL includes 'folder', false otherwise.
   */
  private isFolderUrl(): boolean {
    return this.getCurrentUrlPath().includes('folder');
  }

  /**
   * Retrieves the current URL path.
   *
   * @private
   * @returns The current URL path as a string.
   */
  private getCurrentUrlPath(): string {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);
    const segmentGroup: UrlSegmentGroup | undefined = urlTree.root.children[PRIMARY_OUTLET];
    return segmentGroup ? segmentGroup.toString() : this.router.url;
  }

  /**
   * Determines the current search mode (clipbin or folder).
   *
   * @private
   * @returns The current search mode.
   */
  private getSearchMode(): typeof ResourceTypes[keyof typeof ResourceTypes] {
    return this.resourceService.store$.value?.searchMode === 'bins'
      ? ResourceTypes.CLIPBIN
      : ResourceTypes.FOLDER;
  }

  /**
   * Retrieves the owner of the resources based on the search mode.
   *
   * @private
   * @param searchMode - The current search mode.
   * @returns The owner identifier or undefined.
   */
  private getOwner(searchMode: typeof ResourceTypes[keyof typeof ResourceTypes]): string | undefined {
    if (this.resourceService.currentOwner.value === ClipbinsOwner.USER) {
      return searchMode === ResourceTypes.CLIPBIN ? `current_user` : this.authService.getUserEmail();
    }
    return undefined;
  }

  /**
   * Refreshes the content of a folder and closes the dialog.
   *
   * @private
   * @param url - The URL of the folder.
   * @param dialogRef - Reference to the MatDialog.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any --- The type of dialogRef.close's argument is determined dynamically based on the resource service response.
  private refreshFolderContent(url: string, dialogRef: MatDialogRef<unknown>): void {
    const searchMode = this.getSearchMode();
    const folderId = url.split('/')[1];

    this.resourceService.getResourceChildren(searchMode, folderId).subscribe((result) => {
      dialogRef.close(result);
      this.selectionService.setSelectAll(false);
    });
  }

  /**
   * Refreshes the top-level resources and closes the dialog.
   *
   * @private
   * @param dialogRef - Reference to the MatDialog.
   * @param data - Optional data used to determine pagination.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any --- The type of dialogRef.close's argument is determined dynamically based on the resource service response.
  private refreshTopLevel(dialogRef: MatDialogRef<unknown>, data?: unknown): void {
    const searchMode = this.getSearchMode();
    const owner = this.getOwner(searchMode);
    const pagination = this.getPagination(data);

    this.resourceService.getResource(searchMode, pagination, { owner, searchTerm: '', level: 0 }).subscribe(() => {
      dialogRef.close();
      this.selectionService.setSelectAll(false);
    });
  }

  /**
   * Retrieves pagination information based on the provided data or current state.
   *
   * @private
   * @param data - Optional data used to determine pagination.
   * @returns Pagination information.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any --- The type of data is dynamically determined based on the dialog input.
  private getPagination(data?: unknown): PaginationInfo {
    const registeredRows = this.selectionService.getRegisteredTopLevelRows();
    const limit = Array.isArray(data) ? registeredRows.length : this.resourceService.paginationInfo$.value.limit;
    return {
      limit: limit,
      offset: 0,
      startAfter: undefined,
    };
  }
}
