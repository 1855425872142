@if (labels$ | async; as labels) {
  <!-- Root breadcrumb item -->
  <button class="label item-header"
          id="root-button-ellipsis"
          mat-button
          (click)="activeButton = '0'"
          [class.collapsed]="activeButton === '0'">
    ...
  </button>
  <button
    class="label breadcrumb-body breadcrumb__button--spacing"
    id="root-button"
    [class.expanded]="activeButton === '0'"
    mat-button
    (click)="onRootLabelClick()"
    mam-firebase-ga="Select the root breadcrumb item"
    [mam-firebase-resource]="labels.root"
  >
    {{ labels.root }}
  </button>
  @if (labels.clipbin) {
    <!-- Clipbin breadcrumb item -->
    <mat-icon class="separator">chevron_right</mat-icon>
    @if (breadcrumbs && breadcrumbs.length) {
      <div class="breadcrumb-container">
        @for (breadcrumb of breadcrumbs; track breadcrumb.id) {
        <button
          class="label item-header"
          id="ancestor-button-ellipsis-{{ breadcrumb.id }}"
          mat-button
          (click)="activeButton = breadcrumb.id"
          [class.collapsed]="activeButton === breadcrumb.id"
        >
          ...
        </button>
        <div class="item-body"
             [class.expanded]="activeButton === breadcrumb.id">
          <button
            class="label"
            id="ancestor-button"
            mat-button
            [title]="breadcrumb.name"
            (click)="onClipbinAncestorClicked(breadcrumb)"
          >
            <mat-icon class="breadcrumb-folder-icon">folder</mat-icon>
            {{ breadcrumb.name }}
          </button>
        </div>
        <mat-icon class="separator"
                  style="margin-left: 0; padding-left: 0">chevron_right
        </mat-icon>
      }
      </div>
    }
    <div class="clipbin-container">
      <button class="label item-header"
              mat-button
              (click)="activeButton = '1'"
              [class.collapsed]="activeButton === '1'">
        ...
      </button>
      <div class="item-body"
           [class.expanded]="activeButton === '1'">
        <button
          class="label"
          id="clipbin-button"
          mat-button
          [title]="labels.clipbin.title"
          (click)="onClipbinLabelClick(labels.clipbin)"
          mam-firebase-ga="Select clip bin breadcrumb item"
          [mam-firebase-resource]="labels.clipbin.name"
        >
          <mat-icon class="clipbin-icon">subscriptions</mat-icon>
          {{ labels.clipbin.title }}
        </button>

        <!-- Copy clipbin url button -->
        <button
          id="clipbin-button-copy"
          mat-icon-button
          [cdkCopyToClipboard]="getClipbinUrl()"
          (click)="showValueCopiedSnackbar('Clip Bin URL')"
          mam-firebase-ga="Copy Clip Bin Link"
          aria-label="Copy Clip Bin Link"
          matTooltip="Copy Clip Bin Link"
          class="clipboard clipboard-clipbin main__icon--size"
        >
          <mat-icon>link</mat-icon>
        </button>
      </div>
    </div>
  }
  <!-- Main Asset -->
  @if (labels.asset?.title || labels.primaryCameraAsset) {
    <mat-icon class="separator">chevron_right</mat-icon>
    <button class="label item-header"
            id="asset-button-ellipsis"
            mat-button
            (click)="activeButton = '2'"
            [class.collapsed]="activeButton === '2'">
      ...
    </button>
    <div class="item-body expanded asset-name-block"
         [class.expanded]="activeButton === '2'">
      @if (!labels.primaryCameraAsset) {
        <div class="title"
             [title]="labels.asset?.title">{{ labels.asset?.title }}
        </div>
      } @else {
        <!-- Primary asset breadcrumb item -->
        <button
          id="primary-asset-button"
          class="label primary-asset-button"
          mat-button
          [title]="labels.primaryCameraAsset!.title"
          (click)="onPrimaryCameraAssetClick(labels.primaryCameraAsset!)"
          mam-firebase-ga="Select primary asset breadcrumb item"
          [mam-firebase-resource]="labels.primaryCameraAsset!.name"
        >
          {{ labels.primaryCameraAsset!.title }}
        </button>
        <!-- Camera label breadcrumb item -->
        <mat-icon class="separator">chevron_right</mat-icon>
        <div class="title camera-label"
             [title]="labels.cameraLabel">{{ labels.cameraLabel }}
        </div>
      }
      <!-- Primary camera asset title and current asset camera tag for multi-camera events -->
      <!-- Copy asset url button -->
      <button
        id="asset-button-copy"
        mat-icon-button
        [cdkCopyToClipboard]="getAssetUrl()"
        (click)="showValueCopiedSnackbar(originalOrClip(labels) + ' URL')"
        [mam-firebase-ga]="'Copy ' + originalOrClip(labels) + ' Link'"
        [attr.aria-label]="'Copy ' + originalOrClip(labels) + ' Link'"
        [matTooltip]="'Copy ' + originalOrClip(labels) + ' Link'"
        class="main__icon--size clipboard clipboard-asset"
      >
        <mat-icon>link</mat-icon>
      </button>
      @if (labels.asset) {
        <mam-asset-info-icon-button [asset]="labels.asset"></mam-asset-info-icon-button>
      }
    </div>
  }
}
