import { map, Observable } from 'rxjs';

/**
 *
 */
export function mapSearchEvent<T>(source: string) {
  return map((value: T) => ({
    source,
    value,
    timestamp: Date.now()
  }));
}

/**
 *
 */
export function mapSearchEventStream<T>(searchEventStream: Observable<T>, source: string) {
  return searchEventStream.pipe( mapSearchEvent<T>(source));
}
