import { Router, UrlSegment, UrlTree } from '@angular/router';

/**
 *
 */
export function extractUrlDetails(router: Router): { isNestedFolder: boolean; parentId?: string } {
  const urlTree: UrlTree = router.parseUrl(router.url);
  const urlPathSegments: UrlSegment[] = urlTree.root.children['primary']?.segments;
  const isNestedFolder = urlPathSegments?.[0]?.path === 'folders';
  const parentId = urlPathSegments?.[1]?.path;
  return { isNestedFolder, parentId };
}
