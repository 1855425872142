import {Inject, Injectable, InjectionToken} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {defer, Observable, of} from 'rxjs';
import {catchError, finalize, switchMap, take, tap} from 'rxjs/operators';

import {assertExists, checkExhaustive} from 'asserts/asserts';

import {AuthService} from '../auth/auth_service';
import {environment} from '../environments/environment';
import {ErrorService} from '../error_service/error_service';
import {FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {FirebaseFirestoreDataService, IASEventType} from '../firebase/firebase_firestore_data_service';
import {FirebasePerformanceService, Trace, TraceName} from '../firebase/firebase_performance_service';
import {PublishNeverExpiredService} from '../internal_pubsub/publish_never_expired_service';

import {AssetService, PermissionStatus} from './asset_service';

/** Cached authorization duration before it expires, in milliseconds. */
export const AUTHORIZATION_CACHE_DURATION = 1000 * 3600;  // 1 hour

/**
 * Whether the current environment is under maintenance, which redirects
 * users to a special page.
 */
export const IS_MAINTENANCE = new InjectionToken<boolean>(
    'Is Maintenance', {factory: () => environment.maintenance});

/**
 * AuthGuard to protect navigation safely across pages.
 * Check OAuth first, if it fails, redirect to login page.
 * Then check if the user has IAM permission, if it fails, redirect to
 * unauthorized page. If both pass, redirect to original requested route.
 */
@Injectable()
export class AuthGuard {
  constructor(
      private readonly assetService: AssetService,
      protected readonly authService: AuthService,
      private readonly router: Router,
      private readonly performanceService: FirebasePerformanceService,
      private readonly analyticsService: FirebaseAnalyticsService,
      protected readonly errorService: ErrorService,
      private readonly dataService: FirebaseFirestoreDataService,
      private readonly publishNeverExpiredService: PublishNeverExpiredService,
      @Inject(IS_MAINTENANCE) private readonly isMaintenance: boolean,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean|UrlTree> {
    if (this.authService.isUnauthorized) return of(false);

    return defer(() => this.authService.isLoggedIn())
        .pipe(
            catchError((error) => {
              this.errorService.handle(error);
              this.redirect('/error', error.message);
              return of(false);
            }),
            switchMap((loggedIn: boolean) => {
              return this.processLogging(loggedIn, state.url);
            }));
  }

  /** Optional promise that resolves once the current verification is done. */
  private ongoingVerification?: Promise<void>|undefined;

  /** Checks current user status and permission. */
  private async processLogging(loggedIn: boolean, requestedUrl: string):
    Promise<boolean|UrlTree> {
    if (!loggedIn) {
      this.authService.saveRequestedUrl(requestedUrl);
      return this.router.createUrlTree(['/login']);
    }

    const profile = this.authService.getUserProfile();
    if (!profile) {
      this.authService.logout();
      this.authService.saveRequestedUrl(requestedUrl);
      return this.router.createUrlTree(['/login']);
    }

    // Redirect non-googlers to a static page if the environment is under
    // maintenance.
    if (this.isMaintenance && !this.authService.isInternalUser()) {
      return this.redirect('/maintenance');
    }

    let permissionStatus$: Observable<PermissionStatus>;
    let authTrace: Trace|undefined = undefined;

    // Wait for any ongoing verification so that we don't make an extra
    // verification API call, and instead wait for its response side-effect.
    await this.ongoingVerification;

    const authorizedFor = Date.now() - this.authService.authorizedTime;
    if (authorizedFor < AUTHORIZATION_CACHE_DURATION) {
      // User is already authorized and it has not expired yet,
      // skip the API call.
      permissionStatus$ = of(PermissionStatus.AUTHORIZED);
    } else {
      // Obtain permission status from the backend.
      authTrace =
          this.performanceService.startTrace(TraceName.AUTH_CHECK_PERMISSION);
      permissionStatus$ = this.checkPermission();
    }

    // Verify permission status of the backend in parallel to loading the UI to
    // not delay it.
    this.verifyPermission(permissionStatus$, authTrace);

    await this.authService.readAdminStatus();

    this.storeIASEventForLoginAction();

    this.publishMessagesForLoginAction();


    return true;
  }


  async storeIASEventForLoginAction() {
    const email = this.authService.getUserEmail();
    this.dataService.retrieveDocumentForLoginAction(email)
        .pipe(take(1))
        .subscribe({
          next: (documents) => {
            const iasEvent = {
              type: IASEventType.LOGIN,
              createTime: new Date().toISOString(),
            };
            if (documents.length) {
              this.dataService.updateDocument(documents[0].id, iasEvent)
                  .catch(error => this.errorService.handle(`Can't update login event: ${error}`));
            } else {
              this.dataService.createIASEvent(iasEvent)
                  .catch(error => this.errorService.handle(`Can't create login event: ${error}`));
            }
          },
          error: (error) => {
            this.errorService.handle(`Can't read login event: ${error}`);
          },
        });
  }

  async publishMessagesForLoginAction() {
    const email = this.authService.getUserEmail();
    const token = await this.authService.getActiveAccessToken();
    this.publishNeverExpiredService.publishNeverExpiredMessage(email, token).subscribe();
  }

  protected redirect(path: string, message = '', extras = {skipLocationChange: true} as NavigationExtras) {
    if (message) {
      extras.state = {'message': message};
    }
    return this.router.navigate([path], extras);
  }

  private checkPermission() {
    this.authService.authorizedTime = 0;
    return this.assetService.checkPermission().pipe(tap(permission => {
      if (permission === PermissionStatus.AUTHORIZED) {
        // Cache authorization until page is fully reloaded, or until it expires
        this.authService.authorizedTime = Date.now();
      }
    }));
  }

  /**
   * Verify permission status. If a user cannot make API calls, they will
   * be redirected to the "Unauthorized" or "Error" page.
   */
  private verifyPermission(
      permissionStatus$: Observable<PermissionStatus>, authTrace?: Trace) {
    this.toggleFullUiVisibility(false);

    const onVerified = this.startOngoingVerification();

    permissionStatus$
        .pipe(finalize(() => {
          this.toggleFullUiVisibility(true);
        }))
        .subscribe(permissionStatus => {
          // A 403 error has been intercepted by `MamApiInterceptor` and the
          // user redirected to `/unauthorized`, nothing to do from here.
          if (this.authService.isUnauthorized) return;

          onVerified();

          authTrace?.stop({
            attributes: {status: PermissionStatus[permissionStatus]},
          });

          switch (permissionStatus) {
            case PermissionStatus.AUTHORIZED: {
              const requestedUrl = this.authService.loadRequestedUrl();
              if (requestedUrl) {
                // User went through login redirection and can now
                // access their desired URL.
                this.authService.saveRequestedUrl(null);
                return this.router.navigateByUrl(requestedUrl);
              }

              // User is logged-in and authorized.
              return;
            }
            case PermissionStatus.UNAUTHORIZED:
              this.errorService.handle('Unauthorized user access attempt');
              this.authService.isUnauthorized = true;
              return this.redirect('/unauthorized');
            case PermissionStatus.UNRESOLVED:
              this.errorService.handle('User authorization check failure');
              return this.redirect('/error');
            default:
              checkExhaustive(permissionStatus);
          }
        });
  }

  /**
   * Assigns a promise to `ongoingVerification` and returns its deferred
   * completion callback.
   */
  private startOngoingVerification() {
    let onVerified!: () => void;
    this.ongoingVerification = new Promise(resolve => {
      onVerified = resolve;
    });
    return onVerified;
  }

  /**
   * Toggles the visibility of the entire app outside of Angular framework
   * to let the UI load in the background and only show it once the
   * authorization call has completed.
   */
  private toggleFullUiVisibility(visible: boolean) {
    const mam = document.querySelector<HTMLElement>('mam-app');
    assertExists(mam);
    // Visually hide the UI but preserve the layout dimensions.
    mam.style.visibility = visible ? 'visible' : 'hidden';
  }
}

@Injectable()
export class AuthAdminGuard extends AuthGuard {
  override canActivate(): Observable<boolean | UrlTree> {
    if (!this.authService.isAdmin) {
      this.errorService.handle('Regular user attempted to access an admin resource.');
      const extras = {replaceUrl: true, queryParamsHandling: 'preserve'} as NavigationExtras;
      this.redirect('/error', 'Only administrators can access Admin page.', extras);
      return of(false);
    }
    return of(true);
  }
}
