<h1 mat-dialog-title>Move</h1>
<div mat-dialog-content>
  <div class="move-folder__wrapper" [class.loading]="isLoading">
    <h2 class="sub-title">
      <span class="sub-title__text">Current Location:</span>
      <div class="sub-title__path">
        <span class="move-folder__folder-icon">
          <img src="../../images/folder_icon_light.svg" alt="Folder icon" />
        </span>
        @for (folder of resource?.breadcrumb; track folder) {
          <mat-icon class="move-folder__separator">chevron_right</mat-icon>
          <span class="move-folder__folder-name">{{folder.name}}</span>
        }
        <mat-icon class="move-folder__separator">chevron_right</mat-icon>
        <span class="move-folder__folder-name">{{resource?.name}}</span>
      </div>
    </h2>
    @if (selectedNestedFolder != null) {
      <div class="move-folder__selected-nested-folder">
        <div class="move-folder__selected-nested-folder__content">
          <div class="icon" (click)="backClicked()">
            <mat-icon>arrow_back</mat-icon>
          </div>
          <div class="folder">
            <span>{{selectedFolder?.name}}</span>
          </div>
        </div>
      </div>
    }

    <hr class="top_hr"/>

    <mat-progress-bar *ngIf="isLoading" mode="indeterminate">
    </mat-progress-bar>

    <div
      [ngClass]="{'move-folder__content--not-nested': !isNestedFolder, 'move-folder__content--nested': isNestedFolder}"
      class="move-folder__content"
      >
      @if (isLoading === false || ((resources$ | async) || []).length) {
        <ul>
          @if (!isNestedFolder) {
            <ng-container  *ngTemplateOutlet="renderSelectHome"></ng-container>
          }
          @for (resource of resources$ | async; track resource) {
            <li class="move-folder_list"
              data-id="{{resource.id}}"
              (click)="checkDisabledFolder(resource) ? null : selectFolder(resource)"
                        [ngClass]="{
                          'move-folder__content__selected-folder': selectedFolder && selectedFolder.id === resource.id,
                          'move-folder__content__disabled-folder': checkDisabledFolder(resource)
                        }">
              <span class="move-folder__folder-icon">
                @if (resource.type === 'folder') {
                  <img src="../../images/folder_icon_light.svg" alt="Folder icon" />
                }
                @if (resource.type !== 'folder') {
                  <mat-icon role="img" aria-hidden="true" class="p-r-8"> subscriptions </mat-icon>
                }
              </span>
              @if (resource.type === 'folder') {
                <mat-icon class="move-folder__separator">chevron_right</mat-icon>
              }
              <span class="move-folder__folder-name">{{resource.name}}</span>
              @if (resource.type === 'folder' && resource.directChildrenCount! > 0) {
                <mat-icon class="move-folder__separator">chevron_right</mat-icon>
              }
            </li>
          }
        </ul>
      } @else {
        <div class="move-folder__loading-content">
          <h1 class="loading_title">Loading results...</h1>
          <p class="loading_message">Please wait</p>
        </div>
      }
    </div>
    <hr />
    <div class="move-folder__selected-folder">
      @if (hasFolderInside$ | async) {
        <span class="error"
          >You can't move this clip bin into the selected folder because it already has one or more
          subfolders.</span
          >
        }
        @if (!hasFolderInside$.value && selectedFolder != null) {
          <div
            class="move-folder__selected-folder__content"
            >
            <span class="move-folder__selected-folder__text">Selected Location:</span>
            <div class="move-folder__selected-folder__path">
              <span class="move-folder__folder-icon">
                <img src="../../images/folder_icon_light.svg" alt="Folder icon" />
              </span>
              @if (!isNestedFolder || selectedNestedFolder == null) {
                <mat-icon class="move-folder__separator">chevron_right</mat-icon>
                <span class="move-folder__folder-name">{{selectedFolder.name}}</span>
              } @else {
                <!-- start of path -->
                @for (folder of selectedNestedFolder.breadcrumb; track folder) {
                  <span class="move-folder__path">
                    <mat-icon class="move-folder__separator">chevron_right</mat-icon>
                    <span
                      class="move-folder__folder-name text-ellipsis"
                      [matTooltip]="folder.name"
                      matTooltipPosition="below"
                      >
                      {{folder.name}}
                    </span>
                  </span>
                }
                <!-- last part of path -->
                @if (selectedFolder) {
                  <span class="move-folder__path">
                    <mat-icon class="move-folder__separator">chevron_right</mat-icon>
                    <span
                      class="move-folder__folder-name text-ellipsis"
                      [matTooltip]="selectedFolder.name"
                      matTooltipPosition="below"
                      >
                      {{selectedFolder.name}}
                    </span>
                  </span>
                }
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel folder move" [mam-firebase-resource]="resource?.name"
      >
      Cancel
    </button>
    <button
      mat-button
      class="primary"
      color="primary"
      #primary
      mam-firebase-ga="Confirm folder move"
      [mam-firebase-resource]="resource?.name"
      [mat-dialog-close]="true"
      [disabled]="moveButtonDisableCondition()"
      >
      Move
    </button>
  </div>



  <ng-template #renderSelectHome>
    <li data-id="0" (click)="selectHome()"
    [ngClass]="{
      'move-folder__content__selected-folder': selectedFolder$.value && selectedFolder$.value.id === '0',
      'move-folder__content__home-item': true
      }">
      <span class="move-folder__folder-icon">
        <mat-icon role="img" aria-hidden="true"> subscriptions </mat-icon>
      </span>
      <span class="move-folder__folder-name move-folder__separator move-folder__v-align">Home</span>
    </li>
  </ng-template>
