import {ChangeDetectorRef, Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';

import {StagingService, StagingView} from '../right_panel/staging_service';
import {AssetService, Original} from '../services/asset_service';
import {StateService} from '../services/state_service';
import {TableUtils} from '../services/table_utils';

/**
 * Base class for Live and VoD staging tables that contains shared logic.
 */
// eslint-disable-next-line @angular-eslint/use-component-selector, @angular-eslint/prefer-on-push-component-change-detection, @angular-eslint/component-max-inline-declarations -- Abstract component should not be used from HTML
@Component({template: '<b>Abstract class, should not be used</b>'})
export abstract class StagingTable implements OnInit, OnDestroy {
  @HostBinding('class.loading') loading = false;

  @Output() readonly scrollTopNeeded = new EventEmitter();

  abstract readonly view: StagingView;

  /**
   * Data source for the table.
   *
   * Keeping asset array separate from cache allows to keep displaying existing
   * items on page refresh.
   */
  assets: Original[]|undefined = undefined;

  constructor(
      readonly stagingService: StagingService,
      readonly tableUtils: TableUtils,
      readonly assetService: AssetService,
      protected readonly cdr: ChangeDetectorRef,
      protected readonly stateService: StateService,
      protected readonly router: Router
  ) {
    // Refresh approved / changed assets in the table.
    // delay(0) is needed to invoke subscription in async fashion, otherwise we
    // may run into scenario when a method of child class (updateCache) is
    // invoked before child class is initialized.
    this.assetService.assetsChanged$.pipe(delay(0), takeUntil(this.destroyed$))
        .subscribe(({updates}) => {
          if (!updates) return;

          const assets = [...this.assets ?? []];
          let isUpdateNeeded = false;

          // Go over a list of assets and replace outdated ones with the onces
          // from assetsChanged$
          for (let i = 0; i < assets.length; i++) {
            const updated = updates.get(assets[i].name);
            if (!updated) continue;

            assets[i] = updated;
            isUpdateNeeded = true;
          }

          // If there are any updated assets refresh the table.
          if (isUpdateNeeded) {
            this.assets = assets;

            this.refreshActiveAndSelectedItems();
            this.cdr.markForCheck();
          }

          // Both live and VoD staging tables cache response pages.
          // Logic above updates only the currently displayed assets (current
          // cache page) which should be sufficient for now. But in theory
          // assetsChanged could include an asset from a different cached page.
          // Here we call updateCache which may be implemented by the concrete
          // staging table to update the full cache.
          this.updateCache(updates);
        });
  }

  /**
   * Toggles an asset selection if shift is not pressed. Otherwise toggles a
   * block of items based on target asset and anchor asset.
   */
  toggleSelection(
      asset: Original, selectedNames: Set<string>, shiftPressed = false) {
    if (!this.assets) return;

    const {itemsToSelect, itemsToUnSelect, anchorName} =
        this.tableUtils.processMultiSelect({
          items: this.assets,
          target: asset,
          selectedNames,
          shiftPressed,
          anchorName: this.selectionAnchorAssetName,
          canBeSelected: asset => this.canBeSelected(asset),
        });

    this.selectionAnchorAssetName = anchorName;
    this.stagingService.updateSelection(itemsToSelect, itemsToUnSelect);

    // Remove selection that can be caused by shift-clicking.
    document.getSelection()?.removeAllRanges();
  }

  ngOnInit() {
    this.stagingService.activeView$.next(this.view);
    if (!this.stagingService.getActive()) {
      // Initially hide persistent panel when there is nothing to display
      this.stateService.togglePersistentPanel$.next(false);
    }
  }

  /**
   * Makes the row active when shift is not pressed. Otherwise toggles
   * selection.
   */
  selectOrActivate(
      asset: Original, selectedAssetSet: Set<string>, shiftPressed?: boolean) {
    if (!shiftPressed) {
      this.stagingService.setActive({assets: [asset]});
      return;
    }
    this.toggleSelection(asset, selectedAssetSet, shiftPressed);
  }

  getSelectionInfo(assets: Original[], selectedNames: Set<string>) {
    return this.tableUtils.getSelectionInfo(
        assets,
        selectedNames,
        asset => this.canBeSelected(asset),
    );
  }

  canBeSelected(asset: Original) {
    return !asset.isDeleted;
  }

  async edit(selectedAssets: Original[]) {
    await this.executeBlockingAction(
        () => this.stagingService.edit(selectedAssets));
  }

  async approve(selectedAssets: Original[]) {
    await this.executeBlockingAction(() => {
        return this.stagingService.approve(selectedAssets);
    });
  }

  async addClipsToBins(selectedAssets: Original[]) {
    await this.executeBlockingAction(
        () => this.stagingService.addClipsToBins(selectedAssets));
  }

  async deleteAssets(selectedAssets: Original[]) {
    await this.executeBlockingAction(
        () => this.stagingService.deleteAssets(selectedAssets));
  }

  async purgeAssets(selectedAssets: Original[]) {
    await this.executeBlockingAction(
        () => this.stagingService.purgeAssets(selectedAssets));
  }

  async syncMetadata(selectedAssets: Original[]) {
    await this.executeBlockingAction(
        () => this.stagingService.syncMetadata(selectedAssets));
  }

  async extendAssetsTtl(selectedAssets: Original[]) {
    await this.executeBlockingAction(
        () => this.stagingService.extendAssetsTtl(selectedAssets));
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();

    // Clear any staging asset selection
    this.stagingService.resetState();
  }

  /** No-op in base class but can be overridden in children */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  protected updateCache(changedItems: Map<string, Original>) {}

  /** Asset that will be use for multi-select (shift+click) operations.  */
  protected selectionAnchorAssetName = '';
  protected readonly destroyed$ = new ReplaySubject<void>(1);

  /**
   * Ensures that
   * - Active items contain only up-to-date items available in the
   * table.
   * - Selected items contain only items that are available in the
   * table and can be selected.
   */
  protected refreshActiveAndSelectedItems() {
    this.refreshActiveItems();
    this.refreshSelectedItems();
  }

  /**
   * Ensures that active items contain only up-to-date items available in the
   * table.
   */
  protected refreshActiveItems() {
    const current = this.stagingService.getActive();
    // Nothing to update.
    if (!current || !this.assets) return;

    // ASSETS.
    // Drop active assets if they are no longer present in the table, or if they
    // were deleted. Otherwise provided updated versions.
    if (current.assets) {
      const currentNames = new Set(current.assets.map(a => a.name));
      const updated =
          this.assets.filter(a => currentNames.has(a.name) && !a.isDeleted);
      this.stagingService.setActive(
          {assets: updated, skipPanelExpansion: true});
      // Active items can't have both assets and cutdowns.
      return;
    }

    // CUTDOWNS.
    if (!current.cutdowns.length) return;

    // Locate cutdown parent within updated assets and get its cutdowns.
    const updatedParent =
        this.assets.find(asset => asset.name === current.cutdownParent.name);
    if (!updatedParent) {
      // Parent asset doesn't exist anymore => close metadata panel.
      this.stagingService.setActive(undefined);
      return;
    }
  }

  /**
   * Ensures that selected items contain only items that are available in the
   * table and can be selected.
   */
  protected refreshSelectedItems() {
    const current = this.stagingService.getSelection();
    if (!current.size || !this.assets) return;

    const updatedSelection = this.assets.filter(asset => {
      return this.canBeSelected(asset) && current.has(asset.name);
    });

    // Selection has not changed.
    if (updatedSelection.length === current.size) return;

    this.stagingService.select(updatedSelection);
  }

  private async executeBlockingAction(action: () => Promise<void>) {
    if (this.loading) return;
    this.loading = true;
    await action();
    this.cdr.markForCheck();
    this.loading = false;
  }
}
