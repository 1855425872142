<h1 mat-dialog-title>Create New Folder</h1>
<div mat-dialog-content
    (keyup.enter)="keyEnabled && dialogRef.close(true)"
>
  <br>
  <p
    class="error"
    *ngIf="maxLevelRestriction"
    [hidden]="!maxLevelRestriction">You can't create folders more than 3 levels deep</p>
  <p
    class="error"
    *ngIf="isAnotherOwnedFolder">Unable to create folder: You can only create folders within your own folders.</p>
  <p
    class="error"
    *ngIf="hasClipBinInsideError$ | async">Unable to create folder: This folder has a clip bin inside.</p>

  <p
    class="error"
    *ngIf="hasNotAllowedCharacters$ | async"
  >Special characters !&#64;#$%^&*() are not allowed.</p>

  <p
    class="error"
    *ngIf="wordNotAllowed$ | async"
  >The word 'Home' is not allowed as a folder name.</p>

  <form [formGroup]="form">
    <mat-form-field class="name-field" appearance="outline" floatLabel="always">
      <input
          matInput
          required
          placeholder="Folder name"
          autocomplete="off"
          formControlName="folderName"
          (keyup.enter)="dialogRef.close(true)"
          />
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button
      mam-firebase-ga="Cancel folder creation"
      mat-button
      mat-dialog-close
  >
    Cancel
  </button>

  <button
      mat-button
      class="primary"
      color="primary"
      mam-firebase-ga="Confirm folder creation"
      [mat-dialog-close]="true"
      (keyup.enter)="dialogRef.close(true)"
      [disabled]="isDisabled()"
  >
    Create
  </button>
</div>
