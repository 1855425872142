import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth/auth_service';

import { ELASTICSEARCH_ACCESS_MANAGEMENT_ENDPOINT } from './elastic_search.constants';
import { RestrictionElasticHitSource } from './elastic_search.model';

@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {
  constructor(
    private _http: HttpClient,
    private readonly authService: AuthService,
  ) {
  }

  getResourceAccessInfoByTextInTitle(title: string) {
    const accessToken = this.authService.getAccessToken();
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
    };
    return this._http.post(ELASTICSEARCH_ACCESS_MANAGEMENT_ENDPOINT, { title }, { headers })
      .pipe(
        map((res) => {
          return res as RestrictionElasticHitSource[];
        }),
      );
  }
}
