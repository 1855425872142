import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BinsChange } from '../../../services/bin.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceActionService {
  private binCreatedSubject = new Subject<BinsChange | undefined>();

  binCreated$ = this.binCreatedSubject.asObservable();

  publishBinCreated(event: BinsChange | undefined) {
    this.binCreatedSubject.next(event);
  }
}
