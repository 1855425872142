<mat-toolbar class="upper-toolbar">
  <a class="title" (click)="goHome()">
    <span class="product-icon">
      <img src="images/Logo_Collab.svg" width="35px" height="35px" alt="IAS logo">
    </span>
    <h1>Intelligent Asset Service</h1>
  </a>
</mat-toolbar>
<section class="content">
  <div>
    <img src={{currentView.image}} alt="No permission logo">
  </div>
  <h1>{{currentView.title}}</h1>
  <h2 *ngIf="extraMessage">{{extraMessage}}</h2>
  <h2>{{currentView.body}}</h2>
  <button
      mat-stroked-button
      [mam-firebase-ga]="'Back to login from ' + currentView.type"
      (click)="goHome()"
      *ngIf="currentView.type !== 'not_found'">
    Try Again
  </button>
</section>
