import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { tap } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { ErrorResponse } from '../../error_service/error_response';
import { AssetService, Clip } from '../../services/asset_service';
import { Bin } from '../../services/bin.service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinFolderDeleteDialog } from '../../shared/clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import { ClipBinFolderMoveDialog } from '../../shared/clipbin_folder_move_dialog/clipbin_folder_move_dialog';
import { ClipBinFolderUpdateDialog } from '../../shared/clipbin_folder_update_dialog/clipbin_folder_update_dialog';
import { USER_ICON_PLACEHOLDER } from '../../shared/toolbar_menu';
import { ResourceTypes } from '../clip-bin-section/service/resource-types';
import { Resource, ResourceService } from '../clip-bin-section/service/resource.service';
import { SelectionService } from '../clip-bin-section/service/selection.service';

type ResultItem = Resource | Bin | Clip;

@Component({
    selector: 'mam-clip-bin-folder-preview',
    styleUrl: './folders-preview.component.scss',
    templateUrl: './folders-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinFoldersPreview implements OnInit, OnDestroy {
    @Input() isAdmin: boolean = false;
    @Input() userEmail: string = '';
    @Input() showAllFolders: boolean = false;
    @Input() folder!: Resource;
    @Input() viewMode: DisplayMode = DisplayMode.GRID;

    clips: Clip[] = [];

    userIconUrl = this.authService.getUserIcon() || USER_ICON_PLACEHOLDER;

    isLocationExpanded: boolean = false;
    protected readonly JSON = JSON;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private authService: AuthService,
        private readonly resourceService: ResourceService,
        private cdr: ChangeDetectorRef,
        private assetService: AssetService,
        public selectionService: SelectionService
    ) {}

    ngOnInit(): void {
        this.getNestedAssets();
        this.selectionService.registerRow(this.folder);
    }

    async getNestedAssets() {
        if (this.folder.id && this.folder.directChildrenCount && this.folder.directChildrenCount > 0) {
            this.resourceService.getThumbnailsAssets(this.folder.id, 3).subscribe({
                next: (clips) => {
                    clips.forEach((clip) => {
                        if (clip.name) {
                            this.assetService.getClip(clip.name).subscribe((clipResult) => {
                                if (clipResult instanceof ErrorResponse) {
                                    return;
                                }

                                this.clips.push(clipResult);
                                this.cdr.markForCheck();
                            });
                        }
                    });
                }
            });
        }
    }

    trackClip(index: number, clip: Clip) {
        return clip.name;
    }

    openRenameFolder() {
        this.dialog.open(ClipBinFolderUpdateDialog, {
            ...ClipBinFolderUpdateDialog.dialogOptions,
            panelClass: 'mam-dialog--small',
            data: this.folder
        });
    }

    openDeleteFolder() {
        this.dialog.open(ClipBinFolderDeleteDialog, {
            ...ClipBinFolderDeleteDialog.dialogOptions,
            panelClass: 'mam-dialog--small',
            data: this.folder
        });
    }

    openMoveFolder() {
        this.dialog.open(ClipBinFolderMoveDialog, {
            ...ClipBinFolderMoveDialog.dialogOptions,
            panelClass: 'mam-dialog--small',
            data: this.folder
        });
    }

    navigateToFolder(folderId: string | undefined) {
        if (folderId) {
            this.router.navigate(['/folders', folderId], { queryParamsHandling: 'merge' }).then(() => {
                this.cdr.markForCheck();
                return true;
            });
        }
    }

    navigateToParentFolder(folderId: string | undefined) {
        if (folderId) {
            this.resourceService
                .getParentResource(ResourceTypes.FOLDER, folderId)
                .pipe(
                    tap((parent) => {
                        if (parent) {
                            this.navigateToFolder(parent.id);
                        }
                    })
                )
                .subscribe();
        }
    }

    getAssetCount(folder: Resource): number {
        if (this.isLocationExpanded) return -1;
        return folder.directChildrenCount || 0;
    }

    onIsExpandedChange(isExpanded: boolean) {
        this.isLocationExpanded = isExpanded;
    }

    shouldShow3DotMenu() {
        return this.shouldShowNavigateToParentButton() || this.shouldShowOtherButtons();
    }

    shouldShowOtherButtons() {
        return this.isAdmin || !this.showAllFolders;
    }

    shouldShowNavigateToParentButton() {
        return this.folder && this.folder.level !== undefined && this.folder.level > 0;
    }

    shouldShowMoveButton() {
        if(this.showAllFolders)
            return false;

        return !this.showAllFolders && this.folder.owner === this.authService.getUserEmail();
    }
    ngOnDestroy(): void {
      const table:ResultItem[] = [];
      table.push(this.folder);
      this.selectionService.unregisterRow(table);
    }
}
