import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '../auth/auth_service';
import { FeatureFlagService } from '../feature_flag/feature_flag_service';
import {
  AnalyticsEventType,
  FirebaseAnalyticsService,
} from '../firebase/firebase_analytics_service';
import { Asset, AssetService, Original } from '../services/asset_service';
import { Bin, BinService, BinWithClips, ListWithAssetsResponse } from '../services/bin.service';
import { MediaCacheService } from '../services/media_cache_service';
import { SearchInputService } from '../services/search_input_service';
import { HomeView, StateService } from '../services/state_service';
import { ScrubbingService } from '../shared/scrubbing_service';

/**
 * Default view of the home page
 */
@Component({
    selector: 'mam-landing',
    templateUrl: './landing.ng.html',
    styleUrls: ['./landing.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Landing implements OnInit, OnDestroy {
    @ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;
    readonly HomeView = HomeView;

    /** Assets, the three most recently accessed clips */
    recentAssets: Original[] = [];
    cachedBins: Bin[] = [];

    /* Loading */
    isAssetsLoading = true;
    assetsLoading = Array.from({ length: 3 });

    isCboAvailable = true;
    bins$ = new ReplaySubject<BinWithClips[]>(1);
    hasClipBinsLoaded$ = new BehaviorSubject<boolean>(false);
    private readonly destroyed$ = new ReplaySubject<void>(1);
    // Pagination related
    private readonly binsNextPageChange$ = new BehaviorSubject<void>(undefined);
    private binsNextPageToken?: string;
    private isBinsLoading = false;
    private listenToBinsNextPageChange = true;

    constructor(
        private readonly assetService: AssetService,
        private readonly cdr: ChangeDetectorRef,
        private readonly ngZone: NgZone,
        readonly searchInputService: SearchInputService,
        private analyticsService: FirebaseAnalyticsService,
        readonly stateService: StateService,
        readonly mediaCache: MediaCacheService,
        readonly route: ActivatedRoute,
        readonly featureService: FeatureFlagService,
        readonly scrubbingService: ScrubbingService,
        readonly binService: BinService,
        readonly authService: AuthService
    ) {
        this.analyticsService.logEvent('Visited landing', {
            eventType: AnalyticsEventType.NAVIGATION,
            path: '/',
            string2: '/'
        });

        if (this.featureService.featureOff('enable-clip-bin-organization')) {
            // Resets pagination when a bin is created
            this.binService.binsUpdated$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                this.resetBinsPagination();
                this.scrollToTop();
            });
        }
    }

    ngOnInit() {
        this.stateService.currentSelectedResource$.next(undefined);
        this.isCboAvailable = this.featureService.featureOn('enable-clip-bin-organization');
        // Observe periodic updates outside NgZone,
        // so that e2e tests are not stuck waiting for this observable to complete.
        this.ngZone.runOutsideAngular(() => {
            const request = this.featureService.featureOn('enable-access-management')
                ? this.assetService.watchRecentsPagination()
                : this.assetService.watchRecents();

            request.pipe(takeUntil(this.destroyed$)).subscribe((assets) => {
                this.ngZone.run(() => {
                    this.isAssetsLoading = false;
                    if (!assets) return;

                    this.recentAssets = assets;

                    // Save recent assets for navigating between them when we open the details page.
                    this.assetService.cachedRecents = assets;
                    this.stateService.isRecentVideosLoaded$.next(true);
                    this.cdr.detectChanges();
                });
            });
        });

        if (this.featureService.featureOff('enable-clip-bin-organization')) {
            combineLatest([this.binService.binsUpdated$, this.binsNextPageChange$])
                .pipe(
                    takeUntil(this.destroyed$),
                    tap(() => {
                        this.isBinsLoading = true;
                    }),
                    switchMap(() => this.binService.listWithAssets(this.binsNextPageToken))
                )
                .subscribe((response: ListWithAssetsResponse | null) => {
                    this.cdr.markForCheck();

                    if (!response) {
                        this.isBinsLoading = false;
                        return;
                    }

                    this.isBinsLoading = false;

                    const { binsWithAssets, nextPageToken } = response;

                    if (!nextPageToken) {
                        this.listenToBinsNextPageChange = false;
                    }

                    this.hasClipBinsLoaded$.next(true);

                    this.cachedBins.push(...binsWithAssets);
                    this.bins$.next(this.cachedBins);
                    this.binsNextPageToken = nextPageToken;
                });
        }

        this.route.url.pipe(takeUntil(this.destroyed$)).subscribe((_) => {
            if (!this.featureService.featureOn('enable-clip-bin-organization')) {
                // Don't go inside a folder if cbo is disabled
                return;
            }
        });
    }

    onNearBottomForBinsArea() {
        // Only get triggered when the API finishes,
        // or it is not the last page result of the API results
        if (!this.isBinsLoading && this.listenToBinsNextPageChange) {
            this.binsNextPageChange$.next();
        }
    }

    onScroll(evt: Event) {
        this.stateService.scrollHappened$.next(evt);
    }

    trackName(index: number, value: BinWithClips | Asset) {
        return value.name;
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private resetBinsPagination() {
        this.cachedBins = [];
        this.binsNextPageToken = undefined;
        this.listenToBinsNextPageChange = true;
    }

    private scrollToTop() {
        if (this.scrollableView) {
            this.scrollableView.nativeElement.scrollTop = 0;
        }
    }
}
