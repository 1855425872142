import { Pipe, PipeTransform } from '@angular/core';

import { ResourceBin } from '../landing/clip-bin-preview/clip-bin-preview';
import { Resource } from '../landing/clip-bin-section/service/resource.service';
import { Bin, BinWithClips } from '../services/bin.service';

@Pipe({
  name: 'assetCount',
  pure: true,
})
export class AssetCountPipe implements PipeTransform {
  transform(binValue: BinWithClips | Bin | Resource | ResourceBin, isLocationExpanded?: boolean): number {
    if (isLocationExpanded) return -1;

    if ('iasData' in binValue) {
      return binValue.iasData.assetCount || 0;
    } else if ('assetCount' in binValue) {
      return Number.parseInt(String(binValue.assetCount), 10) || 0;
    } else {
      throw new Error('Invalid binValue type');
    }
  }
}
