<div class="actions-search-bar-container">
  <div class="search-bar-container">
    <mat-form-field appearance="outline" class="search-bar" subscriptSizing="dynamic">
      <mat-icon matPrefix>search</mat-icon>
      <input
        type="text"
        matInput
        placeholder="Search transfers"
        aria-label="Search transfers"
        autocomplete="off"
        [formControl]="search"
      />
      <button
        *ngIf="search.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        mam-firebase-ga="Clear transfer search query"
        (click)="search.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-paginator
      [length]="totalSize"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      (page)="goToPage($event)"
      hidePageSize
      class="main__mat-paginator--border"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

@if (showListView) {
  <!-- ListViews table - feature flag ON -->
  <mam-ui-table
  [cols]="cols"
  [data]="rows"
  [options]="{
    tableInfo: {
      name: 'transferTable',
      id: tableId
    },
    multi: true,
    mainColIdx: 1,
    widthGap: 10
  }"
  tableType="transfer"
  [expandedRows]="expandedRows"
  [activeSort]="activeSort"
  (sortClick)="onSort($event)"
  (rowClick)="onRowClick($event)"
  emptyMessage="No transfer found"
>
  <ng-template #headerTpl let-col>
    <ng-container *ngIf="col.headerTpl === 'userTpl'">
      <mam-sort-col-btn
        [rows]="rows"
        [key]="'user'"
        [activeKey]="activeSort.active"
        colName="Source"
        (mamSortByField)="onSortByField($event, 'user')"
      >
        Source
      </mam-sort-col-btn>
    </ng-container>

    <ng-container *ngIf="col.headerTpl === 'intelligenceTpl'">
      <mam-sort-col-btn
        [rows]="rows"
        [key]="'intelligence'"
        [activeKey]="activeSort.active"
        colName="Intelligence"
        (mamSortByField)="onSortByField($event, 'intelligence')"
      >
        Intelligence
      </mam-sort-col-btn>
    </ng-container>

    <ng-container *ngIf="col.headerTpl === 'typeTpl'">
      <mam-multiselect-table-header
        headerName="Type"
        [options]="typeFilterOptions"
        [disableMenu]="(transferService.processing$ | async) || false"
        (toggle)="onTypeFilterChanged($event)"
      >
      </mam-multiselect-table-header>
    </ng-container>

    <ng-container *ngIf="col.headerTpl === 'statusTpl'">
      <mam-multiselect-table-header
        headerName="Status"
        [options]="statusFilterOptions"
        [disableMenu]="(transferService.processing$ | async) || false"
        (toggle)="onStatusFilterChanged($event)"
      >
      </mam-multiselect-table-header>
    </ng-container>
  </ng-template>

  <ng-template #cellTpl let-row let-col="col">
    <!-- Icon -->
    <ng-container *ngIf="col.cellTpl === 'iconTpl'">
      <div class="table-icon">
        <mat-icon *ngIf="!isRowError(row)">insert_drive_file</mat-icon>
        <mat-icon
          matTooltip="Transfer failure"
          matTooltipClass="tooltip"
          *ngIf="isRowError(row)"
          class="error"
        >
          error
        </mat-icon>
      </div>
    </ng-container>

    <!-- Name -->
    <ng-container *ngIf="col.cellTpl === 'nameTpl'">
      <div class="main-column">
        <div class="icon-with-text">
          <span [matTooltip]="col.name+': '+(row.name?.split('/')?.pop() | lowercase)" matTooltipClass="tooltip">
            {{row.name?.split('/')?.pop() | lowercase}}
          </span>
        </div>
      </div>
    </ng-container>

    <!-- Type -->
    <ng-container *ngIf="col.cellTpl === 'typeTpl'">
      {{isUploadTask(row) ? 'Upload' : 'Download'}}
    </ng-container>

    <!-- Intelligence -->
    <ng-container *ngIf="col.cellTpl === 'intelligenceTpl'">
      {{row.isMlEnabled ? 'Yes' : 'No'}}
    </ng-container>

    <!-- Status -->
    <ng-container *ngIf="col.cellTpl === 'statusTpl'">
      <ng-container [ngSwitch]="row.status">
        <ng-container *ngSwitchCase="TaskStatus.ACTIVE">
          <div class="icon-with-text status-column-icon">
            <mat-icon class="status-active-icon" matTooltip="Active" matTooltipClass="tooltip"> pending </mat-icon>
            <span class="status-active-text">Active</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="TaskStatus.FAILED">
          <button
            mat-flat-button
            [disabled]="isRowRetried(row) || (transferService.processing$ | async)"
            (click)="$event.stopPropagation(); onRetryClicked(row)"
            mam-firebase-ga="Retry transfer task"
            [mam-firebase-resource]="row.id"
            [ngClass]="'asset-table-button'"
          >
            Retry
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TaskStatus.COMPLETED">
          <div class="icon-with-text status-column-icon">
            <mat-icon class="status-completed-icon" matTooltip="Completed" matTooltipClass="tooltip">
              check_circle
            </mat-icon>
            <span class="status-completed-text">Completed</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Expand -->
    <ng-container *ngIf="col.cellTpl === 'expandTpl'">
      <button
        mat-icon-button
        mam-firebase-ga="{{isRowExpanded(row) ? 'Expand' : 'Collapse'}} transfer table row"
        [mam-firebase-resource]="row.id"
        [attr.aria-label]="isRowExpanded(row) ? 'Collapse row' : 'Expand row'"
      >
        <mat-icon> {{isRowExpanded(row) ? 'expand_less' : 'expand_more'}} </mat-icon>
      </button>
    </ng-container>
  </ng-template>

  <ng-template #multiTpl let-row>
    <mam-transfer-details [task]="row.rawTask"> </mam-transfer-details>
  </ng-template>
</mam-ui-table>
}
@else {
  <!-- Standard table - feature flag OFF -->
  <table
    class="legacy-table"
    mat-table
    matSort
    multiTemplateDataRows
    [dataSource]="rows"
    [trackBy]="trackByRow"
    [matSortActive]="activeSort.active"
    [matSortDirection]="activeSort.direction"
    [matSortDisableClear]="true"
    (matSortChange)="sort.emit($event)"
    [mamTable]="displayedColumns()"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="transferService.processing$ | async"
          mam-firebase-ga="Sort transfer tasks by name"
          mamCol
          draggable="false"
          (mamResetCols)="onResetCols()"
          >
            Name
      </th>
      <td
          class="main-column"
          mat-cell
          *matCellDef="let row">
        <div class="icon-with-text">
          <mat-icon *ngIf="!isRowError(row)">insert_drive_file</mat-icon>
          <mat-icon
              *ngIf="isRowError(row)"
              class="error"
              matTooltip="Transfer failure"
              matTooltipClass="close-from-top">
              error
          </mat-icon>
          <span
              [title]="row.name"
              matTooltip="{{row.name}}"
              matTooltipPosition="below"
              matTooltipClass="mobile-tooltip">
              {{row.name}}
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Size Column -->
    <ng-container matColumnDef="size">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="transferService.processing$ | async"
        mam-firebase-ga="Sort transfer tasks by size"
        mamCol="100px"
        draggable="true"
        (mamResetCols)="onResetCols()"
      >
        Size
      </th>
      <td
          mat-cell
          *matCellDef="let row"
          [title]="(row.size | number) + ' bytes'">
          {{row.size | bytes}}
      </td>
  </ng-container>

  <!-- User Column -->
  <ng-container matColumnDef="source">
    <th
      mat-header-cell
      *matHeaderCellDef
      mamCol="100px"
      [mamMinWidth]="100"
      draggable="true"
      (mamResetCols)="onResetCols()">
      <mam-sort-col-btn
        [rows]="rows"
        [key]="'user'"
        colName="Source"
        (mamSortByField)="onSortByField($event, 'user')">
        Source
      </mam-sort-col-btn>
    </th>
    <td
      mat-cell
      *matCellDef="let row"
      [title]="row.user">
      {{row.user}}
    </td>
  </ng-container>

    <!-- Last Modified Column -->
    <ng-container matColumnDef="modifiedTime">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="transferService.processing$ | async"
        mam-firebase-ga="Sort transfer tasks by Last Modified"
        mamCol="100px"
        [mamMinWidth]="100"
        draggable="true"
        (mamResetCols)="onResetCols()"
        >
          Last Modified
      </th>
      <td
          mat-cell
          *matCellDef="let row"
          [title]="row.modifiedTime | tzdate:'long'">
        <span
            matTooltip="{{row.modifiedTime | tzdate:'MMM d, y, h:mm a'}}"
            matTooltipPosition="below"
            matTooltipClass="mobile-tooltip">
            {{row.modifiedTime | tzdate:'MMM d, y, h:mm a'}}
        </span>
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th
        mat-header-cell
        *matHeaderCellDef
        mamCol="100px"
        draggable="true"
        (mamResetCols)="onResetCols()"
      >
          <mam-multiselect-table-header
              headerName="Type"
              [options]="typeFilterOptions"
              [disableMenu]="(transferService.processing$ | async) || false"
              (toggle)="onTypeFilterChanged($event)">
          </mam-multiselect-table-header>
      </th>
      <td mat-cell *matCellDef="let row">
        {{isUploadTask(row) ? 'Upload' : 'Download'}}
      </td>
    </ng-container>

    <!-- Intelligence Column -->
    <ng-container matColumnDef="intelligence">
      <th
        mat-header-cell
        *matHeaderCellDef
        mamCol="100px"
        [mamMinWidth]="100"
        draggable="true"
        (mamResetCols)="onResetCols()"
      >
          Intelligence
      </th>
      <td mat-cell *matCellDef="let row">{{row.isMlEnabled ? 'Yes' : 'No'}}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        mamCol="100px"
        [mamMaxWidth]="40"
        draggable="true"
        (mamResetCols)="onResetCols()"
        class="status-table-header">
          <mam-multiselect-table-header
              headerName="Status"
              [options]="statusFilterOptions"
              [disableMenu]="(transferService.processing$ | async) || false"
              (toggle)="onStatusFilterChanged($event)">
          </mam-multiselect-table-header>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container [ngSwitch]="row.status">
          <ng-container *ngSwitchCase="TaskStatus.ACTIVE">
            <div class="icon-with-text status-column-icon">
              <mat-icon
                class="status-active-icon"
                matTooltip="Active"
                matTooltipPosition="below">
                pending
              </mat-icon>
              <span class="status-active-text">Active</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="TaskStatus.FAILED">
            <button
                mat-flat-button
                [disabled]="isRowRetried(row) || (transferService.processing$ | async)"
                (click)="$event.stopPropagation(); onRetryClicked(row)"
                mam-firebase-ga="Retry transfer task"
                [mam-firebase-resource]="row.id"
                [ngClass]="'asset-table-button'">
                Retry
            </button>
          </ng-container>
          <ng-container *ngSwitchCase="TaskStatus.COMPLETED">
            <div class="icon-with-text status-column-icon">
              <mat-icon
                class="status-completed-icon"
                matTooltip="Completed"
                matTooltipPosition="below">
                check_circle
              </mat-icon>
              <span class="status-completed-text">Completed</span>
            </div>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <!-- Expand Column -->
    <ng-container matColumnDef="expand">
      <th
        mat-header-cell
        *matHeaderCellDef
        mamCol="40px"
        draggable="false"
        (mamResetCols)="onResetCols()"
        class="expand-table-header">
      </th>
      <td mat-cell *matCellDef="let row;">
        <button
            class="main__icon--size"
            mat-icon-button
            mam-firebase-ga="{{isRowExpanded(row) ? 'Expand' : 'Collapse'}} transfer table row"
            [mam-firebase-resource]="row.id"
            [attr.aria-label]="isRowExpanded(row) ? 'Collapse row' : 'Expand row'">
          <mat-icon>
            {{isRowExpanded(row) ? 'expand_less' : 'expand_more'}}
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Details Column (displayed when row is expanded) -->
    <ng-container matColumnDef="expandedDetails">
      <td
          mat-cell
          *matCellDef="let row;"
          [attr.colspan]="displayedColumns().length">
        <mam-transfer-details
            @expandDetails
            *ngIf="isRowExpanded(row)"
            [task]="row.rawTask">
        </mam-transfer-details>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>

    <tr
        mat-row *matRowDef="let row; columns: displayedColumns();"
        [mam-firebase-ga]="isRowExpanded(row) ? 'Hide transfer row details' : 'Show transfer row details'"
        (click)="$event.stopPropagation(); toggleRowExpanded(row)"
        [class.error]="isRowError(row)"
        [class.expanded]="isRowExpanded(row)">
    </tr>

    <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetails'];"
        class="details"
        [hidden]="!isRowExpanded(row)">
    </tr>
  </table>

  <div *ngIf="!rows?.length" class="empty-message">
    No transfer found
  </div>
}
