import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterExtensionService {
  private previousUrl: string = '';
  private currentUrl: string = '';

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd | NavigationStart => event instanceof NavigationEnd || event instanceof NavigationStart)
      )
      .subscribe({
        next: (event: NavigationEnd | NavigationStart) => {
          if (event instanceof NavigationEnd) {
            this.previousUrl = this.getCurrentUrl();
            this.currentUrl = event.urlAfterRedirects;
          } else if (event instanceof NavigationStart) {
            this.currentUrl = this.getCurrentUrl();
          }
        }
      });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public getCurrentUrl(): string {
    return this.currentUrl === '' ? this.router.url : this.currentUrl;
  }
}
