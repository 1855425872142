export const ResourceTypes = Object.freeze({
    CLIP: {
        name: 'clip',
        resourcesKey: 'clips',
        apiResourceType: 'clip'
    },
    CLIPBIN: {
        name: 'clip-bin',
        resourcesKey: 'clipBins',
        apiResourceType: 'clipbin'
    },
    FOLDER: {
        name: 'folder',
        resourcesKey: 'folders',
        apiResourceType: 'folder'
    },
    ASSET: {
        name: 'asset',
        resourcesKey: 'assets',
        apiResourceType: 'asset'
    }
} as const);

// Type representing the values of ResourceTypes
export type ResourceType = (typeof ResourceTypes)[keyof typeof ResourceTypes];

// Type representing the 'name' property of ResourceTypes
export type ResourceTypeName = (typeof ResourceTypes)[keyof typeof ResourceTypes]['name'];

/**
 * A TypeScript type definition representing a specific API resource type for a given entry in the `ResourceTypes` object.
 *
 * This type resolves to the `apiResourceType` property of entries in the `ResourceTypes` object.
 * It extracts the valid string values associated with the `apiResourceType` field across all members of the `ResourceTypes` object.
 *
 * This is primarily used in scenarios where strict typing is required for API resource types,
 * ensuring only valid and predefined values from `ResourceTypes` are allowable.
 */
export type ResourceTypeApiName = (typeof ResourceTypes)[keyof typeof ResourceTypes]['apiResourceType'];

export enum OrganizerEnum {
    CLIP_BIN = 'Clip Bin',
    LOCAL_UPLOAD = 'Local Upload',
    CLOUD_INGEST = 'Cloud Ingest',
    FOLDER = 'Folder'
}

export enum OrganizerTypes {
    CLIP_BIN = 'clipbins',
    FOLDER = 'folder',
    LOCAL_UPLOAD = 'localupload',
    CLOUD_INGEST = 'cloudingest',
}

export interface OrganizersActionOptions  {
  name: OrganizerEnum,
  type: OrganizerTypes,
  allow: boolean,
  errorMsg: string,
  order: number,
}
