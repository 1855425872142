import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  combineLatest,
  debounceTime,
  EMPTY,
  finalize,
  map,
  mergeMap,
  of,
  ReplaySubject,
  shareReplay,
  switchMap,
  takeUntil
} from 'rxjs';

import { environment } from '../../environments/environment';
import {isErrorResponse} from '../../error_service/error_response';
import {FeatureFlagService} from '../../feature_flag/feature_flag_service';
import { FirebaseFirestoreDataService } from '../../firebase/firebase_firestore_data_service';
import { Resource, ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import {AssetService, ListResponse, Original} from '../../services/asset_service';
import {DialogService} from '../../services/dialog_service';
import {Pagination, PaginationService} from '../../services/pagination_service';
import {PaginatorIntl} from '../../services/paginator-intl';
import {ProgressbarService} from '../../services/progressbar_service';
import {SnackBarService} from '../../services/snackbar_service';
import { TableUtils } from '../../services/table_utils';
import {TaskUsersService} from '../../services/task_user_service';
import { TransferRow, TransferRowFilterChange, TransferRowSort } from '../../services/transfer_service';
import { TableCol } from '../../ui/ui_table.type';

const ALL_COLUMNS = [
  'title',
  'source',
  'duration',
  'lastModified',
  'undelete',
] as const;

export type Column = typeof ALL_COLUMNS[number];

const LEGACY_COLUMNS = [
  'title',
  'source',
  'lastModified',
  'undelete',
] as const;

/**
 * Asset deletion table component.
 */
@Component({
  selector: 'mam-asset-deletion-table-legacy',
  templateUrl: './asset_deletion_table_legacy.ng.html',
  styleUrls: ['./asset_deletion_table_legacy.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorIntl}],
})
export class AssetDeletionTableLegacy implements OnInit, OnDestroy {
  cols: TableCol[] = [
    {
      key: 'icon',
      name: '',
      headerTpl: 'iconTpl',
      cellTpl: 'iconTpl',
      resizer:false,
      dragger: false,
      stickyStart: true,
      headerStyle: {
        width: 'var(--table-checkbox-width)',
        minWidth: 'var(--table-checkbox-minwidth)',
        maxWidth: 'var(--table-checkbox-width)',
      },
      cellStyle: {
        paddingLeft: '8px',
      },
      order: 0,
      orderMenu: 0
    },
    {
      key: 'title',
      name: 'Title',
      sorter: true,
      optioner: true,
      dragger: false,
      resizer: true,
      disabled: true,
      cellTpl: 'titleTpl',
      headerStyle: {
        width: '180px',
        minWidth: '200px',
      },
      order: 1,
      orderMenu: 1
    },
    {
      key: 'source',
      name: 'Deleted by',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      headerStyle: {
        width: '140px',
        minWidth: '100px',
      },
      order: 2,
      orderMenu: 2
    },
    {
      key: 'duration',
      name: 'Duration',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'durationTpl',
      headerStyle: {
        width: '100px',
        minWidth: '80px',
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'updateTime',
      name: 'Last Modified',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'updateTimeTpl',
      headerStyle: {
        width: '160px',
        minWidth: '90px',
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'undelete',
      name: '',
      sorter: false,
      optioner: true,
      dragger: true,
      resizer: false,
      stickyEnd: true,
      cellTpl: 'undeleteTpl',
      headerStyle: {
        width: '90px',
        minWidth: '90px',
      },
      order: 5,
      orderMenu: 5
    }
  ];

  get rows(): TransferRow[] {
    return this.rowsInternal;
  }
  set rows(rows: TransferRow[]) {
    this.retriedRows.clear();
    this.rowsInternal = rows;
    this.rowsChanged$.next();
  }

  @Output() readonly scrollTopNeeded = new EventEmitter();

  @Output() assetsForm = new EventEmitter<FormControl>();

  @Input() currentDeletedMode?: string;

  @Input() deletedModes?: string[];

  @Output() deletedModeChange = new EventEmitter<string>();

  /** Emits when navigating to a new page is requested. */
  @Output() readonly page = new EventEmitter<PageEvent>();

  /** Emits when sorting is requested. */
  @Output() readonly sort = new EventEmitter<Sort>();

  /** Emits when filter is changed. */
  @Output() readonly filter = new EventEmitter<TransferRowFilterChange>();


  assets: Original[] = [];
  activeSort: TransferRowSort = { active: 'modifiedTime', direction: 'desc' };

  deletedAssets: Resource[] = [];

  /** Record the undeleted asset to dim the matching row. */
  undeletedAssetSet = new Set<string>();

  /** Fixed page size of the asset deletion table. */
  readonly pageSize = 30;

  /** Form control for search input. */
  search = new FormControl<string>('');

  pagination: Pagination<ListResponse<Original>>;

  /** List of columns to render in the table. */
  displayedColumns = signal<Column[]>([]);
  displayedLegacyColumns = LEGACY_COLUMNS;

  /** Flag to show assets' source column. */
  readonly showAssetsSource = this.featureService.featureOn('show-user-information');

  /** Flag to use List views feature. */
  readonly showListView = this.featureService.featureOn('use-table-list-views');

  private rowsInternal: TransferRow[] = [];

  /** List of rows that we clicked "Retry" on, which disable the button. */
  private readonly retriedRows = new Set<string>();

  private readonly destroyed$ = new ReplaySubject<void>(1);

  /** Emits when the `rows` input changes. */
  private readonly rowsChanged$ = new ReplaySubject<void>(1);
  tableId:string;

  constructor(
      readonly progressbar: ProgressbarService,
      private readonly cdr: ChangeDetectorRef,
      private readonly assetService: AssetService,
      private readonly dialogService: DialogService,
      private readonly snackbar: SnackBarService,
      private readonly paginationService: PaginationService,
      private readonly featureService: FeatureFlagService,
      protected readonly taskUserService: TaskUsersService,
      protected readonly resourceService: ResourceService,
      private readonly dataService: FirebaseFirestoreDataService,
      private readonly tableUtils: TableUtils,
  ) {
    this.tableId = environment.tableInfoId['deletedAssetsTable'];

    this.pagination = this.paginationService.getEmptyPagination(this.pageSize);

    this.search.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(300))
        .subscribe(query => {
          // Re-build the cache since the query is changed.
          this.pagination =
              this.paginationService.getEmptyPagination(this.pageSize);

          this.refreshDeletedAssets(query ?? '');
          this.scrollTopNeeded.emit();
        });
  }

  openUndeleteAssetDialog(asset: Original) {
    const confirmed$ = this.dialogService.showConfirmation({
      title: 'Undelete asset',
      question:
          'Are you sure you want to restore this asset?',
      primaryButtonText: 'Undelete',
    });

    confirmed$
        .pipe(
            switchMap(result => {
              if (!result) return EMPTY;
              return this.assetService.undeleteAsset(asset);
            }),
            takeUntil(this.destroyed$))
        .subscribe(response => {
          if (isErrorResponse(response)) {
            this.snackbar.error({
              message: 'Failed to undelete asset.',
              details: response.message,
            });
          } else {
            this.snackbar.message('The asset has been undeleted.');
            this.dataService.deleteDeletedAsset(asset.name);
            this.undeletedAssetSet.add(asset.name);
            this.cdr.markForCheck();
          }
        });
  }

  ngOnInit() {
    // Trigger asset fetching.
    this.refreshDeletedAssets('');
  }

  changePage(event: PageEvent) {
    this.pagination.pageIndex = event.pageIndex;
    this.refreshDeletedAssets(this.search.value ?? '');
    this.scrollTopNeeded.emit();
  }

  handleDeletedModeChange(mode: string) {
    this.deletedModeChange.emit(mode);
    this.cdr.detectChanges();
  }

  trackByAsset(index: number, asset: Original) {
    return asset.name;
  }

  /**
   * Get the deleted assets from the cache or API.
   */
  private refreshDeletedAssets(userQuery: string) {
    if (this.pagination.pageIndex < this.pagination.pagesCache.length) {
      this.assets =
          this.pagination.pagesCache[this.pagination.pageIndex].assets;
      this.cdr.markForCheck();
      return;
    }

    const nextPageToken =
        this.pagination.pagesCache[this.pagination.pageIndex - 1]
            ?.nextPageToken;
    this.progressbar.show();
    this.assetService.getDeletedAssets(userQuery, this.pageSize, nextPageToken)
        .pipe(
          mergeMap(
            originalResponse => {

              if (!this.showAssetsSource) return of (originalResponse);

              const response = (originalResponse as ListResponse<Original>);
              const originalAssets = response.assets;
              const nextPageToken = response.nextPageToken;

              if (!originalAssets || originalAssets.length == 0 ) return of (originalResponse);
              const completedItems = originalAssets.map(
                originalAsset => this.taskUserService
                  .completeDeletedAssetWithUser(originalAsset)
              );

              return combineLatest(completedItems).pipe(
                map(assets => {
                  return {assets,nextPageToken} as ListResponse<Original>;
                })
              );
            }),
          shareReplay({bufferSize: 1, refCount: false})
        )
        .pipe(takeUntil(this.destroyed$), finalize(() => {
                this.progressbar.hide();
              }))
        .subscribe(response => {
          this.progressbar.hide();
          this.cdr.markForCheck();

          if (isErrorResponse(response)) {
            this.snackbar.error({
              message: 'Failed to get deleted assets.',
              details: response.message,
            });
            this.assets = [];
            return;
          }

          this.assets = response.assets;
          this.pagination.pagesCache[this.pagination.pageIndex] = response;

          // This is the last page, calculate the total count.
          if (!response?.nextPageToken) {
            this.pagination.totalCount =
                this.pagination.pageIndex * this.pageSize + this.assets.length;
          }
        });
  }

  onSortByField(rows: Original[]) {
    this.assets = rows;
    this.cdr.detectChanges();
  }

  trackByRow(index: number, row: TransferRow) {
    return row.id;
  }

  onSort(sort: Sort, rows: Original[]) {
    this.assets =  this.tableUtils.sortByField<Original>(rows, sort.active, sort.direction === 'desc');
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
