import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';

import { AuthService } from '../auth/auth_service';
import { environment } from '../environments/environment';
import { ResourceTypes } from '../landing/clip-bin-section/service/resource-types';
import { Resource } from '../landing/clip-bin-section/service/resource.service';
import { BreadcrumbItem } from '../landing/folders-preview/breadcrumb.component';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    private readonly BASE_URL = environment.resourcesApi;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient
    ) {}

    getFolderBreadcrumbs(iasId: string): Observable<BreadcrumbItem[]> {
        return this.httpClient.get<BreadcrumbItem[]>(
            `${this.BASE_URL}/folders/${encodeURIComponent(iasId)}/breadcrumb`,
            {
                headers: this.getAuthHeaders()
            }
        );
    }

    getClipBinBreadcrumbs(iasId: string): Observable<BreadcrumbItem[]> {
        return this.getClipBinParentResource(iasId).pipe(
                switchMap((resource) => {
                    const resourceId = resource?.id;
                    if (resourceId) {
                        return this.httpClient
                            .get<BreadcrumbItem[]>(
                                `${this.BASE_URL}/folders/${encodeURIComponent(resourceId)}/breadcrumb`,
                                {
                                    headers: this.getAuthHeaders()
                                }
                            )
                            .pipe(
                                map((breadcrumbs) => [
                                    ...breadcrumbs,
                                    {
                                        id: resourceId, // No need for non-null assertion
                                        name: resource.name
                                    }
                                ])
                            );
                    }

                    throw new Error(
                        'Cannot get the breadcrumb because the parent resource is undefined or does not have an ID'
                    );
                })
            );
    }

    getClipBinParentResource(iasId: string) {
        return this.httpClient.get<Resource>(
            `${this.BASE_URL}/${ResourceTypes.CLIPBIN.name}s/${encodeURIComponent(iasId)}/parent`,
            {
                headers: this.getAuthHeaders()
            }
        );
    }

    private getAuthHeaders(): HttpHeaders {
        const accessToken = this.authService.getAccessToken();
        return new HttpHeaders({
            Authorization: `Bearer ${accessToken}`
        });
    }
}
