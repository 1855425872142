// import { BreakpointObserver } from '@angular/cdk/layout';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {debounceTime, finalize, first, map, shareReplay, startWith, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {assertTruthy, checkExhaustive} from 'asserts/asserts';
import { environment } from 'environments/environment';
import {FeatureFlagService} from 'feature_flag/feature_flag_service';
import {TableCol} from 'ui/ui_table.type';

import {ErrorResponse, isErrorResponse} from '../error_service/error_response';
import {ClipOperationStatus, ExportType} from '../services/asset_service';
import {MediaCacheService} from '../services/media_cache_service';
import {PaginatorIntl} from '../services/paginator-intl';
import {SnackBarService} from '../services/snackbar_service';
import { TableUtils } from '../services/table_utils';
import {TaskStatus, TransferRow, TransferRowFilter, TransferRowFilterChange, TransferRowSort, TransferService, TransferType} from '../services/transfer_service';
import {UtilsService} from '../services/utils_service';

import {ExportMonitorCompReelService} from './export_monitor_comp_reel_service';
import {ExportMonitorLiveService} from './export_monitor_live_service';
import {
  EXPORT_COLUMNS,
  EXPORT_LEGACY_COLUMNS,
  ExportItemResponse,
  ExportMonitorService
} from './export_monitor_service';
import {ExportMonitorVodService} from './export_monitor_vod_service';
import { MultiSelectOption, MultiSelectOptions } from './multiselect_table_header';
import { PAGE_SIZE } from './transfer_monitor';


/** Media breakpoints affecting the visibility of columns. */
// export enum BreakPoint {
//   COMPACT = '(max-width: 1280px)',
//   HIDE_INTELLIGENCE = '(max-width: 960px)',
//   HIDE_SIZE_AND_MODIFIED = '(max-width: 720px)',
// }

const ALL_COLUMNS = [
  'icon',
  'title',
  'fileName',
  'updateTime',
  'exportFolder',
  'user',
  'clipBinName',
  'duration',
  'status'
] as const;

/**
 * String literals union of all the possible column names.
 * - Refer to cl/344914657 to add a "select" (checkboxes) column.
 * - Refer to cl/345791367 to add an "origin" column.
 */
export type Column = typeof ALL_COLUMNS[number];

//const ALWAYS_VISIBLE_COLUMNS: Column[] = ['title', 'updateTime', 'status'];

const SEARCH_DEBOUNCE = 900;


/**
 * Shared Export Monitor component.
 */
@Component({
  selector: 'mam-export-monitor',
  templateUrl: './export_monitor.ng.html',
  styleUrls: ['./export_monitor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorIntl}],
})
export class ExportMonitor implements OnInit, OnDestroy, OnChanges {

  cols: TableCol[] = [
    {
      key: 'icon',
      name: '',
      headerTpl: 'iconTpl',
      cellTpl: 'iconTpl',
      resizer:false,
      dragger: false,
      stickyStart: true,
      headerStyle: {
        width: 'var(--table-checkbox-width)',
        minWidth: 'var(--table-checkbox-minwidth)',
        maxWidth: 'var(--table-checkbox-width)',
      },
      cellStyle: {
        paddingLeft: '8px',
      },
      order: 0,
      orderMenu: 0
    },
    {
      key: 'title',
      name: 'Title',
      sorter: true,
      optioner: true,
      dragger: false,
      resizer: true,
      disabled: true,
      cellTpl: 'titleTpl',
      headerStyle: {
        width: '100px',
        minWidth: '200px',
      },
      order: 1,
      orderMenu: 1
    },
    {
      key: 'fileName',
      name: 'File Name',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      headerStyle: {
        width: '140px',
        minWidth: '140px'
      },
      order: 2,
      orderMenu: 2
    },
    {
      key: 'exportFolder',
      name: 'Export Folder',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      headerStyle: {
        width: '152px',
        minWidth: '100px',
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'clipBinName',
      name: 'Clip Bin',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'clipBinTpl',
      headerStyle: {
        width: '124px',
        minWidth: '90px',
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'user',
      name: 'Modified by',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      pipe: 'titlecase',
      headerStyle: {
        width: '140px',
        minWidth: '100px',
      },
      order: 5,
      orderMenu: 5
    },
    {
      key: 'duration',
      name: 'Duration',
      sorter: true,
      optioner: false,
      dragger: true,
      resizer: true,
      cellTpl: 'durationTpl',
      headerStyle: {
        width: '100px',
        minWidth: '80px',
      },
      order: 6,
      orderMenu: 6
    },
    {
      key: 'updateTime',
      name: 'Update Time',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      pipe: 'tzdate',
      pipeArg: 'MMM d, y, h:mm a',
      headerStyle: {
        width: '150px',
        minWidth: '80px'
      },
      order: 7,
      orderMenu: 7
    },
    {
      key: 'status',
      name: 'PFR Status',
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'statusTpl',
      headerStyle: {
        width: '118px',
        minWidth: '70px',
      },
      order: 8,
      orderMenu: 8
    }
  ];

  get rows(): TransferRow[] {
    return this.rowsInternal;
  }
  set rows(rows: TransferRow[]) {
    this.retriedRows.clear();
    this.rowsInternal = rows;
    this.rowsChanged$.next();
  }
  activeSort: TransferRowSort = {active: 'modifiedTime', direction: 'desc'};

  get activeFilter(): TransferRowFilter {
    return this.filterInternal;
  }
  set activeFilter(filter: TransferRowFilter) {
    this.filterInternal = filter;
    this.typeFilterOptions = this.typeFilterOptions.map(opt => {
      opt.selected = opt.value === filter.type;
      return opt;
    });
    this.statusFilterOptions = this.statusFilterOptions.map(opt => {
      opt.selected = opt.value === filter.status;
      return opt;
    });
  }

  /** Options for status filter. */
  statusFilterOptions: MultiSelectOptions<TaskStatus> = [
    {title: 'Any Status', selected: true},
    {title: 'Active', value: TaskStatus.ACTIVE},
    {title: 'Failed', value: TaskStatus.FAILED},
    {title: 'Completed', value: TaskStatus.COMPLETED},
  ];

  // Determine what type of data is being displayed
  @Input() exportType!: ExportType;

  @Input() displayDuration: boolean = true;

  @Output() readonly scrollTopNeeded = new EventEmitter();

  /** Emits when navigating to a new page is requested. */
  @Output() readonly page = new EventEmitter<PageEvent>();

  /** Emits when clicking on a failed task's "Retry" button. */
  @Output() readonly retry = new EventEmitter<string>();

  /** Emits when sorting is requested. */
  @Output() readonly sort = new EventEmitter<Sort>();

  /** Emits when filter is changed. */
  @Output() readonly filter = new EventEmitter<TransferRowFilterChange>();

  //displayedColumns = EXPORT_COLUMNS;
  /** List of columns to render in the table. */
  displayedColumns = signal<Column[]>([]);
  displayedLegacyColumns = EXPORT_LEGACY_COLUMNS;

  /** Form control for search input. */
  search = new FormControl<string>('');

  /** Expose status enumeration to template. */
  readonly TaskStatus = TaskStatus;

  /** Options for type filter. */
  typeFilterOptions: MultiSelectOptions<TransferType> = [
    {title: 'Any type', selected: true},
    {title: 'Download', value: TransferType.DOWNLOAD},
    {title: 'Upload', value: TransferType.UPLOAD}
  ];

  readonly selectableSites$ = this.mediaCache.state.selectableSites$;
  exportService!: ExportMonitorService;
  /** Used for pagination of the transfers table. */
  readonly currentPage$ = new BehaviorSubject({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });
  currentSort: TransferRowSort = {active: 'modifiedTime', direction: 'desc'};
  currentFilter: TransferRowFilter = {};

  /** Page of rows and total size from current search. */
  readonly searchResponse$ = this.getSearchResponse();

  tableId!:string;

  emptyMessage: string = 'No data found.';

  /** Flag to use List views feature. */
  readonly showListView = this.featureService.featureOn('use-table-list-views');
  tableIdName!: string;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly exportMonitorCompReelService: ExportMonitorCompReelService,
    private readonly exportMonitorLiveService: ExportMonitorLiveService,
    private readonly exportMonitorVodService: ExportMonitorVodService,
    private readonly mediaCache: MediaCacheService,
    private readonly snackbar: SnackBarService,
    readonly transferService: TransferService,
    private readonly featureService: FeatureFlagService,
    readonly utils: UtilsService,
    private readonly tableUtils: TableUtils,
    // private readonly breakpoint: BreakpointObserver,
  ) {
    this.search.valueChanges
        .pipe(
            debounceTime(SEARCH_DEBOUNCE),
            startWith(''),
            takeUntil(this.destroyed$),
            )
        .subscribe(value => {
          this.filter.emit({type: 'name', value} as TransferRowFilterChange);
        });

    if (this.featureService.featureOff('show-user-information')) {
      const displayedColumns:Column[] = EXPORT_COLUMNS.filter(column => column !== 'clipBin' && column !== 'user') as Column[];
      this.displayedColumns.set(displayedColumns);
    }
   }
  ngOnChanges(changes: SimpleChanges): void {

    if(changes['exportType'].currentValue) {
      this.tableIdName = 'exportMonitorTable'+this.exportType;
      this.tableId = environment.tableInfoId[this.tableIdName];
    }
  }

  ngOnInit() {
    assertTruthy(
        this.exportType, `mam-export-monitor: 'exportType' is required.`);

    switch (this.exportType) {
      case 'VoD':
        this.exportService = this.exportMonitorVodService;
        break;
      case 'Live':
        this.exportService = this.exportMonitorLiveService;
        break;
      case 'CompReel':
        this.exportService = this.exportMonitorCompReelService;
        break;
      default:
        checkExhaustive(this.exportType);
    }

    this.exportService.items$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.scrollTopNeeded.emit();
    });

    this.emptyMessage = `No ${this.exportType} exports found.`;
    this.exportService.loadItems().pipe(takeUntil(this.destroyed$)).subscribe();
    this.cdr.markForCheck();
  }

  isRowError(status: ClipOperationStatus) {
    return status === 'Failed';
  }

  isRowExpanded(row: TransferRow) {
    return this.expandedRows.has(row.id);
  }

  isUploadTask(row: TransferRow) {
    return row.type === TransferType.UPLOAD;
  }

  trackByItem(index: number, item: ExportItemResponse) {
    return item.fileName;
  }

  isRowRetried(row: ExportItemResponse) {
    return this.retriedRows.has(row.fileName);
  }

  onRetryClicked(row: TransferRow) {
    // Prevent to click again on "Retry" until input data is updated.
    this.retriedRows.add(row.id);
    this.retry.emit(row.id);
  }

  retryOnClick(row: ExportItemResponse) {
    this.retriedRows.add(row.fileName);

    combineLatest([
      this.transferService.siteAndExportFolders$,
      this.mediaCache.state.scratchFolder$
    ])
      .pipe(
          take(1),
          switchMap(([{site, folders}, scratchFolder]) => {
            const exportFolder =
                folders.find(folder => folder.name === row.folderPath);

            if (!exportFolder) {
              return of(new ErrorResponse('Failed to find the folder'));
            }
            return this.exportService.retryExport(
                site.siteId, exportFolder, row.name, scratchFolder?.name);
          }),
          )
      .subscribe(response => {
        if (isErrorResponse(response)) {
          this.snackbar.error({
            message: `Failed to export ${row.title} to ${row.folderPath}.`,
            details: response.message,
            doNotLog: true,
          });
          return;
        }
        this.snackbar.message(`Exporting ${row.title}.`);
      });
  }

  onSortByField(rows: ExportItemResponse[]) {
    this.exportService.items.next(rows);

  }

  onResetCols() {
    //this.startResponsiveLayout();
  }

  toggleRowExpanded(row: TransferRow) {
    if (this.expandedRows.has(row.id)) {
      this.expandedRows.delete(row.id);
    } else {
      this.expandedRows.add(row.id);
    }
  }

  onTypeFilterChanged(selected: MultiSelectOption) {
    this.filter.emit({type: 'type', value: selected.value as TransferType});
  }

  onStatusFilterChanged(selected: MultiSelectOption) {
    this.filter.emit({type: 'status', value: selected.value as TaskStatus});
  }

  goToPage(event: PageEvent) {
    // Rows open on the current page should be collapsed when we come back to
    // this page.
    this.expandedRows.clear();
    this.page.emit(event);
  }

  trackByRow(index: number, row: TransferRow) {
    return row.id;
  }

  onSort(sort: Sort, rows: ExportItemResponse[]) {
    const sortedItems = this.tableUtils.sortByField<ExportItemResponse>(rows, sort.active, sort.direction === 'desc');
    this.exportService.items.next(sortedItems);
  }

  onRowClick(row: TransferRow) {
    this.toggleRowExpanded(row);
  }

  /**
   * Observe changes to the selected site and the current page, and emits the
   * list of tasks for the current page, as well as their total size.
   */
  private getSearchResponse():
      Observable<{rows: TransferRow[], totalSize: number}> {
    return this.currentPage$.pipe(
        tap(() => {
          this.transferService.processing$.next(true);
        }),
        switchMap(page => {
          return this.transferService.transferSelectedSite$.pipe(
              first(),
              map(site => ({page, site})),
          );
        }),
        switchMap(({page, site}) => {
          return this.transferService.searchTasks(
              site.siteId,
              page.pageIndex,
              page.pageSize,
              this.currentFilter,
              this.currentSort,
          );
        }),
        map(response => {
          if (!response) {
            this.snackbar.error('Failed to get transfer tasks');
            response = {rows: [], totalSize: 0};
          }
          return response || {rows: [], totalSize: 0};
        }),
        tap(() => {
          this.transferService.processing$.next(false);
        }),
        finalize(() => {
          this.transferService.processing$.next(false);
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  ngOnDestroy() {
    // Clean up the service data for the next component.
    this.exportService.reset();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private rowsInternal: TransferRow[] = [];

  /** List of rows that we clicked "Retry" on, which disable the button. */
  private readonly retriedRows = new Set<string>();

  /** List of rows that have been expanded to reveal their details. */
  readonly expandedRows = new Set<string>();

  private readonly destroyed$ = new ReplaySubject<void>(1);

  private filterInternal: TransferRowFilter = {};

  /** Emits when the `rows` input changes. */
  private readonly rowsChanged$ = new ReplaySubject<void>(1);
}
