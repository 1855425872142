@if (dataSource$ | async; as dataSource) {
<table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="name-header-cell">
            <div class="text-ellipsis actions-wrapper">
                <div class="buttons-wrapper">
                    @if (selectionService.currentSelection.size > 0) {
                        <button
                            mat-icon-button
                            (click)="handleBulkDelete(this.selectionService.currentSelection)"
                            class="bulk-actions__button main__icon--size"
                            aria-label="Delete selected assets"
                            matTooltip="Delete selected assets"
                            matTooltipPosition="above"
                            mam-firebase-ga="Delete selected assets"
                        >
                            <mat-icon class="material-icons-outlined">delete_outline</mat-icon>
                        </button>
                        @if(!isShowAll) {
                            <button
                            mat-icon-button
                            (click)="handleBulkMove(this.selectionService.currentSelection)"
                            class="bulk-actions__button main__icon--size"
                            aria-label="Move selected assets"
                            matTooltip="Move selected assets"
                            matTooltipPosition="above"
                            mam-firebase-ga="Move selected assets"
                        >
                            <mat-icon class="material-icons-outlined">drive_file_move_outline</mat-icon>
                        </button>
                        }
                    }
                    <div class="border">&nbsp;</div>
                </div>
                <p>Name</p>
            </div>
        </th>
        <td
            mat-cell
            *matCellDef="let row"
            [ngClass]="{'p-left-32': isSubRow, 'p-left-28': !isSubRow, 'bg-t':isNextTable}"
        >
            <div [ngStyle]="getStyles()" class="name-container" (click)="executeAction('navigate', row.element)">
                @if (!isDisplayingBin) {
                <div class="expand-button-container">
                    @if (isResource(row.element) && getAssetCount(row.element) > 0) {
                    <button mat-icon-button (click)="toggleExpansion(row); $event.stopPropagation()">
                        @if ((row.expanded$ | async) === false) {
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        } @if ((row.expanded$ | async) === true) {
                        <mat-icon>keyboard_arrow_down</mat-icon>
                        }
                    </button>
                    }
                </div>
                }
                <div class="name-content text-ellipsis">
                    @if (isResource(row.element) && row.element.type === 'folder') {
                    <mat-icon class="icon-color">folder</mat-icon>
                    } @if (isResource(row.element) && row.element.type === 'clipbin') {
                    <mat-icon class="icon-color" role="img" aria-hidden="true">subscriptions</mat-icon>
                    }
                    <span class="name-text">{{ row.element.name }}</span>
                </div>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef class="content-header-cell">Content</th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">
            <div class="text-ellipsis">
                @switch (getAssetCount(row.element)) { @case (0) { No items } @case (1) { 1 item } @default { {{
                getAssetCount(row.element) }} items } }
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="lastModified">
        <th mat-header-cell *matHeaderCellDef class="last-modified-header-cell">Last Modified</th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">
            {{ isResource(row.element) ? (row.element.updatedAt | date : 'mediumDate') : '' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef class="owner-header-cell">Owner</th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">
            <div class="owner-container text-ellipsis">
                @if (isResource(row.element) && ((row.element.owner && row.element.owner.length) || (row.element.owner
                && row.element.owner.length))) {
                <span
                    >{{ row.element!.owner || row.element!.owner!.length ? row.element.owner : row.element.owner
                    }}</span
                >
                } @else {
                <mat-icon role="img" aria-hidden="true" class="person-icon">person</mat-icon>
                }
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="menu" class="text-align-r">
        <th mat-header-cell *matHeaderCellDef class="menu-header-cell">
            <p class="location">Location</p>
        </th>
        <td mat-cell *matCellDef="let row" class="menu-cell" [ngClass]="{'bg-t':isNextTable}">
            @if (isAdmin || (!isShowAll)) {
              <div class="menu-button-container" [ngClass]="{'justify-flex-end':!isDisplayingBin}">
                  @if (isDisplayingBin) {
                  <mam-breadcrumb [resource]="row.element"></mam-breadcrumb>
                  }
                  <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                      <mat-icon>more_vert</mat-icon>
                  </button>
              </div>
            } @else {
              @if (isClipbin(row.element)) {
                <div class="menu-button-container" [ngClass]="{'justify-flex-end':!isDisplayingBin}">
                    @if (isDisplayingBin) {
                    <mam-breadcrumb [resource]="row.element"></mam-breadcrumb>
                    }
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>
                <mat-menu #menu="matMenu">
                    <button
                      matTooltipPosition="left"
                      [matTooltip]="(row.element | assetCount) === 0 ? 'No clips to share' : ''"
                      mat-menu-item
                      [disabled]="getAssetCount(row.element) === 0"
                      (click)="openShareBin(row.element)"
                      mam-firebase-ga="Open clip bin share dialog"
                      [mam-firebase-resource]="row.element.name"
                    >
                      Share clip bin
                    </button>
                </mat-menu>
              }
            }
            <mat-menu #menu="matMenu">
              @if (isClipbin(row.element)) {
                <button
                  matTooltipPosition="left"
                  [matTooltip]="(row.element | assetCount) === 0 ? 'No clips to share' : ''"
                  mat-menu-item
                  [disabled]="getAssetCount(row.element) === 0"
                  (click)="openShareBin(row.element)"
                  mam-firebase-ga="Open clip bin share dialog"
                  [mam-firebase-resource]="row.element.name"
                >
                  Share clip bin
                </button>
              }
                <button
                    mat-menu-item
                    (click)="executeAction('rename', row.element)"
                    mam-firebase-ga="Open rename dialog"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Rename
                </button>
                @if (!isShowAll) {
                <button
                    mat-menu-item
                    (click)="executeAction('move', row.element)"
                    mam-firebase-ga="Open move dialog"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Move
                </button>
                }
                <button
                    mat-menu-item
                    (click)="executeAction('delete', row.element)"
                    class="delete-menu-item"
                    mam-firebase-ga="Select deletion"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Delete
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [ngClass]="{'bg-t':isNextTable}">
            <div [@detailExpand]="(row.expanded$ | async) ? 'expanded' : 'collapsed'" class="expanded-detail-container">
                <div class="animation-wrapper">
                    @if ((row.expanded$ | async) && !expandedFolderDataCache[row.id]) {
                    <div class="loading-animation"></div>
                    }
                </div>
                @if ((row.expanded$ | async) && expandedFolderDataCache[row.id]) {
                <mam-cbo-list-display
                    [result]="expandedFolderDataCache[row.id]"
                    [parentId]="row.id"
                    [owner]="owner"
                    [isSubRow]="true"
                    [startAfter]="startAfter"
                    [isAdmin]="isAdmin"
                    [isShowAll]="isShowAll"
                    [isNested]="isNested"
                >
                </mam-cbo-list-display>
                }
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef="let column" [ngClass]="{'selection-wrapper__show-mine': !isShowAll, 'selection-wrapper__show-all': isShowAll}">
            <div class="selection-wrapper">
                <mat-checkbox
                    color="primary"
                    (change)="$event? toggleAllSelect($event.checked): null"
                    [checked]="selectionService.selectAll$()"
                    [indeterminate]="(selectionService.currentSelection.size > 0) && !selectionService.selectAll$()"
                ></mat-checkbox>
            </div>
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable,'selection-wrapper__show-mine': !isShowAll, 'selection-wrapper__show-all': isShowAll}">
            <mat-checkbox
                [disabled]="isChildSelected(row)"
                color="primary"
                (change)="$event? toggleSelect(row): null"
                [checked]="selectionService.isSelected(row.element) || selectionService.selectAll$() || isChildSelected(row)"
            >
            </mat-checkbox>
        </td>
    </ng-container>

    <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        [hidden]="isSubRow || isNextTable"
        class="sticky-header-row"
    ></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="row.expanded$ | async"
    ></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        [hidden]="(row.expanded$ | async) === false"
        class="detail-row"
    ></tr>
</table>
} @else {
<p>Loading...</p>
} @if (showLoadMoreButton) {
<table mat-table [dataSource]="[1]">
    <ng-container matColumnDef="loadMore">
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">
            @if (resourceContent) {
            <mam-cbo-list-display
                [result]="resourceContent"
                [isSubRow]="isSubRow"
                [owner]="owner"
                [startAfter]="startAfter"
                [offset]="offset"
                [isNextTable]="true"
                [isAdmin]="isAdmin"
                [isShowAll]="isShowAll"
                [isNested]="isNested"
                [parentId]="parentId"
            >
            </mam-cbo-list-display>
            } @else {
            <div class="load-more-container">
                @if (isLoadingMore) {
                <div class="animation-wrapper">
                    <div class="loading-animation"></div>
                </div>
                } @else {
                <button mat-raised-button class="load-more-button" (click)="onLoadMore()">Load More</button>
                }
            </div>
            }
        </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: ['loadMore']"></tr>
</table>
}
