/**
 * Service responsible for managing the selection state of items
 * within the list and grid views. This service was created
 * to decouple selection logic from the `ResourceService`, which
 * handles other resource-related operations.
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../../auth/auth_service';
import { environment } from '../../../environments/environment';
import { Clip } from '../../../services/asset_service';
import { Bin } from '../../../services/bin.service';

import { Resource } from './resource.service';

type ResultItem = Resource | Bin | Clip;
export interface BulkMoveFoldersResult {
    movedFolders: Resource[];
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class SelectionService {

    private readonly BASE_URL = environment.resourcesApi;

    childSelection = signal(new Set<ResultItem>());

    get childSelection$() {
        return this.childSelection.asReadonly();
    }

    get selection$() {
        return this.selection.asReadonly();
    }

    get selectAll$() {
        return this.selectAll.asReadonly();
    }

    private selection = signal(new Set<ResultItem>());

    private selectAll = signal(false);

    private hasParentAndId(
        item: ResultItem
    ): item is (Resource | Bin | Clip) & { id: string; parent?: { id: string } } {
        return 'id' in item && 'parent' in item && item.parent !== undefined && 'id' in item.parent;
    }

    private hasId(item: ResultItem): item is ResultItem & { id: string } {
        return 'id' in item && typeof item.id === 'string';
    }

  private hasType(item: ResultItem): item is ResultItem & { type: string } {
      return 'type' in item && typeof item.type === 'string';
  }

    /**
     * This is basically what you need in order to get the current array of selected items
     * so you could just `return Array.from(this.selectionService.currentSelection)` in your component and perform actions with the selected items
     * or even set a rule to not perform an action if the array has (length > 1) etc...
     */
    get currentSelection(): Set<ResultItem> {
        return this.selection();
    }

    get currentRegisteredRows(): Set<ResultItem> {
      return this.registeredRows;
    }

    getRegisteredTopLevelRows(): ResultItem[] {
      return Array.from(this.registeredRows).filter(row => row.level === 0);
    }

    /**
     * Checks if an item is selected.
     *
     * @param item The item to check.
     * @returns True if the item is selected, false otherwise.
     */
    isSelected(item: ResultItem): boolean {
        return this.selection().has(item);
    }

    private registeredRows = new Set<ResultItem>();

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient
    ) {}

    /**
     * Registers an item for selection management. This is essential for the "select all"
     * functionality to work correctly. Items must be registered before they can be
     * selected or included in the "select all" operation.
     *
     * @param row The item to register.
     */
    registerRow(row: ResultItem) {
        this.registeredRows.add(row);
        if (this.hasParentAndId(row) && row.parent) {
            const parentId = row.parent.id;
            const parentInSelection = Array.from(this.selection()).find((selectedItem) => {
                return this.hasId(selectedItem) && selectedItem.id === parentId;
            });
            const parentInChildrenSelection = Array.from(this.childSelection()).find((childItem) => {
                return this.hasId(childItem) && childItem.id === parentId;
            });

            if (parentInSelection || parentInChildrenSelection) {
                this.childSelection.update((currentChildSelection) => {
                    const newChildSelection = new Set(currentChildSelection);
                    newChildSelection.add(row);
                    return newChildSelection;
                });
            }
        }
    }

    /**
     * Unregisters a set of items from selection management and removes them from the
     * current selection and child selection. This is called when items are removed
     * from the view (e.g., when a user filters the list or navigates away from the
     * view). Unregistering prevents memory leaks and ensures that the selection
     * state is consistent with the displayed items. It also removes any descendant
     * selections if a parent item is unregistered.
     *
     * @param table An array of `ExpandedRow` objects representing the rows to unregister.
     */
    unregisterRow(table: ResultItem[]) {
        table.forEach((element: ResultItem) => {
            if (this.registeredRows.has(element)) {
                this.registeredRows.delete(element);

                this.selection.update((currentSelection) => {
                    const newSelection = new Set(currentSelection);
                    newSelection.delete(element);
                    return newSelection;
                });

                this.childSelection.update((currentChildSelection) => {
                    const newChildSelection = new Set(currentChildSelection);
                    newChildSelection.delete(element);
                    return newChildSelection;
                });

                if (this.hasId(element)) {
                    this.removeDescendantsFromChildSelection(element);
                }
            }
        });

        if (this.selection().size === 0) {
            this.selectAll.set(false);
        }
    }

    /**
     * Sets the "select all" state. This operation efficiently updates the selection
     * based on the `registeredRows`. If `checked` is true, all registered items are
     * selected. If `checked` is false, the selection is cleared.
     *
     * @param checked True to select all items, false to deselect all items.
     */
    setSelectAll(checked: boolean) {
        this.selectAll.set(checked);

        if (checked) {
            const newSelection = new Set<ResultItem>();
            const newChildSelection = new Set<ResultItem>();

            this.registeredRows.forEach((row) => {
                if (this.hasChildren(row, Array.from(this.registeredRows))) {
                    newSelection.add(row);
                    const descendants = this.getDescendants(row, Array.from(this.registeredRows));
                    descendants.forEach((descendant) => newChildSelection.add(descendant));
                } else {
                    newSelection.add(row);
                }
            });

            newSelection.forEach((item) => {
                if (newChildSelection.has(item)) {
                    newSelection.delete(item);
                }
            });

            this.selection.set(newSelection);
            this.childSelection.set(newChildSelection);
        } else {
            this.selection.set(new Set<ResultItem>());
            this.childSelection.set(new Set<ResultItem>());
        }
    }
    /**
     * Toggles the selection state of a single item. This method updates the `selection`
     * signal and also updates the `selectAll` signal to maintain consistency.  When an
     * item is selected, it removes any other selected items that have a different
     * level or, if the level is not 0, a different parent.  After toggling an item,
     * it checks if *all* registered items are now selected and updates the `selectAll`
     * state accordingly.
     *
     * @param item The item to toggle.
     */
    toggleSelect(item: ResultItem) {
        this.selection.update((currentSelection) => {
            const newSelection = new Set(currentSelection);
            const isSelected = newSelection.has(item);

            if (isSelected) {
                newSelection.delete(item);
                this.removeDescendantsFromChildSelection(item);

                const descendants = this.getDescendants(item, Array.from(this.registeredRows));
                this.childSelection.update((currentChildSelection) => {
                    const newChildSelection = new Set(currentChildSelection);
                    descendants.forEach((descendant) => newChildSelection.delete(descendant));
                    return newChildSelection;
                });
            } else {
                const itemLevel = this.getLevel(item);
                const itemParentId = this.getParentId(item);

                const conflictingItems = Array.from(newSelection).filter((selectedItem) => {
                  if (this.hasType(item) && item.type === 'clipbin') return false;
                    const selectedLevel = this.getLevel(selectedItem);
                    const selectedParentId = this.getParentId(selectedItem);
                    return itemLevel !== selectedLevel || (itemLevel !== 0 && itemParentId !== selectedParentId);
                });

                conflictingItems.forEach((conflictingItem) => newSelection.delete(conflictingItem));
                newSelection.add(item);
                this.addDescendantsToChildSelection(item);
            }

            this.handleParentChildSelection(newSelection);

            if (newSelection.size === 0) {
                this.selectAll.set(false);
            } else if (newSelection.size === this.registeredRows.size) {
                this.selectAll.set(true);
            } else {
                this.selectAll.set(false);
            }

            return newSelection;
        });
    }

    /**
     * Handles parent-child selection logic to ensure that when a parent is selected,
     * all its children are also selected, and when a parent is deselected, its
     * children are deselected as well.
     *
     * @param currentSelection The current selection set.
     */
    private handleParentChildSelection(currentSelection: Set<ResultItem>) {
        const parents = Array.from(currentSelection).filter((item) =>
            this.hasChildren(item, Array.from(this.registeredRows))
        );

        const childrenToRemove = new Set<ResultItem>();

        parents.forEach((parent) => {
            if (!currentSelection.has(parent)) {
                const descendants = this.getDescendants(parent, Array.from(this.registeredRows));
                descendants.forEach((descendant) => childrenToRemove.add(descendant));
            }
        });

        this.childSelection.update((currentChildSelection) => {
            const newChildSelection = new Set(currentChildSelection);
            childrenToRemove.forEach((child) => newChildSelection.delete(child));
            return newChildSelection;
        });
    }

    /**
     * Recursively retrieves all descendants of an item.
     *
     * @param item The item to get descendants for.
     * @param items The array of items to search within.
     * @returns An array of descendant items.
     */
    private getDescendants(item: ResultItem, items: ResultItem[]): ResultItem[] {
        const descendants = items.filter((potentialDescendant) => {
            if (this.hasParentAndId(potentialDescendant)) {
                return this.hasId(item) && potentialDescendant.parent
                    ? potentialDescendant.parent.id === item.id
                    : false;
            } else {
                return false;
            }
        });

        const allDescendants = [...descendants];
        descendants.forEach((descendant) => {
            allDescendants.push(...this.getDescendants(descendant, items));
        });
        return allDescendants;
    }

    /**
     * Checks if an item has any children within a given array of items.
     *
     * @param item The item to check for children.
     * @param items The array of items to search within.
     * @returns True if the item has children, false otherwise.
     */
    private hasChildren(item: ResultItem, items: ResultItem[]): boolean {
        return items.some((potentialDescendant) => {
            return this.hasParentAndId(potentialDescendant) && this.hasId(item)
                ? potentialDescendant.parent?.id === item.id
                : false;
        });
    }

    /**
     * Gets the level of an item in the hierarchy.
     *
     * @param item The item to get the level for.
     * @returns The level of the item.
     */
    private getLevel(item: ResultItem): number {
        return item.level || 0;
    }

    /**
     * Gets the ID of the parent of an item.
     *
     * @param item The item to get the parent ID for.
     * @returns The ID of the parent item, or undefined if the item has no parent.
     */
    private getParentId(item: ResultItem): string | undefined {
        if (this.hasParentAndId(item)) {
            return item.parent?.id;
        }
        return undefined;
    }
    /**
     * Retrieves the authorization headers for HTTP requests.
     *
     * @returns The HTTP headers with the authorization token.
     */
    private getAuthHeaders(): HttpHeaders {
        const accessToken = this.authService.getAccessToken();
        return new HttpHeaders({
            Authorization: `Bearer ${accessToken}`
        });
    }

    /**
     * Recursively adds the descendants of an item to the child selection.
     *
     * @param item The item to add descendants for.
     */
    private addDescendantsToChildSelection(item: ResultItem) {
        const descendants = Array.from(this.registeredRows).filter((potentialDescendant) => {
            return this.hasParentAndId(potentialDescendant) && this.hasId(item)
                ? potentialDescendant.parent?.id === item.id
                : false;
        });

        this.childSelection.update((currentChildSelection) => {
            const newChildSelection = new Set(currentChildSelection);
            descendants.forEach((descendant) => newChildSelection.add(descendant));
            return newChildSelection;
        });

        descendants.forEach((descendant) => this.addDescendantsToChildSelection(descendant));
    }
    /**
     * Recursively removes the descendants of an item from the child selection.
     *
     * @param item The item to remove descendants for.
     */
    private removeDescendantsFromChildSelection(item: ResultItem) {
        const descendants = Array.from(this.registeredRows).filter((potentialDescendant) => {
            return this.hasParentAndId(potentialDescendant) && this.hasId(item)
                ? potentialDescendant.parent?.id === item.id
                : false;
        });

        this.childSelection.update((currentChildSelection) => {
            const newChildSelection = new Set(currentChildSelection);
            descendants.forEach((descendant) => newChildSelection.delete(descendant));
            return newChildSelection;
        });

        descendants.forEach((descendant) => this.removeDescendantsFromChildSelection(descendant));
    }

    /**
     * Performs a bulk move operation for folders.
     *
     * @param selectedFoldersIds An array of IDs of the folders to move.
     * @param newParentId The ID of the new parent folder.
     * @returns An HTTP request for the bulk move operation.
     */
    bulkMoveFolders(selectedFoldersIds: string[], newParentId: string | undefined) {
      if (newParentId === '0') newParentId = '';
      return this.httpClient
      .post<BulkMoveFoldersResult>(
          `${this.BASE_URL}/folders/bulk-move`,
          { newParentId: newParentId, selectedFoldersIds },
          { headers: this.getAuthHeaders() }
        );
    }

    /**
     * Performs a bulk delete operation for folders.
     *
     * @param selectedFoldersIds An array of IDs of the folders to delete.
     * @returns An HTTP request for the bulk delete operation.
     */
    bulkDeleteFolders(selectedFoldersIds: string[]) {
        return this.httpClient.post<Resource>(
            `${this.BASE_URL}/folders/bulk-delete`,
            { selectedFoldersIds },
            { headers: this.getAuthHeaders() }
        );
    }

    /**
     * Performs a bulk move operation for clip bins.
     *
     * @param clipBins An array of IDs of the clip bins to move.
     * @param newParentId The ID of the new parent clip bin.
     * @returns An HTTP request for the bulk move operation.
     */
    bulkMoveClipBins(clipBins: string[], newParentId: string | undefined) {
     newParentId = newParentId === '0' ? '' : newParentId;
        return this.httpClient.post<Resource>(
            `${this.BASE_URL}/clip-bins/bulk-move`,
            { newParentId: newParentId, clipBins },
            { headers: this.getAuthHeaders() }
        );
    }
    /**
     * Performs a bulk delete operation for clip bins.
     *
     * @param clipBins An array of IDs of the clip bins to delete.
     * @returns An HTTP request for the bulk delete operation.
     */
    bulkDeleteCLipBins(clipBins: string[]): Observable<{ message: string; clipbin: string }[]> {
      return this.httpClient.post<{ message: string; clipbin: string }[]>(
        `${this.BASE_URL}/clip-bins/bulk-delete`,
        { clipBins },
        { headers: this.getAuthHeaders() }
      );
    }
}
