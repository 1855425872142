import { Injectable, NgZone } from '@angular/core';
import { DocumentData, QueryDocumentSnapshot, QueryFieldFilterConstraint, where } from '@firebase/firestore';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth/auth_service';

import { FirebaseResolver, IASDataObject } from './firebase_resolver';

/**
 * Collection to store 'facets favorite options' configuration.
 * For now items/records/documents in collection consist of the next fields:
 * - email (string) - configuration owner's email;
 * - facet (string) - facet name;
 * - options (string[]) - favorite options list for current facet^;
 */
const FACETS_FAVORITE_OPTIONS_COLLECTION = 'ias-facets-favorite-options';
/**
 * Collection to store 'never expired' mark for the shared links.
 * For now items/records/documents in collection consist of the next fields:
 * - email (string) - shared link creator's email;
 * - expireTime (string) - shared link expiration time;
 * - name (string) - shared link name (key field);
 */
const SHARED_LINK_NEVER_EXPIRED_COLLECTION = 'ias-shared-link-never-expired';

/**
 * Collection to store info about the deleted assets.
 * Used to manage deleted assets list by monitoring/handling the "Delete asset"
 * (in asset's actions) and "Undelete" (in "Jobs/Deleted items" tab) actions.
 * Allows to filter out children clips with the deleted parent asset on the
 * clipbin share view.
 */
const DELETED_ASSETS_COLLECTION = 'ias-deleted-assets';

/** Max number of the items supported for "IN" conditions. */
const IN_CONDITION_LIMIT = 30;


export enum IASEventType {
  TRANSFER = 'generic-transfer',
  VOD_CLIP_EXPORT = 'vod-clip-export',
  VOD_ORIGINAL_EXPORT = 'vod-original-export',
  LIVE_CLIP_EXPORT = 'live-clip-export',
  COMP_REEL_EXPORT = 'comp-reel-export',
  LOCAL_UPLOAD = 'local_upload',
  DELETE_ASSET = 'delete-asset',
  LOGIN = 'login',
}

export declare interface IASEvent extends IASDataObject {
  type: string
  state?: string;
  assetTitle?: string
  assetName? : string
  clipBinTitle?: string
  clipBinName?: string
  filename?: string;
  folderId? : string
}

@Injectable({ providedIn: 'root' })
export class FirebaseFirestoreDataService {
    constructor(
        private readonly firebaseResolver: FirebaseResolver,
        private readonly authService: AuthService,
        private readonly ngZone: NgZone
    ) { }

    async createIASEvent(data: IASEvent) {
      data.emailID = this.authService.getUserEmail();
      data.username = this.authService.getUserName();

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.createFirestoreDoc('ias-event', data);
      });
    }

    async updateDocument(id: string, data: IASEvent) {
      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.updateFirestoreDoc('ias-event', id, data);
      });
    }

    retrieveIASEventsForLoginAction(emails: string[]) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('type', '==', IASEventType.LOGIN),
        where('emailID', 'in', emails),
      ];
      return this.queryIASEvent(constraints, emails.length);
    }

    retrieveDocumentForLoginAction(email: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('type', '==', IASEventType.LOGIN),
        where('emailID', '==', email),
      ];
      return this.queryDocument(constraints, 1);
    }

    retrieveIASEventForTransfer(filename: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('filename','==',filename),
        where('type','==',IASEventType.TRANSFER)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForLocalUpload(filename: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('filename','==',filename),
        where('type','==',IASEventType.LOCAL_UPLOAD)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForVodClipExport(assetName: string, folderId: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('assetName','==',assetName),
        where('folderId','==',folderId),
        where('type','==',IASEventType.VOD_CLIP_EXPORT)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForLiveClipExport(assetName: string, folderId: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('assetName','==',assetName),
        where('folderId','==',folderId),
        where('type','==',IASEventType.LIVE_CLIP_EXPORT)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForCompReelExport(filename: string, folderId: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('filename','==',filename),
        where('folderId','==',folderId),
        where('type','==',IASEventType.COMP_REEL_EXPORT)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForDeleteAsset(assetName: string, assetTitle: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('assetName','==',assetName),
        where('assetTitle','==',assetTitle),
        where('type','==',IASEventType.DELETE_ASSET)
      ];
      return this.queryIASEvent(constraints,1);
    }

    private queryIASEvent(constraints: QueryFieldFilterConstraint[], limitSize: number) {
      return this.queryDocument(constraints, limitSize)
        .pipe(
          map(documents =>
            documents.map(document => {
              return document.data() as IASEvent;
            }))
        );
    }

    private queryDocument(constraints: QueryFieldFilterConstraint[], limitSize: number) {
      return this.firebaseResolver.queryCollection('ias-event', constraints, limitSize);
    }

    retrieveFacetsFavoriteOptions(): Observable<QueryDocumentSnapshot[]> {
      const email = this.authService.getUserEmail();
      const constraints: QueryFieldFilterConstraint[] = [
        where('email', '==', email),
      ];
      return this.firebaseResolver.queryCollection(FACETS_FAVORITE_OPTIONS_COLLECTION, constraints, 100);
    }

    async updateFacetsFavoriteOptions(id: string, data: DocumentData) {
      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.updateFirestoreDoc(FACETS_FAVORITE_OPTIONS_COLLECTION, id, data);
      });
    }

    async createFacetsFavoriteOptions(data: DocumentData) {
      data['email'] = this.authService.getUserEmail();

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.createFirestoreDoc(FACETS_FAVORITE_OPTIONS_COLLECTION, data);
      });
    }

    /** Creates item/record/document in the 'shared link never expired' collection. */
    async createSharedLinkNeverExpired(data: DocumentData) {
      data['email'] = this.authService.getUserEmail();

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.createFirestoreDoc(SHARED_LINK_NEVER_EXPIRED_COLLECTION, data);
      });
    }

    /**
     * Deletes all the items/records in the 'shared link never expired' collection with the specified
     * shared link name. Normally there should/might be just single record for any shared link name.
     *
     * @param name shared link name.
     */
    async deleteSharedLinkNeverExpired(name: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('name', '==', name),
      ];

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.deleteCollectionBatchMode(SHARED_LINK_NEVER_EXPIRED_COLLECTION, constraints);
      });
    }

    retrieveSharedLinkNeverExpired(names: string[]): Observable<QueryDocumentSnapshot[]> {
      const constraints: QueryFieldFilterConstraint[] = [
        where('name', 'in', names),
      ];
      return this.firebaseResolver.queryCollectionWithoutLimitSize(SHARED_LINK_NEVER_EXPIRED_COLLECTION, constraints);
    }


    async createDeletedAsset(data: DocumentData) {
      data['email'] = this.authService.getUserEmail();

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.createFirestoreDoc(DELETED_ASSETS_COLLECTION, data);
      });
    }

    async deleteDeletedAsset(name: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('name', '==', name),
      ];

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.deleteCollectionBatchMode(DELETED_ASSETS_COLLECTION, constraints);
      });
    }

    getDeletedAssets(names: string[]): Observable<QueryDocumentSnapshot[]> {
      const uniqueNames = Array.from(new Set(names));

      const chunkSize = IN_CONDITION_LIMIT;
      const chunks: string[][] = [];
      for (let i = 0; i < uniqueNames.length; i += chunkSize) {
        chunks.push(uniqueNames.slice(i, i + chunkSize));
      }
      const queries = chunks.map((chunk) => {
        return this.queryDeletedAssets(chunk);
      });

      return combineLatest(queries).pipe(
        map((results) => results.flat())
      );
    }

    private queryDeletedAssets(names: string[]): Observable<QueryDocumentSnapshot[]> {
      const constraints: QueryFieldFilterConstraint[] = [
        where('name', 'in', names),
      ];

      return this.firebaseResolver.queryCollectionWithoutLimitSize(DELETED_ASSETS_COLLECTION, constraints);
    }
  }
