import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentReference } from 'firebase/firestore';
import {
  asyncScheduler,
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  delay,
  EMPTY,
  finalize,
  first,
  firstValueFrom,
  forkJoin,
  from,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  reduce,
  ReplaySubject,
  scheduled,
  take,
  tap,
  toArray
} from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from '../../auth/auth_service';
import { RestrictionElasticHitSource } from '../../elastic_search/elastic_search.model';
import { ElasticSearchService } from '../../elastic_search/elastic_search.service';
import { isErrorResponse } from '../../error_service/error_response';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { SharedLink as SharedLinkFromClip } from '../../models/shared-link';
import { Asset, AssetService, Clip, Original } from '../../services/asset_service';
import { SearchMode, SearchSegment } from '../../services/search_service';
import { SharedLinksService } from '../../services/shared_links_service';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';
import { PaddedSegment } from '../../services/vod_search_service';
import { SharedLink } from '../../shared_clipbin/models/shared_link_clipbin.model';
import { SharedLinkClipBinService } from '../../shared_clipbin/services/shared_link_clipbin.service';
import { AccessConfirm, AccessConfirmDialog } from '../component/access-confirm-dialog/access-confirm-dialog.component';
import { AccessRestrictionAssetDialog } from '../component/access-restriction-asset-dialog/access-restriction-asset-dialog.component';
import { Restriction } from '../component/access-restriction-options/access-restriction-options.component';
import { PermissionDetail, ResourceAccessInfo } from '../models/access_management.model';

import { AccessManagementService } from './access_management.service';


export type RestrictionResult = { documentId: string | undefined, permissions: PermissionDetail[], restriction: Restriction } | undefined;

export type ClipbinsAndClips = { clips: Clip[], clipBinName: string, documentId: string | undefined, clipSharedLinks: SharedLink[] | undefined };

/**
 * This delay is necessary to give an interval between update the firebase and request the updates
 */
const DELAY_TIME = 2000;

/** Duplicated constant from the bulk_clips_actions. */
export const LIST_ALL_CLIPS_PAGE_SIZE = 50;

/** Duplicated constant from the bulk_clips_actions. */
export const MAX_CLIPS_COUNT_FOR_BULK = 400;

export type AssetToRevoke = { name?: string, title?: string };

@Injectable({
  providedIn: 'root'
})
export class AccessManagementActionsService {

  private _loading$ = new BehaviorSubject(false);

  public get loading$() {
    return this._loading$;
  }
  public set loading$(value) {
    this._loading$ = value;
  }

  public readonly lastSearchQuery = new ReplaySubject<string>(1);
  public lastSearchQueryString: string = '';

  /** Flag to use Access Management feature. */
  readonly isEnabledAccessManagement = this.featureService.featureOn('enable-access-management');

  constructor(
    private readonly snackbar: SnackBarService,
    private readonly dialog: MatDialog,
    private readonly accessManagementService: AccessManagementService,
    private readonly clipbinSharedLinkService: SharedLinkClipBinService,
    private readonly sharedLinksService: SharedLinksService,
    private readonly stateService: StateService,
    private readonly authService: AuthService,
    private readonly assetService: AssetService,
    private readonly elasticSearchService: ElasticSearchService,
    private readonly featureService: FeatureFlagService,
  ) {
    this.lastSearchQuery.subscribe(query => {
        this.lastSearchQueryString = query;
    });
  }

  getRestriction(assetName: string): Observable<RestrictionResult> {
    if (!assetName) return of(undefined);

    return this.accessManagementService.getResourceAccessInfoByResource('RESTRICTION', assetName, 'ASSET')
      .pipe(
        first(),
        map(resources => ({
          documentId: resources[0]?.documentId,
          permissions: resources[0]?.permissions ?? [],
          restriction: resources[0]?.resourceAccessType === 'RESTRICTION' ? 'restrict' : 'public' as Restriction
        }))
      );
  }

  getRestrictionsByTitle(title: string) {
    return this.elasticSearchService.getResourceAccessInfoByTextInTitle(title).pipe(
      mergeMap((aArray) =>
        from(aArray).pipe(
          mergeMap((restrictionElastic: RestrictionElasticHitSource) =>
            this.assetService.getAsset(encodeURI(restrictionElastic.resourceid)).pipe(
              filter((retrievedAsset): retrievedAsset is Original => retrievedAsset != null),
              map((filteredAsset) => ({
                endTime: 0,
                eventId: '',
                query: '',
                searchMode: SearchMode.VIDEO,
                startTime: 0,
                asset: filteredAsset,
                name: filteredAsset.name ?? '',
                permissions: [],
                permissionsDocumentId: restrictionElastic.id,
              } as SearchSegment))
            )
          ),
          toArray()
        )
      )
    );
  }

  openAccessManagement(peopleAccess: PermissionDetail[], documentId: string) {
    return this.dialog.open(AccessRestrictionAssetDialog,
      {
        autoFocus: false,
        data: {
          permissions: peopleAccess.map((p) => { return { email: p.userId, displayName: p.displayName }; }),
          documentId: documentId
        }
      }).afterClosed()
      .pipe(
        concatMap((dataConfirm) => {
          if (dataConfirm == false) return EMPTY;

          return of(dataConfirm).pipe(
            finalize(() => this.stateService.restrictAssetUpdateFromMetadata$.next(true))
          );
        }),
      );
  }

  openRestrictDialog(asset: Asset): Observable<undefined | DocumentReference> {

    const data: AccessConfirm = {
      icon: 'restrict__custom',
      title: 'Make Asset Restricted?',
      content: 'Are you sure you want to restrict this asset and associated clips? This will also revoke any existing shared links and will prevent users from creating shared links.',
      buttons: [
        {
          text: 'CANCEL',
          type: 'default',
          value: false
        },
        {
          text: 'CONFIRM',
          type: 'default',
          value: true
        }
      ]
    };

    return this.dialog.open(AccessConfirmDialog, { data })
      .afterClosed()
      .pipe(
        concatMap((confirm) => {
          if (confirm && asset) {
            const data: Partial<ResourceAccessInfo> = {
              permissions: [],
              resourceAccessType: 'RESTRICTION',
              resourceId: asset.original?.name ?? asset.name,
              resourceType: 'ASSET',
              title: asset.title
            };

            return scheduled(this.accessManagementService.createResourceAccessInfo(data), asyncScheduler)
              .pipe(
                tap(() => this.revokeClipbinRelated([{ name: asset.name, title: asset.title }])),
                finalize(() => this.stateService.restrictAssetUpdateFromMetadata$.next(true))
              );
          }

          return EMPTY;
        }),
      );
  }

  openPublicDialog(documentId: string): Observable<void> {

    //Make Asset Public
    const data: AccessConfirm = {
      icon: 'public',
      title: 'Make Asset Public?',
      content: 'This will make the asset accessible to everyone. Ensure you\'re ready to share it publicly, as this action may impact privacy and access controls.',
      buttons: [
        {
          text: 'CANCEL',
          type: 'default',
          value: false
        },
        {
          text: 'CONFIRM',
          type: 'default',
          value: true
        }
      ]
    };

    return this.dialog.open(AccessConfirmDialog, { data })
      .afterClosed()
      .pipe(concatMap((confirm) => {
        if (confirm) {
          return scheduled(this.accessManagementService.deleteDocument(documentId), asyncScheduler)
            .pipe(
              finalize(() => this.stateService.restrictAssetUpdateFromMetadata$.next(true))
            );
        }

        return EMPTY;
      })
      );
  }

  openMakePublicSelectedIds(documentIds?: string[]) {
    if (!documentIds?.length) {
      this.snackbar.message('These assets are already public.');
      return EMPTY;
    }
    //Make Assets Public
    const data: AccessConfirm = {
      icon: 'public',
      title: 'Make Asset Public?',
      content: 'This will make the asset accessible to everyone. Ensure you\'re ready to share it publicly, as this action may impact privacy and access controls.',
      buttons: [
        {
          text: 'CANCEL', type: 'default',
          value: false
        },
        {
          text: 'DONE', type: 'default',
          value: true
        }
      ]
    };

    return this.dialog.open(AccessConfirmDialog, { data })
      .afterClosed()
      .pipe(
        concatMap((confirm) => {
          if (confirm) {
            this.loading$.next(true);
            return scheduled(this.accessManagementService.deleteManyDocumentsList(documentIds), asyncScheduler)
              .pipe(
                finalize(() => this.stateService.restrictAssetUpdateFromMetadata$.next(true))
              );
          }
          return EMPTY;
        }),
        delay(DELAY_TIME),
        concatMap(async () => this.snackbar.message('The assets are now accessible to the public.')),
        catchError(async () => this.snackbar.error('Error to make Public')),
        finalize(() => {
          this.loading$.next(false);
        })
      );
  }

  openAddUsersAndMakeRestrictOnSelectedIds(resourceUpdate: ResourceAccessInfo[], assetsToCreate: Partial<Asset>[] = []) {
    const assetsToRevoke: AssetToRevoke[] = [];

    return this.dialog.open(AccessRestrictionAssetDialog,
      {
        autoFocus: false,
        data: {
          permissions: [],
          documentId: resourceUpdate.map(r => r.documentId),
          updateManyAsset: true
        }
      })
      .afterClosed()
      .pipe(
        concatMap(
          (result: boolean | Partial<PermissionDetail>[]) =>
            //Complete the observable if the user CLOSE dialog
            iif(() => !result,
              EMPTY,
              of(result as Partial<PermissionDetail>[]))
        ),
        map((permissions) => {
          this.loading$.next(true);
          const requestedToUpdate: Observable<void>[] = [];
          const requestedToCreate: Observable<DocumentReference | undefined>[] = [];

          resourceUpdate.forEach((resource) => {
            assetsToRevoke.push({ name: resource.resourceId, title: resource.title });
            const oldPermissions: Partial<PermissionDetail>[] = [...resource.permissions];
            const newPermissions: Partial<PermissionDetail>[] = [];

            permissions.forEach(newPermission => {
              const existInOldPermission = oldPermissions.some(permissionUpdated => permissionUpdated.userId === newPermission.userId);
              if (existInOldPermission) return;

              newPermissions.push(newPermission);
            });

            if (newPermissions.length === 0) return;

            if (resource.documentId) {
              const mergedPermissions = [...oldPermissions, ...newPermissions];
              const req = scheduled(this.accessManagementService.updatePermissions(resource.documentId, mergedPermissions), asyncScheduler);
              requestedToUpdate.push(req);
            }
          });

          assetsToCreate.forEach((asset) => {
            assetsToRevoke.push({ name: asset.name, title: asset.title });
            const data: Partial<ResourceAccessInfo> = {
              permissions: [...permissions] as PermissionDetail[],
              resourceAccessType: 'RESTRICTION',
              resourceId: asset.name,
              resourceType: 'ASSET',
              title: asset.title
            };

            const reqCreate = scheduled(this.accessManagementService.createResourceAccessInfo(data), asyncScheduler);
            requestedToCreate.push(reqCreate);
          });

          return combineLatest([...requestedToUpdate, ...requestedToCreate]).pipe(take(1), delay(DELAY_TIME / 2));
        }),
        delay(DELAY_TIME),
        tap(() => this.revokeClipbinRelated(assetsToRevoke)),
        concatMap(async () => this.snackbar.message('Users added with success!')),
        catchError(async () => this.snackbar.error('Error to add users')),
        finalize(() => {
          this.loading$.next(false);
          this.stateService.restrictAssetUpdateFromMetadata$.next(true);
        })
      );
  }


  revokeClipbinRelated(idAssetsClipsNames: AssetToRevoke[]) {
    const restrictClipsSelectedList = idAssetsClipsNames.filter(f => !!f.name);

    if (!restrictClipsSelectedList?.length) return;

    this.clipbinSharedLinkService
      .getAllValidIASClipBinShareLink()
      .pipe(
        take(1),
        concatMap((clipBins) => {
          const requestGroup: Observable<ClipbinsAndClips>[] = [];

          clipBins.forEach(clipbin => {
            const requestToGetClip = this.clipbinSharedLinkService.getAllClips(decodeURIComponent(clipbin.clipBinName))
              .pipe(
                reduce((clips, clip) => {
                  clips.push(clip);
                  return clips;
                }, [] as Clip[]),
                take(1),
                map((clips) => {
                  return {
                    clipSharedLinks: clipbin.clipSharedLinks || [],
                    clipBinName: clipbin.clipBinName,
                    documentId: clipbin.documentId,
                    clips,
                  } as ClipbinsAndClips;
                })
              );

            requestGroup.push(requestToGetClip);
          });

          return combineLatest(requestGroup);
        }),
        map((clipbinsShared) => {
          const toRevoke = clipbinsShared.filter((clipbinShared) =>
            clipbinShared.clips
              //for each clip on the shared clipbins, check if it has any restriction on it, from clip or assets
              .some((clip) => restrictClipsSelectedList
                .some((restrictClip) =>
                  //check if the clip into list has restriction
                  //if yes it will be remained in the list to have the access revoked
                  clip.name === restrictClip.name || clip.original.name === restrictClip.name
                )));

          return toRevoke.filter(c => !!c.documentId);
        }),
        concatMap(clipbinsResult =>
          iif(() =>
            //If no clips are found within clipbins, check and revoke any single clips.
            //If restricted clips exist within clipbins, revoke each clip and its associated clipbin.
            clipbinsResult.length === 0,
            this.revokeClips(restrictClipsSelectedList),
            this.revokeClipBins(clipbinsResult)
          )
        )
      ).subscribe(
      //TODO check if we should show up the snackbar on success or fail to revoke
    );
  }


  private revokeClips(restrictClipsSelectedList: AssetToRevoke[]) {
    const requestClipsByTitle = restrictClipsSelectedList
      .map(revoke => this.sharedLinksService.search(revoke.title as string, 10) as Observable<{ sharedLinks: SharedLinkFromClip[] } | null>);

    if (!requestClipsByTitle?.length) return of();

    return forkJoin(requestClipsByTitle)
      .pipe(
        concatMap((sources) => {
          const sharedLinks = sources.flatMap(source => source?.sharedLinks);
          //find all clips into sharedLinks that has same origin name
          const toRevoke = sharedLinks.filter(sharedLink => restrictClipsSelectedList
            .some(restrictClip => restrictClip.name === sharedLink?.original))
            .map(foundClip => foundClip?.name as string);
          return toRevoke;
        }),
        toArray(),
        concatMap((revokeNames) => this.sharedLinksService.revokeAll(revokeNames))
      );
  }

  private revokeClipBins(clipBinNameRevoke: ClipbinsAndClips[]) {
    const req = clipBinNameRevoke.map(clipbins => {
      const assetsClipName = clipbins.clipSharedLinks?.map(clipLink => clipLink.assetName) || [];
      return this.clipbinSharedLinkService.revokeClipBinAndClipsIntoClipBin(assetsClipName, clipbins.documentId as string);
    });

    return forkJoin(req);
  }

  checkNoAccessAndUpdatePermissionsForSelected(asset: Asset) {
    return this.checkAssetAndUpdatePermissions(asset, this.checkNoAccessState);
  }

  checkRestrictedAndUpdatePermissionsForSelected(asset: Asset) {
    return this.checkAssetAndUpdatePermissions(asset, this.checkRestrictedState);
  }

  private getAssetName(asset: Asset) {
    return asset.original?.name ?? asset.name;
  }

  private permissionsChanged(initialPermissions?: PermissionDetail[], actualPermissions?: PermissionDetail[]) {
    if ((initialPermissions && !actualPermissions) || (!initialPermissions && actualPermissions)) {
      return true;
    }

    const user = this.authService.getUserEmail();
    const initialPermission = initialPermissions?.find(p => p.userId === user);
    const actualPermission = actualPermissions?.find(p => p.userId === user);
    return initialPermission?.permission !== actualPermission?.permission;
  }

  private checkPermissionsState(
      checker: (isAdmin: boolean, user: string, permissions?: PermissionDetail[]) => boolean,
      permissions?: PermissionDetail[],
  ) {
    return checker(this.authService.isAdmin, this.authService.getUserEmail(), permissions);
  }

  private async checkAssetAndUpdatePermissions(
      asset: Asset,
      checker: (isAdmin: boolean, user: string, permissions?: PermissionDetail[]) => boolean,
  ): Promise<boolean> {

    if (!this.isEnabledAccessManagement) return false;

    const clipOrAsset = this.getAssetName(asset);
    const initialPermissions = asset.permissions;
    const actualRestrictionResult = await this.updateRestrictionForAsset(clipOrAsset);
    const actualPermissions = this.getPermissions(actualRestrictionResult);

    if (this.permissionsChanged(initialPermissions, actualPermissions)) {
      this.updateRestrictionForClipBinIfNeeded(clipOrAsset);
    }

    this.updateAssetRestrictionForSearchResults(clipOrAsset, actualRestrictionResult);

    return this.checkPermissionsState(checker, actualPermissions);
  }

  private updateAssetRestrictionForSearchResults(assetName: string, restrictionResult: RestrictionResult) {
    this.stateService.searchResponse$?.pipe(take(1)).subscribe(response => {
      const relatives = response?.videoSegments.filter(s => s.asset.name === assetName);
      const permissions = this.getPermissions(restrictionResult);
      relatives?.forEach(r => {
        r.permissions = permissions;
        r.permissionsDocumentId = restrictionResult?.documentId;
        r.asset.permissions = permissions;
        r.asset.permissionsDocumentId = restrictionResult?.documentId;
        r.asset.permissionsNoAccess = this.checkPermissionsState(this.checkNoAccessState, permissions);
      });
    });
  }

  private updateAssetsRestrictionForSearchResults(accessInfos: ResourceAccessInfo[]) {
    this.stateService.searchResponse$?.pipe(take(1)).subscribe(response => {
      accessInfos.forEach(info => {
        const segment = response?.videoSegments.find(s => s.asset.name === info.resourceId);
        if (segment) {
          segment.permissions = info.permissions;
          segment.permissionsDocumentId = info.documentId;
          segment.asset.permissions = info.permissions;
          segment.asset.permissionsDocumentId = info.documentId;
          segment.asset.permissionsNoAccess = this.checkPermissionsState(this.checkNoAccessState, info.permissions);
        }
      });
    });
  }

  private async updateRestrictionForAsset(assetName: string) {
    const restrictionResult = await firstValueFrom(this.getRestriction(assetName));
    const permissions = this.getPermissions(restrictionResult);

    this.stateService.currentAsset$
      .pipe(take(1))
      .subscribe((asset) => {
        if (asset && (asset.name === assetName || asset.original?.name === assetName)) {
          asset.permissions = permissions;

          if (this.checkPermissionsState(this.checkNoAccessState, permissions)) {
            this.stateService.currentAsset$.next(undefined);
          }
        }
      });

      return restrictionResult;
  }

  private getPermissions(restrictionResult: RestrictionResult) {
    return restrictionResult?.restriction === 'public' ? undefined : restrictionResult?.permissions;
  }

  private updateRestrictionForClipBin() {
    this.forceRedrawForClipBin();
  }

  private updateRestrictionForClipBinIfNeeded(assetName: string) {
    this.stateService.currentPersistentClips$
      .pipe(take(1))
      .subscribe(clips => {
        if (this.collectRelatives(clips, assetName).length) {
          this.forceRedrawForClipBin();
        }
      });
  }

  private collectRelatives(clips: Clip[], assetName: string) {
    return clips.filter(c => c.name === assetName || c.original.name === assetName);
  }

  private forceRedrawForClipBin() {
    this.stateService.restrictAssetUpdateFromMetadata$.next(true);
  }

  private getCurrentAsset() {
    return firstValueFrom(this.stateService.currentAsset$);
  }

  private checkRestrictedState(isAdmin: boolean, user: string, permissions?: PermissionDetail[]) {
    return !!permissions;
  }

  private checkNoAccessState(isAdmin: boolean, user: string, permissions?: PermissionDetail[]) {
    if (!this.isEnabledAccessManagement) return false;

    if (!permissions || isAdmin) {
      return false;
    }

    return !permissions.some(perm => perm.userId === user);
  }

  async checkClipBinAnyRestrictedAndUpdatePermissions(clipBinName: string) {
    if (!this.isEnabledAccessManagement) return false;
    const anyRestricted = await this.checkClipBin(clipBinName, this.checkRestrictedState);
    await this.checkAndUpdatePermissionsForCurrent(anyRestricted);
    return anyRestricted;
  }

  async checkClipBinAnyNoAccessAndUpdatePermissions(clipBinName: string) {
    if (!this.isEnabledAccessManagement) return false;
    const { isAdmin } = this.authService;
    const anyNoAccess = !isAdmin && (await this.checkClipBin(clipBinName, this.checkNoAccessState));
    await this.checkAndUpdatePermissionsForCurrent(anyNoAccess);
    return anyNoAccess;
  }

  async checkSegmentsAnyNoAccessAndUpdatePermissions(segments: PaddedSegment[]) {
    if (!this.isEnabledAccessManagement) return false;

    const assetsNames = segments.map(s => s.asset.name);
    return this.checkAssetsAnyNoAccessAndUpdatePermissions(assetsNames);
  }

  async checkAssetsAnyNoAccessAndUpdatePermissions(assetsNames: string[]) {
    if (!this.isEnabledAccessManagement) return false;

    const { isAdmin } = this.authService;

    const accessInfos = await this.getAccessInfo(assetsNames);
    this.updateAssetsRestrictionForSearchResults(accessInfos);

    const anyNoAccess = !isAdmin && accessInfos.some(info => this.checkPermissionsState(this.checkNoAccessState, info.permissions));
    await this.checkAndUpdatePermissionsForCurrent(anyNoAccess);
    return anyNoAccess;
  }

  private async checkAndUpdatePermissionsForCurrent(forceClipBinUpdate: boolean) {
    const currentAsset = await this.getCurrentAsset();
    if (currentAsset) {
      const clipOrAsset = this.getAssetName(currentAsset);
      await this.updateRestrictionForAsset(clipOrAsset);
    }

    if (forceClipBinUpdate) {
      this.updateRestrictionForClipBin();
    }
  }

  private async checkClipBin(
      clipBinName: string,
      checker: (isAdmin: boolean, user: string, permissions?: PermissionDetail[]) => boolean,
      nextPageToken = '',
      count = 0,
  ): Promise<boolean> {
    const clipsResponse = await firstValueFrom(this.assetService.searchClips(clipBinName, undefined, nextPageToken, LIST_ALL_CLIPS_PAGE_SIZE));
    if (isErrorResponse(clipsResponse)) {
      return false;
    }

    const clipsNames = clipsResponse.assets.map(c => c.original?.name || c.name);
    const accessResponse = await firstValueFrom(this.accessManagementService.getListResourceAccessInfoByResources('RESTRICTION', clipsNames, 'ASSET'));
    if (accessResponse.some(info => this.checkPermissionsState(checker, info.permissions))) {
      return true;
    }

    if (!clipsResponse.nextPageToken) {
      return false;
    }
    if (count >= MAX_CLIPS_COUNT_FOR_BULK - LIST_ALL_CLIPS_PAGE_SIZE) {
      return false;
    }
    return this.checkClipBin(clipBinName, checker, clipsResponse.nextPageToken, count + clipsResponse.assets.length);
  }

  private async getAccessInfo(assetsNames: string[]): Promise<ResourceAccessInfo[]> {
    return firstValueFrom(this.accessManagementService.getListResourceAccessInfoByResources('RESTRICTION', assetsNames, 'ASSET'));
  }

  /**
   *
   * Retrieves the number of restrictions associated with a list of clips.
   *
   * This function takes an array of `Clip` objects, extracts their names (preferring the `original.name` if available),
   * and uses the `accessManagementService` to fetch restriction information for these resources (assets).
   * It then counts how many of the provided clips have associated restrictions.
   *
   * @param clips An array of `Clip` objects.
   * Each clip should have a `name` property, and optionally an `original` property with a `name` property.
   * @returns An Observable that emits the number of clips with restrictions.
   */
  getNumberOfRestrictionsFromAssetsIds(clips: Clip[]): Observable<number> {
    const clipsNames = clips.map(c => c.original?.name || c.name);
    const clipsRestrictionResult$ = this.accessManagementService.getListResourceAccessInfoByResources('RESTRICTION', clipsNames, 'ASSET');

    return clipsRestrictionResult$
      .pipe(
        map((resources) => {
          let restrictedNumber = 0;

          for (const clip of clips) {
            //we need to check each clip,
            const assetOrClip = clip.original.name ?? clip.name;
            const hasRestriction = resources.find(r => r.resourceId === assetOrClip);

            if (hasRestriction) restrictedNumber++;
          }

          return restrictedNumber;
        })
      );
  }


}
