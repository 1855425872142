import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, from, ReplaySubject } from 'rxjs';
import { debounceTime, finalize, first, map, switchMap, tap } from 'rxjs/operators';

import { ResourceAccessInfo } from '../../access_management/models/access_management.model';
import { AccessManagementService } from '../../access_management/services/access_management.service';
import { AccessManagementActionsService } from '../../access_management/services/access_management_actions.service';
import { environment } from '../../environments/environment';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { ProgressbarService } from '../../services/progressbar_service';
import { SnackBarService } from '../../services/snackbar_service';
import { UtilsService } from '../../services/utils_service';
import { TableCol } from '../../ui/ui_table.type';

const DEFAULT_PAGE_SIZE = 30;

/** Page size options for list view. */
const PAGE_SIZE_OPTIONS = [DEFAULT_PAGE_SIZE, 50, 100, 200];

const ALL_COLUMNS = [
  'select',
  'title',
  'access',
  'users',
  'requestedBy',
  'updatedAt',
  'actions'
] as const;

const DEFAULT_SORT: Sort = {
  active: 'displayName',
  direction: 'asc',
};

interface RestrictionFilter {
  documentId?: string | undefined | null;
  pageSize?: number;
  moveForward?: boolean;
  textSearch?: string | undefined;
}

type ResourceAccessInfoSelected = ResourceAccessInfo & { selected?: boolean };

@Component({
  selector: 'mam-access-management-list',
  templateUrl: './access-management-list.ng.html',
  styleUrls: ['./access-management-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessManagementList implements OnInit, OnDestroy {
  @HostBinding('class.loading') loading = false;

  @Output() readonly scrollTopNeeded = new EventEmitter();

  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild('paginator') paginator!: MatPaginator;

  readonly search = new FormControl<string>('');
  readonly displayedColumns = ALL_COLUMNS;
  readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  readonly currentPage$ = new BehaviorSubject<Partial<PageEvent> & { isLastPage?: boolean }>(
    {
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    }
  );

  totalItem!: number;
  assetsRestrictions: ResourceAccessInfoSelected[] = [];
  activeSort = DEFAULT_SORT;
  dataSource = new MatTableDataSource<ResourceAccessInfoSelected>([]);

  protected readonly destroyed$ = new ReplaySubject<void>(1);
  lastConfigRequest!: RestrictionFilter | undefined;
  selectedResourcesDocumentId: string[] = [];
  selectAll: boolean = false;

  assetsTurnPublic$:Set<string> = new Set();

  /** Flag to use List views feature. */
  readonly showListView = this.featureService.featureOn('use-table-list-views');

  cols: TableCol[] = [
    {
      key: 'select',
      name: '',
      resizer:false,
      dragger: false,
      headerTpl: 'checkBoxTpl',
      cellTpl: 'checkBoxTpl',
      headerStyle: {
        width: 'var(--table-checkbox-width)',
        minWidth: 'var(--table-checkbox-minwidth)',
        maxWidth: 'var(--table-checkbox-width)',
      },
      cellStyle: {
        textOverflow: 'clip',
      },
      order: 0,
      orderMenu: 0
    },
    {
      key: 'title',
      name: 'Title/File name',
      optioner: true,
      sorter: true,
      dragger: false,
      resizer: true,
      disabled: true,
      cellTpl: 'nameTpl',
      headerStyle: {
        width: '130px',
        minWidth: '100px'
      },
      order: 1,
      orderMenu: 1
    },
    {
      key: 'access',
      name: 'Access',
      optioner: true,
      dragger: true,
      resizer: true,
      sorter: false,
      cellTpl: 'permissionTpl',
      headerStyle: {
        width: '110px',
        minWidth: '90px',
      },
      order: 2,
      orderMenu: 2
    },
    {
      key: 'users',
      name: 'Users',
      optioner: true,
      dragger: true,
      resizer: true,
      sorter: false,
      cellTpl: 'usersTpl',
      headerStyle: {
        width: '180px',
        minWidth: '100px',
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'requestedBy',
      name: 'Requested by',
      optioner: true,
      sorter: false,
      resizer: true,
      dragger: true,
      cellTpl: 'requestedTpl',
      headerStyle: {
        width: '110px',
        minWidth: '90px'
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'updatedBy',
      name: 'Update',
      optioner: true,
      sorter: false,
      resizer: true,
      dragger: true,
      cellTpl: 'updatedTpl',
      headerStyle: {
        width: '110px',
        minWidth: '90px'
      },
      order: 5,
      orderMenu: 5
    },
    {
      key: 'action',
      name: '',
      sticky: true,
      stickyEnd: true,
      dragger: false,
      cellTpl: 'actionTpl',
      colStyle: {
        minWidth: '48px',
        textAlign: 'center'
      },
      headerStyle: {
        width: '48px',
        minWidth: '48px'
      },
      order: 6,
      orderMenu: 6
    }
  ];

  tableId:string;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly snackbar: SnackBarService,
    private readonly progressbar: ProgressbarService,
    private readonly accessManagementService: AccessManagementService,
    readonly featureService: FeatureFlagService,
    private readonly accessManagementActionsService: AccessManagementActionsService,
    readonly utils: UtilsService
  ) {
    this.tableId = environment.tableInfoId['vodStagingTable'];
  }

  ngOnInit(): void {
    this.listenToPageChanges();
    this.listenSearchChanges();
    this.loadingFromActions();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.currentPage$.complete();
  }

  listenSearchChanges() {
    from([this.search.valueChanges, this.search.statusChanges])
      .pipe(
        switchMap((value) => {
          return value;
        }),
        debounceTime(750),
        map(() => {
          this.currentPage$.next({ pageIndex: 0 });
        })
      ).subscribe();
  }

  listenToPageChanges() {
    this.currentPage$.pipe(
      tap(() => this.resetSelected())
    )
      .subscribe((page) => {
        const moveForward = (page?.previousPageIndex ?? 0) < (page?.pageIndex ?? 0);

        if (page.pageIndex === 0) {
          const search = this.search.value || undefined;
          this.getAssetsRestrictions({ documentId: null, pageSize: page.pageSize, textSearch: search });
        } else {
          const fromLast = this.assetsRestrictions[this.assetsRestrictions.length - 1].documentId;
          const untilFirst = this.assetsRestrictions[0].documentId;
          const documentId = moveForward ? fromLast : untilFirst;

          if (page.isLastPage) {
            const restCount = this.totalItem % (page.pageSize ?? DEFAULT_PAGE_SIZE);
            this.getAssetsRestrictions({ documentId, pageSize: restCount, moveForward: true });
          } else {
            this.getAssetsRestrictions({ documentId, pageSize: page.pageSize, moveForward });
          }
        }
      });
  }

  getAssetsRestrictions(config?: RestrictionFilter | undefined) {
    this.lastConfigRequest = config;
    const { documentId = null, pageSize = DEFAULT_PAGE_SIZE, moveForward = true, textSearch = undefined } = config ?? {};
    this.loadingStart();

    this.accessManagementService.getResourceAccessInfoByPagination('RESTRICTION', ['ASSET'], documentId, pageSize, moveForward, textSearch).pipe(
      first(),
      map(({ count, resources }) => {
        if (!resources) {
          this.snackbar.error('Failed to get assets');
          return;
        }

        this.dataSource.data = resources;
        this.totalItem = count;
        this.assetsRestrictions = resources;
      }),
      finalize(() => {
        this.loadingEnd();
        this.cdr.detectChanges();
      }),
    ).subscribe({
        error: () => this.snackbar.error('Error to get assets restriction, try again!')
      }
    );
  }

  private loadingEnd() {
    this.progressbar.hide();
    this.loading = false;
  }

  private loadingStart() {
    this.progressbar.show();
    this.loading = true;
  }

  changePage(event: Partial<PageEvent>, paginator?: MatPaginator) {
    const { pageIndex, pageSize, previousPageIndex } = event;
    const isLastPage = !paginator?.hasNextPage();

    this.scrollTopNeeded.emit();
    this.currentPage$.next({ pageIndex, pageSize, isLastPage, previousPageIndex });
  }


  makePublic(asset: ResourceAccessInfo) {
    if (!asset.documentId) return;

    this.accessManagementActionsService.openPublicDialog(asset.documentId)
      .subscribe({
        next: () => {
          if (asset.documentId && !this.assetsTurnPublic$.has(asset.documentId)) {
            this.assetsTurnPublic$.add(asset.documentId);
          }
        }
      });
  }

  /** Refresh the table and keep the same page */
  updateListAndKeepSamePage() {
    this.getAssetsRestrictions({
      ...this.lastConfigRequest
    });
  }

  addUsers(asset: ResourceAccessInfo) {
    if (!asset.documentId) return;

    this.accessManagementActionsService.openAccessManagement(asset.permissions, asset.documentId)
      .subscribe((result) => {
        if (result) {
          this.updateListAndKeepSamePage();
        }
      });
  }

  compare(a: string, b: string, isAsc: boolean) {
    if (a.toLocaleLowerCase() === b.toLowerCase()) return 0;

    return (a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSort(event: Sort) {
    const dataSorted = this.assetsRestrictions.sort((a, b) => {
      return this.compare(a.title, b.title, event.direction === 'asc');
    });

    if (this.showListView) this.dataSource.data = [...dataSorted];
    else this.dataSource = new MatTableDataSource(dataSorted);
  }

  onChangeAll(event: MatCheckboxChange) {
    if (event.checked) {
      const resourceIds = this.dataSource.data.filter(t => t.resourceType !== 'CUTDOWN').map(d => d.documentId) as string[];
      this.selectedResourcesDocumentId.push(...resourceIds);
    } else {
      this.selectedResourcesDocumentId.length = 0;
    }

    this.assetsRestrictions.forEach((f) => {
      if(f.resourceType !== 'CUTDOWN')
        f.selected = event.checked;
    });

    this.cdr.detectChanges();
  }

  onChangeResource(event: MatCheckboxChange, resource: ResourceAccessInfo) {
    const { documentId } = resource;
    if (!documentId) return;

    if (event.checked) {
      this.selectedResourcesDocumentId.push(documentId);
    } else {
      const indexToRemove = this.selectedResourcesDocumentId.indexOf(documentId);
      if (indexToRemove > -1) {
        this.selectedResourcesDocumentId.splice(indexToRemove, 1);
      }
    }
  }

  addUsersOnSelected() {
    const resourcesSelectedIds = this.assetsRestrictions.filter(a => a.documentId && this.selectedResourcesDocumentId.includes(a.documentId));

    this.accessManagementActionsService.openAddUsersAndMakeRestrictOnSelectedIds(resourcesSelectedIds)
      .subscribe({
        next: () => {
          this.getAssetsRestrictions(this.lastConfigRequest);
        },
        complete: () => {
          this.resetSelected();
        }
      });
  }

  makePublicSelected() {
    this.accessManagementActionsService.openMakePublicSelectedIds(this.selectedResourcesDocumentId)
      .subscribe({
        next: () => {
          this.selectedResourcesDocumentId.forEach((documentId) => {
            if (documentId && !this.assetsTurnPublic$.has(documentId)) {
              this.assetsTurnPublic$.add(documentId);
            }
          });
        },
        complete: () => {
          this.resetSelected();
        }
      });
  }

  private resetSelected() {
    this.selectAll = false;
    this.selectedResourcesDocumentId.length = 0;
    this.assetsRestrictions.forEach((f) => {
      f.selected = false;
    });
  }

  private loadingFromActions() {
    this.accessManagementActionsService.loading$.subscribe((value) => {
      if(value) {
        this.loadingStart();
      } else {
        this.loadingEnd();
      }
    });
  }
}
