import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import {ReplaySubject} from 'rxjs';

import {castExists} from '../asserts/asserts';
import { environment } from '../environments/environment';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {DialogService} from '../services/dialog_service';
import {LocalUpload, LocalUploadStatus, LocalUploadsTrackingService} from '../services/local_uploads_tracking_service';
import { TableUtils } from '../services/table_utils';
import { TableCol, TableSort } from '../ui/ui_table.type';

const ALL_COLUMNS = [
  'fileName',
  'user',
  'fileSize',
  'startTime',
  'duration',
  'status',
  'action'
];
export type Column = typeof ALL_COLUMNS[number];

export type ColumnSortKey = {
  fileName: string;
  fileSize: number;
  startTime: number;
}

/**
 * Local Upload Table.
 */
@Component({
  selector: 'mam-local-upload-table',
  templateUrl: './local_upload_table.ng.html',
  styleUrls: ['./local_upload_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalUploadTable implements OnDestroy {
  @Input() displayDuration: boolean = true;

  cols: TableCol[] = [
    {
      key: 'icon',
      name: '',
      headerTpl: 'iconTpl',
      cellTpl: 'iconTpl',
      resizer:false,
      dragger: false,
      stickyStart: true,
      headerStyle: {
        width: 'var(--table-checkbox-width)',
        minWidth: 'var(--table-checkbox-minwidth)',
        maxWidth: 'var(--table-checkbox-width)',
      },
      cellStyle: {
        paddingLeft: '8px',
      },
      order: 0,
      orderMenu: 0
    },
    {
      key: 'fileName',
      name: 'File name',
      sorter: true,
      optioner: true,
      dragger: false,
      resizer: true,
      disabled: true,
      cellTpl: 'titleTpl',
      headerStyle: {
        width: '280px',
        minWidth: '200px',
      },
      order: 1,
      orderMenu: 1
    },
    {
      key: 'user',
      name: 'Uploaded by',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'userTpl',
      pipe: 'titlecase',
      headerStyle: {
        width: '120px',
        minWidth: '100px',
      },
      order: 2,
      orderMenu: 2
    },
    {
      key: 'fileSize',
      name: 'Size',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'sizeTpl',
      headerStyle: {
        width: '120px',
        minWidth: '100px',
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'startTime',
			name: 'Start Time',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'startTpl',
			headerStyle: {
				width: '180px',
				minWidth: '90px',
			},
      order: 4,
      orderMenu: 4
    },
    {
      key: 'duration',
      name: 'Duration',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'durationTpl',
      headerStyle: {
        width: '100px',
        minWidth: '80px',
      },
      order: 5,
      orderMenu: 5
    },
    {
      key: 'status',
      name: 'Status',
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'statusTpl',
      headerStyle: {
        minWidth: '100px',
        width: '130px',
      },
      order: 6,
      orderMenu: 6
    },
    {
      key: 'action',
      name: '',
      optioner: false,
      dragger: false,
      resizer: false,
      cellTpl: 'actionTpl',
      headerStyle: {
        minWidth: '50px',
        width: '50px',
      },
      order: 7,
      orderMenu: 7
    }
  ];

  /** Data source for the table. */
  readonly localUploads$ = this.localUploadsTrackingService.localUploads$;
  readonly columnsToDisplay: string[] = ALL_COLUMNS;
  readonly localUploadStatus = LocalUploadStatus;

  /** Flag to show local uploads' source column. */
  readonly showLocalUploadsSource = this.featureService.featureOn('show-user-information');

  /** Flag to use List views feature. */
  readonly showListView = this.featureService.featureOn('use-table-list-views');

  activeSort: TableSort = {
    active: 'fileName',
    direction: 'asc'
  };
  tableId: string = environment.tableInfoId['localUploads'];

  constructor(
      private readonly localUploadsTrackingService: LocalUploadsTrackingService,
      private readonly dialogService: DialogService,
      private readonly featureService: FeatureFlagService,
      private readonly tableUtils: TableUtils
  ) {}

  onCancelClicked(upload: LocalUpload) {
    if (upload.status$.value !== LocalUploadStatus.UPLOADING) {
      this.localUploadsTrackingService.remove(upload);
      return;
    }

    this.dialogService
        .showConfirmation({
          title: 'Abort Upload',
          question: `Do you want to abort upload ${upload.fileName}?`,
        })
        .subscribe(result => {
          if (result) {
            // Confirmed cancellation.
            this.localUploadsTrackingService.remove(upload);
          }
        });
  }

  retryOrResumeOneFile(event: Event, upload: LocalUpload) {
    const target = event.target as HTMLInputElement;
    this.localUploadsTrackingService.retryOrResumeOneUpload(
      castExists(target.files)[0], upload);
    // No matter it succeeds or not, need to reset the input value.
    target.value = '';
  }

  onSortByField(rows: LocalUpload[]) {
    this.localUploads$.next(rows);
  }

  onSort(sort: TableSort) {
    this.activeSort = sort ;
    const rows = this.localUploads$.value;
    const key = sort.active;
    const asc = this.activeSort.direction === 'asc';

    const sortedRows = rows.sort((a, b) => {
      const aValue = a[key as keyof ColumnSortKey];
      const bValue = b[key as keyof ColumnSortKey];
      return (aValue < bValue ? (asc ? -1 : 1) : (asc ? 1 : -1));
    });
    this.localUploads$.next([...sortedRows]);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackByUpload(index: number, upload: LocalUpload) {
    return upload.fileName;
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
}
