import {Inject, Injectable, InjectionToken} from '@angular/core';
import {from, iif, Observable, of, timer} from 'rxjs';
import {map, mergeMap, switchMap, toArray} from 'rxjs/operators';

import {assertTruthy, assumeExhaustiveAllowing } from 'asserts/asserts';

import {hasAdminRightsMissing} from '../error_service/error_response';

import { AssetCopy } from './asset_service';

/**
 * Default number of concurrent api calls sent. Used for requests like creating
 * a clip in multiple clip bins.
 */
export const DEFAULT_CONCURRENT_REQUEST_NUMBER = 10;

/** Injection token indicating whether the user is protractor e2e tests. */
export const IS_E2E_TESTING =
    new InjectionToken<boolean>('User is Protractor e2e', {
      providedIn: 'root',
      factory: () => false,
    });

/** Provides utilities that can behave differently depending on context. */
@Injectable({providedIn: 'root'})
export class UtilsService {
  constructor(@Inject(IS_E2E_TESTING) private readonly isE2e: boolean) {}

  /**
   * Returns a timer observable when not under e2e, and a single emission
   * otherwise, so that it does not block Protractor waiting for the timer
   * observable to complete.
   */
  timer(interval: number, dueTime = 0) {
    return this.isE2e ? of(0) : timer(dueTime, interval);
  }

  /**
   * Returns the last part of a path separated by "/".
   * Example: 'some/long/path/name.ext' => 'name.ext'
   */
  lastPart(path = '') {
    return path.slice(path.lastIndexOf('/') + 1);
  }

  /**
   * Returns the last part of a path separated by "/" split into name and
   * extension. Example: 'some/long/path/name.ext' => 'name', 'ext'
   */
  getNameAndExtension(path: string) {
    const lastPart = this.lastPart(path);
    const dotIndex = lastPart.lastIndexOf('.');
    if (dotIndex < 0) {
      return {name: lastPart, ext: ''};
    }

    return {
      name: lastPart.slice(0, dotIndex),
      ext: lastPart.slice(dotIndex + 1)
    };
  }

  /**
   * Type-safe Array.prototype.includes allowing for more-generic item search.
   * See https://miyauchi.dev/posts/typesafe-array-element
   */
  includes<T extends U, U>(array: readonly T[], item: U): item is T {
    return array.includes(item as T);
  }

  /** Returns a word in a singular or plural form depending on the count. */
  pluralize(count: number, singular: string, plural: string) {
    return count === 1 ? singular : plural;
  }

  /**
   * Scrolls the container so that the selected item (found using a CSS
   * selector) is visible in the middle of the viewport.
   */
  scrollToActive(container: HTMLElement|undefined, itemSelector: string) {
    const selectedElem = container?.querySelector<HTMLElement>(itemSelector);
    if (!container || !selectedElem) return;

    const containerHeight = container.offsetHeight;
    const itemHeight = selectedElem.offsetHeight;
    const itemTop = selectedElem.offsetTop - container.offsetTop;
    const currentScrollTop = container.scrollTop;

    const isItemBelowView =
        currentScrollTop < itemTop + itemHeight - containerHeight;
    const isItemAboveView = currentScrollTop > itemTop;

    // Scroll to the middle of the container.
    const newScrollTop = itemTop - (containerHeight / 2) + itemHeight;

    // Only use smooth scroll if the scrolling distance is less than one full
    // container height.
    const smooth = Math.abs(newScrollTop - currentScrollTop) < containerHeight;

    if (isItemBelowView || isItemAboveView) {
      container.scrollTo({
        top: itemTop - (containerHeight / 2) + itemHeight,
        behavior: smooth ? 'smooth' : 'auto',
      });
    }
  }

  /**
   * Returns a lowercase key if a keyboard shortcut was pressed. Ignore events
   * while the focus is on an input, of if CTRL or CMD keys are held down (for
   * instance if a user is copying text with CMD+C).
   */
  getKeyboardShortcut(event: KeyboardEvent) {
    assertTruthy(
        event.type === 'keydown', `Expected keydown event: ${event.type}`);
    const target = event.target as HTMLElement | null;

    // Ignore shortcuts while user is typing on any input.
    if (target?.tagName === 'INPUT' || target?.tagName === 'TEXTAREA' ||
        target?.contentEditable === 'true') {
      return undefined;
    }

    // Ignore shortcuts if a user is holding Ctrl or Command.
    if (event.ctrlKey || event.metaKey) {
      return undefined;
    }

    const key = event.key.toLowerCase();
    return (event.shiftKey) ? `shift+${key}` : key;
  }

  /**
   * Executes specified async action for each provided item with default
   * concurrency of 10 and returns observable of responses array.
   *
   *  @param items The items for which to execute the action
   *  @param action The action to execute on each item
   *  @param options Execution options
   *  @param options.abortIfNonAdmin If `abortIfNonAdmin` is `true`, the first action is
   *     executed separately and allows to abort processing more if it fails due
   *     to missing admin rights.
   */
  batchApiCalls<T, Resp>(
      items: T[],
      action: (item: T) => Observable<Resp>,
      options?: {abortIfNonAdmin?: boolean}
  ): Observable<Resp[]> {
    if (!options?.abortIfNonAdmin) {
      return this.executeInParallel(items, action);
    }

    // Case admin-rights checked first before making more API calls.
    const [firstItem, ...otherItems] = items;
    const otherResults$ = this.executeInParallel(otherItems, action);

    return action(firstItem).pipe(switchMap(firstResult => {
      return iif(
          // If the first call failed due to missing admin rights...
          () => hasAdminRightsMissing([firstResult]),
          // then return that first error only...
          of([firstResult]),
          // otherwise batch-execute other calls and concat responses.
          otherResults$.pipe(
              map(otherResults => [firstResult, ...otherResults])),
      );
    }));
  }

  /**
   * Escapes html characters. E.g. `<` becomes `&lt;`, `&` becomes `&amp;`, etc.
   *
   * @param value The string to escape
   * @param convertNewLinesToBr - when set to true, newlines will be replaced
   *     with `<br>`.
   */
  htmlEscape(value: string, convertNewLinesToBr = false) {
    const escaped = this.htmlEscapeInternal(value);
    if (convertNewLinesToBr) return this.convertNewLinesToBr(escaped);
    return escaped;
  }

  private htmlEscapeInternal(str: string) {
    return str.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        // eslint-disable-next-line no-control-regex
        .replace(/\u0000/g, '&#0;');
  }

  private convertNewLinesToBr(str: string) {
    return str.replace(/(\r\n|\r|\n)/g, '<br>');
  }

  /**
   * Executes specified async action for each provided item with default
   * concurrency of 10 and returns observable of responses array.
   */
  private executeInParallel<T, Resp>(
      items: T[], action: (item: T) => Observable<Resp>) {
    // For each item...
    return from(items).pipe(
        mergeMap(i => action(i), DEFAULT_CONCURRENT_REQUEST_NUMBER),
        toArray(),
    );
  }

  formatStatus(cutdown: Partial<AssetCopy>) {
      switch (cutdown?.state) {
        case 'STATE_DRAFT':
          return 'Pending Approval';
        case 'STATE_ERROR':
          return 'Failed';
        case 'STATE_PFR_PROCESSING':
        case 'STATE_PFR_SUCCEEDED':
          return 'In Progress';
        case 'STATE_QUEUED':
          return 'Queued';
        case 'STATE_VOD_PROCESSING':
          return 'VoD Processing';
        case 'STATE_VOD_READY':
          return 'Complete';
        default:
          assumeExhaustiveAllowing<'STATE_UNSPECIFIED' | undefined>(cutdown.state);
          return '';
      }
    }
}

/** Extracts interface from a type. Ignores non-public members. */
export type Interface<T> = Pick<T, keyof T>;

/**
 * Same as Required<T>, but makes all properties in T required recursively
 */
export type RequiredRecursively<T> = {
  [P in keyof T] -?: RequiredRecursively<T[P]>;
};

export interface SerializableModel<T> {
  /**
   * Auto-magically called when the object is serialized via JSON.stringify
   * which is done internally by Angular's HttpClient Provide serialization
   *
   * Allows to fine-tune what will be sent to the api when used in request body.
   */
  toJSON(): T;
}
