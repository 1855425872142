<div class="folder-header">
    <span class="folder-name">
        <span (click)="onFolderCrumbClick(); $event.preventDefault();" class="folder-crumb">
            <span> Home </span>
        </span>
        @if (resourceContent.parent) {
        <mat-icon>chevron_right</mat-icon>
        @if (resourceContent.parent.breadcrumb.length) { @for (crumb of resourceContent.parent.breadcrumb; track $index)
        {
        <span class="folder-crumb" (click)="onFolderCrumbClick(crumb.id!)">
            <span>{{ crumb.name }}</span>
        </span>
        <mat-icon>chevron_right</mat-icon>
        } }
        <p>{{ resourceContent.parent.name }}</p>
        }
    </span>
    <div class="content-wrapper">
      @if (!resultsLoading) {
        <div
          class="content-container"
          [class.folders_grid]="hasContent"
          [class.empty-content]="!hasContent"
          [class.folders_grid--list]="displayMode === 'list'"
        >
          @if (hasContent) {
            @if (displayMode === 'list') {
              <div style="margin-bottom: 24px">
                  <mam-cbo-list-display
                      *ngIf="displayMode === 'list'"
                      [result]="content"
                      [isNested]="true"
                      [startAfter]="startAfter"
                      [parentId]="folderId"
                      [owner]="userEmail"
                  ></mam-cbo-list-display>
              </div>
            } @else {
              @for (folder of content; track trackBy) {
                <div class="folders_grid_card_container">
                  @if (isClipBin(folder)) {
                    <div class="clipbins_checkbox" *featureFlagOn="'use-clips-selection'">
                        <mat-checkbox
                            color="primary"
                            (change)="$event? toggleSelect(folder): null"
                            [checked]="selectionService.isSelected(folder) || selectionService.selectAll$()"
                        >
                        </mat-checkbox>
                    </div>
                    <mam-clip-bin-preview
                      [bin]="folder"
                      [checkboxPresent]="true"
                      [isAdmin]="isAdmin"
                      [binsViewMode]="displayMode"
                      class="clipbins_grid__card"
                  ></mam-clip-bin-preview>
                  } @else {
                    <div class="clipbins_grid_card_container folder_grid_card_container">
                        <div class="clipbins_checkbox" *featureFlagOn="'use-clips-selection'">
                            <mat-checkbox
                                color="primary"
                                (change)="$event? toggleSelect(folder): null"
                                [checked]="selectionService.isSelected(folder) || selectionService.selectAll$()"
                            >
                            </mat-checkbox>
                        </div>
                        <mam-clip-bin-folder-preview
                            class="folders_grid__card"
                            [isAdmin]="isAdmin"
                            [userEmail]="userEmail"
                            [showAllFolders]="showAllFolders"
                            [folder]="folder"
                            [viewMode]="displayMode"
                        >
                        </mam-clip-bin-folder-preview>
                    </div>
                  }
                </div>
              }
            }
          } @else {
            @if (searchTerm) {
              <mam-empty-assets-card [assetsType]="'content'" [hasSearchText]="true" [helperText]="true">
                  <p>Your search did not match any folder.</p>
              </mam-empty-assets-card>
            } @else {
              <mam-empty-assets-card [assetsType]="'content'" [hasSearchText]="false" [helperText]="true">
                <p>
                  Use the <span><mat-icon>add</mat-icon> New</span> button in the top left to create bins and folders
                  and start adding.
                </p>
              </mam-empty-assets-card>
            }
          }
        </div>
      } @else {
        <!-- Loading -->
        <mam-empty-assets-card [loading]="true"></mam-empty-assets-card>
      }
    </div>
</div>
