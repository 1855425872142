import { DisplayMode } from '../services/vod_search_service';

/**
 * Media breakpoints that affect live landing.
 */
export enum Breakpoint {
    SMALLEST = '(max-width: 720px)',
    SMALL = '(max-width: 960px)',
    MEDIUM = '(max-width: 1200px)',
    LARGE = '(max-width: 1600px)',
    LARGEST = '(max-width: 9999px)' // fallback
}

export type DisplayModePagination = {
    displayMode: DisplayMode;
    limit: number;
};

export const getDisplayModePagination = (displayMode: DisplayMode, limit: number): DisplayModePagination => ({
    displayMode,
    limit
});

export const getPaginationBreakpoint = (
    breakpoint: Breakpoint,
    ...DisplayModePagination: Array<DisplayModePagination>
): [Breakpoint, Array<DisplayModePagination>] => [breakpoint, DisplayModePagination];
// it is very important that the breakpoints here are ordered from largest to smallest
export const BINS_PAGINATION_BREAKPOINTS = new Map<Breakpoint, Array<DisplayModePagination>>([
    getPaginationBreakpoint(
        Breakpoint.LARGEST,
        getDisplayModePagination(DisplayMode.GRID, 12),
        getDisplayModePagination(DisplayMode.LIST, 10)
    ),
    getPaginationBreakpoint(
        Breakpoint.LARGE,
        getDisplayModePagination(DisplayMode.GRID, 10),
        getDisplayModePagination(DisplayMode.LIST, 10)
    ),
    getPaginationBreakpoint(
        Breakpoint.MEDIUM,
        getDisplayModePagination(DisplayMode.GRID, 8),
        getDisplayModePagination(DisplayMode.LIST, 8)
    ),
    getPaginationBreakpoint(
        Breakpoint.SMALL,
        getDisplayModePagination(DisplayMode.GRID, 6),
        getDisplayModePagination(DisplayMode.LIST, 6)
    ),
    getPaginationBreakpoint(
        Breakpoint.SMALLEST,
        getDisplayModePagination(DisplayMode.GRID, 4),
        getDisplayModePagination(DisplayMode.LIST, 4)
    )
]);
