<h1 mat-dialog-title>Rename Clip Bin</h1>
<div mat-dialog-content>
  <label>Clip bin name</label>
<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" mat-dialog-content>
  @if (hasNotAllowedCharacters$ | async) {
    <p class="error">Special characters !&#64;#$%^&*() are not allowed.</p>
  }
  @if (wordNotAllowed$ | async) {
    <p class="error">The word 'Home' is not allowed as a folder name.</p>
  }
</div>
  <mat-form-field class="dialog__input--spacing" appearance="outline" subscriptSizing="dynamic">
    <input
        #input
        matInput
        cdkFocusInitial
        aria-label="Name"
        placeholder="Name"
        autocomplete="off"
        value='{{data.title}}'
        (input)="onInput()"
        (keyup.enter)="dialogRef.close(input.value)"
        [disabled]="isDisabledInput()"
        >
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel clip bin rename"
      [mam-firebase-resource]="data.name">
    Cancel
  </button>
  <button
      mat-button
      [mat-dialog-close]="input.value"
      [disabled]="isDisabledButton()"
      class="primary"
      id="SaveButton"
      #primary
      mam-firebase-ga="Confirm clip bin rename"
      [mam-firebase-resource]="data.name">
    Save
  </button>
</div>
