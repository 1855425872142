import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { ResourceService } from '../landing/clip-bin-section/service/resource.service';
import { BinSectionContent, BinService } from '../services/bin.service';
import { SnackBarService } from '../services/snackbar_service';
import { StateService } from '../services/state_service';
import { DisplayMode } from '../services/vod_search_service';
import { SharedLinkClipBinService } from '../shared_clipbin/services/shared_link_clipbin.service';
import { preventSpecialChars } from '../utils/form.utils';

/** Clipbin rename dialog */
@Component({
    selector: 'mam-rename-bin-dialog',
    templateUrl: './rename_bin_dialog.ng.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            @import './shared/mixins';

            :host {
                max-width: 502px;
                width: 70vw;
                display: block;
                .error {
                    color: red;
                    font-size: 12px;
                    margin-top: 4px;
                }

                @include breakpoint-md-max {
                    max-width: 95vw;
                    width: 65vw;
                }

                @include breakpoint-sm-max {
                    width: initial;
                    max-width: initial;
                }
            }
        `
    ]
})
export class RenameBinDialog implements OnInit {
    static readonly dialogOptions = { hasBackdrop: true };
    @ViewChild('input') input!: ElementRef;
    isBusy$ = new BehaviorSubject<boolean>(false);
    hasNotAllowedCharacters$ = new BehaviorSubject<boolean>(false);
    wordNotAllowed$ = new BehaviorSubject<boolean>(false);

    constructor(
        readonly dialogRef: MatDialogRef<RenameBinDialog>,
        private readonly snackBar: SnackBarService,
        private readonly sharedLinkClipBinService: SharedLinkClipBinService,
        private readonly stateService: StateService,
        private readonly resourceService: ResourceService,
        private readonly binService: BinService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            name: string;
        }
    ) {}

    ngOnInit() {
      this.subscribeAfterCloseAction(this.dialogRef);
    }

    onInput() {
      const inputValue = this.input.nativeElement.value;
      const { hasNotAllowedCharacters, wordNotAllowed } = preventSpecialChars(inputValue);
      this.hasNotAllowedCharacters$.next(hasNotAllowedCharacters);
      this.wordNotAllowed$.next(wordNotAllowed);
    }

    private subscribeAfterCloseAction(dialogRef: MatDialogRef<RenameBinDialog>) {
      dialogRef.afterClosed().subscribe((renamedTitle?: string) => {

        if (!renamedTitle || renamedTitle === this.data.title || this.isDisabledButton()) return;
        this.isBusy$.next(true);

        this.resourceService.renameClipBin(encodeURIComponent(this.data.name), renamedTitle).subscribe({
          next: () => {
            this.dialogRef.close(renamedTitle);
            if (this.binService.displayMode$.value === DisplayMode.GRID) {
              this.resourceService.isLoading$.next(true);
            }

            this.updateClipBinSharedLinkTitle(encodeURIComponent(this.data.name), renamedTitle);
            this.snackBar.message('Clip bin has been renamed successfully.');

            if (this.stateService.searchModeSelected$.value !== BinSectionContent.BIN) {
              this.stateService.searchModeSelected$.next(BinSectionContent.BIN);
            }

            if (this.binService.displayMode$.value === DisplayMode.GRID) {
              this.resourceService.updateLocalResource(this.data.name, renamedTitle);
            }
          },
          error: (error) => {
            console.error('Clip bin could not be renamed.', error);
            this.snackBar.error('Clip bin could not be renamed.', undefined, error);
          },
          complete: () => {
            this.isBusy$.next(false);
            if (this.binService.displayMode$.value === DisplayMode.GRID) {
              this.resourceService.isLoading$.next(false);
            }
          }
        });
      });
    }

    isDisabledInput() {
    return this.isBusy$.value;
    }

    isDisabledButton(): boolean {
        if (!this.input || !this.input.nativeElement) return false;
        return this.input.nativeElement.value.trim().length === 0 || this.isBusy$.value || this.hasNotAllowedCharacters$.value || this.wordNotAllowed$.value;
    }

    private updateClipBinSharedLinkTitle(name: string, newTitle: string) {
        this.sharedLinkClipBinService.updateClipBinSharedLinkTitle(name, newTitle);
    }
}
