import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, take } from 'rxjs';

import { DialogResourceRefreshService } from '../../landing/clip-bin-section/service/dialog-resource-refresh-service';
import { ResourceTypes } from '../../landing/clip-bin-section/service/resource-types';
import { ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { SelectionService } from '../../landing/clip-bin-section/service/selection.service';
import { BinSectionContent, BinSectionContentType } from '../../services/bin.service';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';

export interface FolderItemRef {
    id: string; // folder id
    queryableName: string; //property for search
    displayName: string; //folder name or clip bin displayName
    refType: 'folder' | 'clipbin'; // type of reference
    binRefName?: string; // id (name) of the bin
    contentType?: BinSectionContentType;
    createdBy?: string; // user who created
    createdByName?: string; // name of the user who created
    createdAt?: Date; //date of creation
    updatedBy?: string; // for name changes or undeletes in the future
    updatedAt?: Date; // date of chage
    parent?: string | null; //id of parent folder
    items?: number; // amount of folders, bins or clips contained in the ref
    active?: boolean; // variable for soft deleting
    name?: string; // name of the
    level?: number;
    directChildrenCount?: number;
    breadcrumbPath?: string;
}

/** Clipbin deletion dialog */
@Component({
    selector: 'mam-delete-folder-dialog',
    templateUrl: './clipbin_folder_delete_dialog.ng.html',
    styleUrls: ['./clipbin_folder_delete_dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinFolderDeleteDialog implements OnInit, OnDestroy {
    static readonly dialogOptions = {
        hasBackdrop: true
    };
    contextId = window.location.pathname.split('/').pop() || '';
    hasChildrenError$ = new BehaviorSubject<boolean>(false);
    /** Handles on-destroy Subject, used for unsubscribing. */
    private readonly destroyed$ = new Subject<void>();

    constructor(
        readonly dialogRef: MatDialogRef<ClipBinFolderDeleteDialog>,
        private readonly snackBar: SnackBarService,
        private readonly resourceService: ResourceService,
        private readonly selectionService: SelectionService,
        private readonly stateService: StateService,
        private dialogRefreshService: DialogResourceRefreshService,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: FolderItemRef
    ) {}

    ngOnInit() {
        if (this.data && this.data.directChildrenCount) this.hasChildrenError$.next(this.data.directChildrenCount > 0);
        if(this.data && Array.isArray(this.data)) {
          this.data.forEach(item => {
            if (item.directChildrenCount) this.hasChildrenError$.next(item.directChildrenCount > 0);
          });
        }

        this.dialogRef.afterClosed().subscribe((deletionConfirmed) => {
            if (!deletionConfirmed) return;
            if(Array.isArray(this.data)) {
              const foldersIds:string[] = [];
              this.data.forEach(folder => foldersIds.push(folder.id));
              this.selectionService
              .bulkDeleteFolders(foldersIds)
              .pipe(take(1))
              .subscribe({
                  next: () => {
                      this.snackBar.message('Folder has been deleted successfully.');
                      this.stateService.searchModeSelected$.next(BinSectionContent.FOLDER);
                      this.dialogRefreshService.reloadResourcesAndClose(this.dialogRef, this.data);
                      this.cdr.markForCheck();
                  },
                  error: (error) => {
                      this.snackBar.error('Folder could not be deleted.', undefined, error);
                  }
              });
            } else {
              this.resourceService
                .deleteResource(ResourceTypes.FOLDER, ResourceTypes.FOLDER, this.data?.id, this.contextId)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        //TODO: remove references in folder
                        this.snackBar.message('Folder has been deleted successfully.');
                        this.stateService.searchModeSelected$.next(BinSectionContent.FOLDER);
                        this.dialogRefreshService.reloadResourcesAndClose(this.dialogRef, this.data);
                        this.cdr.markForCheck();
                    },
                    error: (error) => {
                        this.snackBar.error('Folder could not be deleted.', undefined, error);
                        this.cdr.markForCheck();
                    }
                });
            }
        });
    }

    isDeleteDisabled() {
        return this.hasChildrenError$.value;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
