import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {EMPTY} from 'rxjs';
import {concatMap, switchMap, take} from 'rxjs/operators';

import {AccessDetailsAssetDialog} from 'access_management/component/access-details-asset-dialog/access-details-asset-dialog.component';
import {PermissionDetail, ResourceAccessInfo, ResourceAccessUser } from 'access_management/models/access_management.model';
import {assertTruthy} from 'asserts/asserts';
import {AuthService} from 'auth/auth_service';

import {AccessManagementActionsService, RestrictionResult} from '../access_management/services/access_management_actions.service';
import {hasAdminRightsMissing, isErrorResponse} from '../error_service/error_response';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {PAGE_CONTEXT_TOKEN} from '../firebase/firebase_analytics_service';
import {FirebaseFirestoreDataService, IASEvent} from '../firebase/firebase_firestore_data_service';
import {FirestoreIASEventHelper} from '../firebase/firebase_firestore_ias_event_helper';
import {Asset, AssetService, Clip, ClipMarking, Original} from '../services/asset_service';
import {DialogService} from '../services/dialog_service';
import {MediaCacheService} from '../services/media_cache_service';
import {DELETED_ASSETS_ROUTE_PATH} from '../services/routing_path_service';
import {QuerySegment, SearchMode} from '../services/search_service';
import {SnackBarService} from '../services/snackbar_service';
import { StateService } from '../services/state_service';
import {PaddedSegment} from '../services/vod_search_service';

import {AddClipDialog, AddClipDialogInputData, AddClipDialogOutputData} from './add_clip_dialog';
import {ExportAssetDialog, ExportAssetDialogInputData} from './export_asset_dialog';
import {GetLinkForVideoDialog} from './get_link_for_video_dialog';

/**
 * Give feedback button that sends feedback to
 * https://www.google.com/tools/feedback
 */
@Component({
  selector: 'mam-asset-extra-actions',
  templateUrl: './asset_extra_actions.ng.html',
  styleUrls: ['./asset_extra_actions.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetExtraActions implements OnInit {
  @Input() asset!: Asset;

  @Output() updateRestriction = new EventEmitter<Partial<ResourceAccessInfo> | undefined>();
  /**
   * Optional additional properties to be saved when creating a new shared
   * link, that can be retrieved later from the public SharedLink page.
   */
  @Input() sharingAdditionalProperties?: Record<string, string>;

  /**
   * Optional query context to enable providing feedback and limiting a new
   * clip from this segment times.
   */
  @Input() querySegment?: QuerySegment;

  /**
   * Optional query context to enable providing feedback and limiting a new
   * clip from this segment padded times (i.e., the API segment + extra time
   * before and after that are visible in search card results).
   */
  @Input() paddedSegment?: PaddedSegment;

  /**
   * Used to track the anchor element for search feedback triggering button.
   */
  @Input() anchor?: Element;

  /**
   * When set to false will lose menu options to delete and export the asset.
   * Only VoD original assets can be deleted.
   */
  @Input() enableFullMenu = true;

  /**
   * Permissions for the asset (Access Management)
   */
  @Input() assetPermissions: PermissionDetail[] | undefined = undefined;

  readonly SearchMode = SearchMode;

  readonly videoDisabledMessage = 'Disabled because the asset is being deleted';

  isAdmin: boolean = false;

  @Input() documentId!: string;

  constructor(
    private readonly assetService: AssetService,
    private readonly mediaCache: MediaCacheService,
    private readonly dialog: MatDialog,
    private readonly dialogService: DialogService,
    private readonly snackBar: SnackBarService,
    private readonly router: Router,
    private readonly featureFlag: FeatureFlagService,
    private readonly cdr: ChangeDetectorRef,
    private readonly iasEventHelper: FirestoreIASEventHelper,
    private readonly dataService: FirebaseFirestoreDataService,
    private readonly authService: AuthService,
    private readonly accessManagementActionsService: AccessManagementActionsService,
    private readonly stateService: StateService
  ) {
    this.isAdmin = this.authService.isAdmin;
  }

  getRestrictionAndEmitUpdate() {
    const clipOrAsset = this.asset.original?.name ?? this.asset.name;
    this.accessManagementActionsService.getRestriction(clipOrAsset)
      .pipe(take(1))
      .subscribe((value: RestrictionResult) => {
        this.documentId = value?.documentId ?? '';
        this.assetPermissions = value?.restriction === 'public' ? undefined : value?.permissions;
        this.updateRestriction.emit({ documentId: this.documentId, permissions: this.assetPermissions });
        this.cdr.detectChanges();
      });
  }

  getRestriction() {
    const clipOrAsset = this.asset.original?.name ?? this.asset.name;
    this.accessManagementActionsService.getRestriction(clipOrAsset)
      .pipe(take(1))
      .subscribe((value: RestrictionResult) => {
        this.documentId = value?.documentId ?? '';
        this.assetPermissions = value?.restriction === 'public' ? undefined : value?.permissions;
      });
  }

  ngOnInit() {
    assertTruthy(
      this.asset,
      '<mam-asset-extra-actions-asset> requires property "asset"');

    this.watchAssetRestriction();
  }

  watchAssetRestriction() {
    if (this.featureFlag.featureOff('enable-access-management')) return;

    this.stateService.restrictAssetUpdateFromMetadata$
      .subscribe({
        next: (update) => {
          if (update) {
            this.getRestriction();
          }
        }
      });
  }

  get segment() {
    return this.paddedSegment || this.querySegment;
  }

  isVideoShareable() {
    return this.assetService.isVideoShareable(this.asset);
  }

  isVideoAbleToApplyRestrictions() {
    return this.authService.isAdmin;
  }

  isVideoDeletable() {
    // Asset deletion may be hidden in some instances of AssetExtraActions
    if (!this.enableFullMenu) return false;
    // Clips are currently not supported by AssetExtraActions
    if (this.asset.original) return false;
    // Live assets deletion is pending final implementation on cutdowns.
    if (this.asset.isLive) return false;

    return true;
  }

  isVideoExportable() {
    // Clip export is hidden from assetExtraActions.
    return this.featureFlag.featureOn('use-original-asset-export') &&
      !this.asset.isLive && !this.asset.original && this.enableFullMenu;
  }

  async shareVideo() {
    const restricted = await this.accessManagementActionsService.checkRestrictedAndUpdatePermissionsForSelected(this.asset);
    if (restricted) {
      this.snackBar.error('Action disabled due to restricted asset.');
      return;
    }

    if (!this.isVideoShareable()) return;

    this.dialog.open(
      GetLinkForVideoDialog, GetLinkForVideoDialog.getDialogOptions({
        asset: this.asset,
        additionalProperties: this.sharingAdditionalProperties,
      }));
  }

  async addUsersToRestrictedAsset() {
    if (!this.assetPermissions) {
      const restricted = await this.accessManagementActionsService.checkRestrictedAndUpdatePermissionsForSelected(this.asset);
      if (restricted) {
        this.snackBar.error('This asset is already restricted.');
        return;
      }
    }

    if (!this.isVideoAbleToApplyRestrictions()) return;

    const dialogToOpen = this.assetPermissions
      ? this.accessManagementActionsService.openAccessManagement(this.assetPermissions ?? [], this.documentId)
      : this.accessManagementActionsService.openRestrictDialog(this.asset).pipe(
        concatMap((document) => this.accessManagementActionsService.openAccessManagement([], document?.id ?? ''))
      );

    dialogToOpen.subscribe(() => this.getRestrictionAndEmitUpdate());
  }

  async makeAssetPublic(){
    const restricted = this.asset && await this.accessManagementActionsService.checkRestrictedAndUpdatePermissionsForSelected(this.asset);
    if (!restricted) {
      this.snackBar.error('This asset is already public.');
      return;
    }

    if (!this.isVideoAbleToApplyRestrictions()) return;

    this.accessManagementActionsService.openPublicDialog(this.documentId ?? this.asset.permissionsDocumentId as string)
      .subscribe({
        next: () => this.getRestrictionAndEmitUpdate()
      });
  }

  async openAddClipDialog() {
    const noAccess = await this.accessManagementActionsService.checkNoAccessAndUpdatePermissionsForSelected(this.asset);
    if (noAccess) {
      this.snackBar.error('Action disabled due to restricted asset.');
      return;
    }

    let clipMarking: ClipMarking | undefined = undefined;
    if (this.segment) {
      const markIn =
        this.paddedSegment?.paddedStartTime || this.segment.startTime;
      const markOut = this.paddedSegment?.paddedEndTime || this.segment.endTime;
      clipMarking = {
        markIn,
        markOut,
      };
    }

    this.dialog
      .open<AddClipDialog, AddClipDialogInputData, AddClipDialogOutputData>(
        AddClipDialog,
        AddClipDialog.getDialogOptions({ asset: this.asset, clipMarking }, '550px !important'));
  }

  async openDeleteAssetDialog() {
    const asset = this.asset;
    assertTruthy(
      !asset.original,
      'AssetExtraActions.openDeleteAssetDialog: original asset expected.');

    const noAccess = await this.accessManagementActionsService.checkNoAccessAndUpdatePermissionsForSelected(this.asset);
    if (noAccess) {
      this.snackBar.error('Action disabled due to restricted asset.');
      return;
    }

    let question = '';
    let extraChoice: [string, boolean] | undefined = undefined;
    if (!asset.isLive) {
      question =
        'Its clips will be permanently deleted. Are you sure you want to continue?';
      extraChoice = ['Also purge on-prem file', true];
    } else {
      question =
        'This live asset and its clips will be permanently deleted. Are you sure you want to continue?';
    }

    const confirmed$ = this.dialogService.showConfirmation({
      title: 'Delete asset',
      question,
      extraChoice,
      primaryButtonText: 'Delete',
    });

    confirmed$
      .pipe(switchMap(confirmed => {
        if (!confirmed) return EMPTY;
        const alsoPurgeFile = Boolean(confirmed.extraChoice);
        return this.mediaCache.purgeAndDelete(asset, !alsoPurgeFile);
      }))
      .subscribe(response => {
        this.cdr.markForCheck();

        if (hasAdminRightsMissing([response])) {
          this.snackBar.error(
            'Asset deletion is reserved for administrators.');
          return;
        }

        this.storeIASEventForDeleteAsset(asset);
        this.storeIASDeletedAsset(asset);

        if (isErrorResponse(response)) {
          this.snackBar.error({
            message: 'Asset deletion failed.',
            details: response.message,
          });
        } else if (response.isDeleted) {
          // Only VoD deletions can be tracked and restored.
          const followUp = asset.isLive ? undefined : 'TRACK';
          this.snackBar.message('The asset has been deleted.', followUp)
            .onAction()
            .subscribe(() => {
              this.navigateToAssetDeletionTable();
            });
        } else {
          this.snackBar.error({ message: 'Asset deletion failed.' });
        }
      });
  }

  async openExportAssetDialog() {
    const asset = this.asset;
    assertTruthy(
        !asset.original,
        'AssetExtraActions.openExportAssetDialog: original asset expected.');

    const noAccess = await this.accessManagementActionsService.checkNoAccessAndUpdatePermissionsForSelected(this.asset);
    if (noAccess) {
      this.snackBar.error('Action disabled due to restricted asset.');
      return;
    }

    const config: ExportAssetDialogInputData = {assets: [asset]};
    this.dialog.open(
      ExportAssetDialog, ExportAssetDialog.getDialogOptions(config));
  }

  navigateToAssetDeletionTable() {
    return this.router.navigate([DELETED_ASSETS_ROUTE_PATH], {
      queryParamsHandling: 'preserve',
    });
  }

  getFirebaseGa() {
    let message = 'Add full title';
    if (this.paddedSegment) {
      message = 'Add clip of a padded segment';
    }
    if (this.querySegment) {
      message = 'Add clip of a query segment';
    }
    message += ` from extra actions on ${PAGE_CONTEXT_TOKEN}`;
    return message;
  }

  isVideoDeleted() {
    return (this.asset.original ?? this.asset).isDeleted;
  }

  async storeIASEventForDeleteAsset(asset: Original | Clip) {
    if (this.featureFlag.featureOn('store-user-information')) {
      const iasEvent: IASEvent = this.iasEventHelper.formatDeletedAssetsIASEvent(asset);
      await this.dataService.createIASEvent(iasEvent);
    }
  }

  async storeIASDeletedAsset(asset: Original | Clip) {
    await this.dataService.createDeletedAsset({name: asset.name});
  }

  showDetailsOptions() {
    const userId = this.authService.getUserEmail();
    const regularUser = !this.authService.isAdmin;
    const hasAccess = this.assetPermissions?.some(assetPermission => assetPermission?.userId?.trim() === userId);
    return regularUser && hasAccess;
  }

  openDetailsDialog() {
    if (this.assetPermissions) {
      const permissions: Partial<ResourceAccessUser>[] = this.assetPermissions.map(ap => {
        return {
          displayName: ap.displayName,
          email: ap.userId,
        };
      });

      this.dialog.open(AccessDetailsAssetDialog, { data: { documentId: this.documentId, permissions }, autoFocus: false });
    }
  }
}
