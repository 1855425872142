import {ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { ResourceTypes } from '../../landing/clip-bin-section/service/resource-types';
import { Resource, ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { BinSectionContent, BinService } from '../../services/bin.service';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';
import { DisplayMode } from '../../services/vod_search_service';
import { preventSpecialChars } from '../../utils/form.utils';

/** Clipbin rename dialog */
@Component({
  selector: 'mam-update-folder-dialog',
  templateUrl: './clipbin_folder_update_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;
      .error {
          color: red;
          font-size: 12px;
          margin-top: 4px;
      }

        @include breakpoint-md-max {
          max-width: 95vw;
          width: 65vw;
          }

        @include breakpoint-sm-max {
          width: initial;
          max-width: initial;
        }
      }`
  ]
})
export class ClipBinFolderUpdateDialog  {
  @ViewChild('input') input!: ElementRef;

  static readonly dialogOptions = {hasBackdrop: true};

  isBusy$ = new BehaviorSubject<boolean>(false);

  contextId = window.location.pathname.split('/').pop() || '';

  hasNotAllowedCharacters$ = new BehaviorSubject<boolean>(false);
  wordNotAllowed$ = new BehaviorSubject<boolean>(false);

  constructor(
    readonly dialogRef: MatDialogRef<ClipBinFolderUpdateDialog>,
    private readonly snackBar: SnackBarService,
    private readonly resourceService: ResourceService,
    private readonly stateService: StateService,
    private readonly binService: BinService,
    @Inject(MAT_DIALOG_DATA) public data: Resource
  ) {
  }

  onSubmit(renamedTitle:string){
    const _renamedTitle = renamedTitle.trim();

    if (!_renamedTitle || _renamedTitle === this.data.displayName || this.isDisabledButton()) return;

    this.data.name = _renamedTitle;

    this.isBusy$.next(true);

    if(this.data.id) {
    this.resourceService.updateResource(ResourceTypes.FOLDER, this.data.id, this.data, this.contextId)
      .subscribe({
        next: () => {
          this.dialogRef.close(_renamedTitle);
          if(this.binService.displayMode$.value === DisplayMode.GRID) {
            this.resourceService.isLoading$.next(true);
          }

          this.snackBar.message('Folder has been updated successfully.');

          if(this.stateService.searchModeSelected$.value !== BinSectionContent.BIN){
            this.stateService.searchModeSelected$.next(BinSectionContent.BIN);
          }

          if(this.binService.displayMode$.value === DisplayMode.GRID) {
            this.resourceService.updateLocalResource(this.data.name, _renamedTitle);
          }
        },
        error: (error) => {
          this.snackBar.error(
              'Folder could not be updated.', undefined, error);
        },
        complete: () => {
          this.isBusy$.next(false);
          if(this.binService.displayMode$.value === DisplayMode.GRID) {
            this.resourceService.isLoading$.next(false);
          }
        }
      });
    }
  }

  onInput() {
    const inputValue = this.input.nativeElement.value;
    const { hasNotAllowedCharacters, wordNotAllowed } = preventSpecialChars(inputValue);
    this.hasNotAllowedCharacters$.next(hasNotAllowedCharacters);
    this.wordNotAllowed$.next(wordNotAllowed);
  }

  isDisabledButton(): boolean {
    if(!this.input || !this.input.nativeElement)
      return false;
    return this.input.nativeElement.value.trim().length === 0 || this.hasNotAllowedCharacters$.value || this.wordNotAllowed$.value;
  }

  isDisabledInput(): boolean {
    return this.isBusy$.value;
  }
}
