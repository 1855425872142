import {SelectionModel} from '@angular/cdk/collections';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, signal} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import { Router } from '@angular/router';
import { of, ReplaySubject} from 'rxjs';
import {concatMap, debounceTime, finalize, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import { ResourceAccessInfo } from '../access_management/models/access_management.model';
import { AccessManagementActionsService } from '../access_management/services/access_management_actions.service';
import { assertTruthy } from '../asserts/asserts';
import { AuthService } from '../auth/auth_service';
import { environment } from '../environments/environment';
import {isErrorResponse} from '../error_service/error_response';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import {VcmsQueryExpressions} from '../query_expressions/vcms_query_expressions';
import { StagingService, StagingView } from '../right_panel/staging_service';
import {Asset, AssetService, AssetState, ListResponse, Original} from '../services/asset_service';
import {ApiAssetState} from '../services/ias_types';
import { FileState } from '../services/media_cache_service';
import {PaginatorIntl, UNKNOWN_LENGTH} from '../services/paginator-intl';
import {PreferencesService} from '../services/preferences_service';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {TableUtils} from '../services/table_utils';
import { TaskStatus } from '../services/transfer_service';
import { ColumnMapType, StorageItemState, StorageType, TableCol } from '../ui/ui_table.type';

import {MultiSelectOption, MultiSelectOptions} from './multiselect_table_header';
import {StagingTable} from './staging_table_base';

export const ALL_COLUMNS = [
  'select',
  'title',
  'schema',
  'source',
  'date',
  'duration',
  'status',
  'permission',
  'location'
] as const;

type Column = typeof ALL_COLUMNS[number];

interface VodStagingSort extends Sort {
  active: Column;
}

/** Page size options for list view. */
const PAGE_SIZE_OPTIONS = [30, 50, 100, 200];

/** Default page size */
const DEFAULT_PAGE_SIZE = 100;

/** When page size selector is disabled we mimic older MAM UI */
const LEGACY_PAGE_SIZE = 30;

const DEFAULT_SORT: VodStagingSort = {
  active: 'date',
  direction: 'desc'
};

/**
 * Icons used to indicate different asset states. SPINNER is a pseudo-icon that
 * will be rendered as animated spinner.
 */
const enum VodAssetIcon {
  SPINNER = 'spinner',
  APPROVED = 'check',
  ERROR = 'error',
  DEFAULT = 'insert_drive_file'
}

/**
 * Vod content staging table.
 */
@Component({
    selector: 'mam-vod-staging-table',
    templateUrl: './vod_staging_table.ng.html',
    styleUrls: ['./vod_staging_table.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }]
})
export class VodStagingTable extends StagingTable implements OnInit {
    /** Flag to show assets' source column. */
    readonly showAssetsSource = this.featureService.featureOn('show-user-information');

    /**Flag to show access management buttons */
    readonly isAccessManagementEnable = this.featureService.featureOn('enable-access-management');

    /** Flag to use List views feature. */
    readonly showListView = this.featureService.featureOn('use-table-list-views');

    selection = new SelectionModel<unknown>(true, []);

    cols: TableCol[] = [];

    private initialCols = (isEnabledAccessManagement:boolean) : TableCol[] => {
      const initialColumns: TableCol[] = [
        {
          key: 'checkbox',
          name: '',
          resizer: false,
          dragger: false,
          headerTpl: 'checkBoxTpl',
          cellTpl: 'checkBoxTpl',
          order: 0,
          headerStyle: {
            width: 'var(--table-checkbox-width)',
            minWidth: 'var(--table-checkbox-minwidth)',
            maxWidth: 'var(--table-checkbox-width)'
          },
          cellStyle: {
            textOverflow: 'clip'
          },
          orderMenu: 0
        },
        {
          key: 'title',
          name: 'Title/File name',
          optioner: true,
          sorter: true,
          dragger: false,
          resizer: true,
          disabled: true,
          order: 1,
          cellTpl: 'nameTpl',
          headerStyle: {
            width: '120px',
            minWidth: '100px'
          },
          orderMenu: 1
        },
        {
          key: 'source',
          name: 'Source',
          optioner: true,
          dragger: true,
          resizer: true,
          sorter: false,
          order: 2,
          headerTpl: 'sourceTpl',
          headerStyle: {
            width: '115px',
            minWidth: '100px'
          },
          orderMenu: 2
        },
        {
          key: 'createTime',
          name: 'Date',
          optioner: true,
          dragger: true,
          resizer: true,
          sorter: true,
          pipe: 'tzdate',
          pipeArg: 'MMM d, y, h:mm a',
          headerStyle: {
            width: '130px',
            minWidth: '130px'
          },
          order: 3,
          orderMenu: 3
        },
        {
          key: 'duration',
          name: 'Duration',
          dragger: true,
          resizer: true,
          sorter: true,
          cellTpl: 'durationTpl',
          headerStyle: {
            width: '90px',
            minWidth: '80px'
          },
          order: 4,
          orderMenu: 4
        },
        {
          key: 'status',
          name: 'Status',
          optioner: true,
          dragger: true,
          resizer: true,
          headerTpl: 'statusTpl',
          cellTpl: 'statusTpl',
          headerStyle: {
            minWidth: '100px',
            width: '130px'
          },
          order: 5,
          orderMenu: 5
        },
        {
          key: 'schema',
          name: 'Schema Name',
          optioner: true,
          dragger: true,
          resizer: true,
          sorter: true,
          cellTpl: 'schemaTpl',
          headerStyle: {
            width: '110px',
            minWidth: '110px',
          },
          order: 7,
          orderMenu: 7
        },
        {
          key: 'storage',
          name: 'Storage',
          dragger: true,
          resizer: false,
          stickyEnd: true,
          headerTpl: 'storageTpl',
          cellTpl: 'storageTpl',
          headerStyle: {
            width: '95px',
            minWidth: '71px',
            textAlign: 'right',
            paddingRight: '5px',
          },
          class: ['th-align-right'],
          order: 8,
          orderMenu: 8
        }
      ];

      const permissionCol: TableCol = {
        key: 'permission',
        name: 'Permission',
        optioner: true,
        sorter: false,
        resizer: true,
        dragger: true,
        hidden: !isEnabledAccessManagement,
        disabled: !isEnabledAccessManagement,
        cellTpl: 'permissionTpl',
        headerStyle: {
          width: '110px',
          minWidth: '110px'
        },
        order: 6,
        orderMenu: 6
      };

      if (isEnabledAccessManagement){
        initialColumns.push(permissionCol);
      }

      return initialColumns.sort((a, b) => a.order - b.order);
    };

    rows: Original[] = [];

    /** Expose status enumeration to template. */
    readonly TaskStatus = TaskStatus;

    displayedColumns = signal<Column[]>([]);

    pageSize = this.restorePageSize();

    /** Form control for search input. */
    search = new FormControl<string>('');

    /** Current query entered by the user that is be used in asset request. */
    userQuery = '';

    readonly dataRequest$ = new ReplaySubject<{ pageIndex: number; pageSize: number }>(1);

    currentPageIndex = 0;

    /** Total asset count across all pages. */
    totalCount = UNKNOWN_LENGTH;

    /** Page size options for vod table. */
    readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

    readonly view: StagingView = 'vod';

    /** Options for status column filter. */
    statusFilterOptions: MultiSelectOptions<ApiAssetState> = [
        { title: 'Any Status', selected: true },
        { title: 'Ready', value: 'STATE_READY' },
        { title: 'Processing', value: 'STATE_PROCESSING' }
    ];

    /** Options for status filter. */
    storageOptions: MultiSelectOptions<StorageType> = [
        { title: 'All', selected: true },
        { title: 'Cloud', value: FileState.FILE_CLOUD_ONLY },
        { title: 'OnPrem', value: FileState.FILE_ONPREM_ONLY },
        { title: 'Cloud/OnPrem', value: FileState.FILE_CLOUD_AND_ONPREM }
    ];
    storageList: Map<string, StorageItemState> = new Map();
    storageType: StorageType = FileState.FILE_CLOUD_ONLY;
    selectedStorageType: string = 'Storage';

    assetSchemaMap: Map<string, ColumnMapType> = new Map();
    assetDurationMap: Map<string, ColumnMapType> = new Map();
    activeSort: VodStagingSort = { ...DEFAULT_SORT };
    sortLoading: boolean = true;

    tableId: string;

    constructor(
        readonly featureService: FeatureFlagService,
        private readonly progressbar: ProgressbarService,
        private readonly preferences: PreferencesService,
        private readonly snackbar: SnackBarService,
        private readonly elementRef: ElementRef,
        private readonly analyticsService: FirebaseAnalyticsService,
        private readonly vcmsExpressions: VcmsQueryExpressions,
        stagingService: StagingService,
        assetService: AssetService,
        tableUtils: TableUtils,
        cdr: ChangeDetectorRef,
        stateService: StateService,
        router: Router,
        private readonly accessManagementActionsService: AccessManagementActionsService,
        readonly authService: AuthService
    ) {
        super(stagingService, tableUtils, assetService, cdr, stateService, router);
        this.tableId = environment.tableInfoId['vodStagingTable'];

        this.cols = this.initialCols(this.isAccessManagementEnable);

        this.search.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(300)).subscribe((query) => {
            this.scrollTopNeeded.emit();
            this.userQuery = query ?? '';
            this.refreshTable();
        });

        this.startResponsiveLayout();
    }

    override ngOnInit() {
        super.ngOnInit();
        this.clearAssetMaps();
        this.startFetchingAssetsOnPageChange();
        // Trigger asset fetching.
        this.dataRequest$.next({ pageIndex: 0, pageSize: this.pageSize });
        this.restrictAssetChangesWatcher();
    }

    private restrictAssetChangesWatcher() {
        this.stateService.restrictAssetUpdateFromMetadata$.pipe(takeUntil(this.destroyed$)).subscribe({
            next: (updated) => {
                if (updated) {
                    this.refreshTable();
                }
            }
        });
    }

    storageInfoList(ev: StorageItemState) {
        ev.storageName = this.storageOptions.find((el) => el.value === ev.state)?.title;
        this.storageList.set(ev.name, ev);
    }

    onTypeFilterChanged(selected: MultiSelectOption) {
        const option = selected as MultiSelectOption<StorageType>;
        this.storageType = option.value || 'all';
        this.selectedStorageType = option.title;

        // Change selected option
        this.storageOptions = this.tableUtils.onMultiSelectOptionUpdate(this.storageOptions, option.value);

        if (this.storageType === 'all') this.rows = [...this.sortByDate(this.rows as Original[])];

        // Convert sortList to an array and sort it by `type`
        const sortedList = Array.from(this.storageList.entries())
            .filter(([, value]) => value.state === this.storageType)
            .map(([key]) => key);

        const rowClone = [...this.rows] as Original[];
        const sortedTableRows = rowClone.sort((a, b) => {
            const aIndex = sortedList.indexOf(a.name);
            const bIndex = sortedList.indexOf(b.name);

            // Items with the targetType come first, followed by others
            if (aIndex === -1 && bIndex === -1) return 0;
            if (aIndex === -1) return 1;
            if (bIndex === -1) return -1;
            return aIndex - bIndex;
        });

        this.rows = [...sortedTableRows];
    }

    private sortByDate(list: Original[]): Original[] {
        return list.sort((a, b) => a.createTime - b.createTime);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.rows.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.rows.forEach((row) => this.selection.select(row));
    }

    changePage({ pageIndex, pageSize }: PageEvent) {
      this.clearAssetMaps();
      this.scrollTopNeeded.emit();
      this.dataRequest$.next({ pageIndex, pageSize });
    }

    formatStatus(asset: Original) {
        // STATE_ERROR
        if (asset.hasError) return 'Error';

        // STATE_READY
        if (asset.state === AssetState.VOD) return 'Ready';

        // STATE_PROCESSING
        if (asset.state === AssetState.PROCESSING) return 'Processing';

        // Unreachable unless there is an empty asset or mis-indexed live asset
        // returned by api.
        return 'Unknown';
    }

    /** Refreshes the table from the first page. */
    refreshTable() {
      this.clearAssetMaps();
      this.stateService.togglePersistentPanel$.next(false);
      this.dataRequest$.next({ pageIndex: 0, pageSize: this.pageSize });
      this.cdr.detectChanges();
    }

    onSortChanged(sort: Sort, rows: Original[], activeKey: Column = this.activeSort.active) {
      if (this.loading || this.sortLoading) return;
      this.sortLoading = true;

      if (this.activeSort.active !== activeKey) this.activeSort.active = activeKey;
      //adjust active sort column to match API request
      if (sort.active === 'createTime') sort.active = 'date';
      this.activeSort = sort as VodStagingSort;
      const sortDirection: boolean = sort.direction === 'asc';

      this.analyticsService.logEvent('Sort VoD staging table', {
        eventType: AnalyticsEventType.LOG,
        string1: this.activeSort.active,
        string2: this.activeSort.direction,
      });

      // assign the value if rows are already sorted elsewhere
      if (['source'].includes(activeKey)) {
        this.rows = rows;
        this.sortLoading = false;
        this.cdr.detectChanges();
        return;
      }

      // validate if column is handles by API
      const sortedByApi = Boolean(!['schema', 'duration'].includes(sort.active));
      let sortedList: Original[] = rows;

      if (sortedByApi) {
        this.refreshTable();
      } else {
        // Convert sortList to an array and sort it by `schema` or `error`
        const mapToSort = (this.activeSort.active === 'schema') ? this.assetSchemaMap : this.assetDurationMap;
        const referenceMap = Array.from(mapToSort.entries());
        sortedList = this.tableUtils.sortByMapReference<Original>(referenceMap, rows, sortDirection);

        this.sortLoading = false;
      }

      this.rows = sortedList;
    }

    onStatusFilterChanged(option: MultiSelectOption) {
        this.statusFilterOptions = this.statusFilterOptions.map((opt) => {
            opt.selected = opt.title === option.title;
            return opt;
        });
        this.refreshTable();
    }

    getStatusIcon(asset: Original) {
        if (asset.hasError) return VodAssetIcon.ERROR;
        if (asset.state === AssetState.PROCESSING) return VodAssetIcon.SPINNER;
        if (asset.approved) return VodAssetIcon.APPROVED;

        return VodAssetIcon.DEFAULT;
    }

    getStatusTooltip(asset: Original) {
        if (asset.hasError) return asset.errorReason || 'Error';
        if (asset.state === AssetState.PROCESSING) return 'Processing';
        if (asset.approved) return 'Approved';

        return '';
    }

    protected override updateCache(changedItems: Map<string, Original>) {
        for (const cachedPage of Array.from(this.assetListResponseCache.values())) {
            for (let i = 0; i < cachedPage.assets.length; i++) {
                const changedItem = changedItems.get(cachedPage.assets[i].name);
                if (changedItem) {
                    cachedPage.assets[i] = changedItem;
                }
            }
        }
    }

    /**
     * Contains cached asset list responses. Array index correlates with
     * pageIndex.
     */
    private assetListResponseCache: Array<ListResponse<Original>> = [];

    /**
     * Listens to the emissions from `dataRequest$` and if `refresh` param is off
     * fetches data for the requested page from the cache or the data service.
     * When `refresh` is true, drops the whole cache and fetches data from first
     * page and up to the requested one.
     *
     * Paginator has to be manually disabled until the request is done because we
     * can't get `N+1` page until we have result for `N` because we would lack
     * the `nextPageToken`.
     */
    private startFetchingAssetsOnPageChange() {
      if (this.loading) return;

        this.dataRequest$
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.cdr.markForCheck();
                    this.loading = true;
                    this.progressbar.show();
                }),
                switchMap(({ pageIndex, pageSize }) => {
                    // When page size is changed store new value and drop the user to
                    // the first page. This will clear the cache as well (line 183)
                    if (pageSize !== this.pageSize) {
                        pageIndex = 0;
                        this.pageSize = pageSize;
                        this.storePageSize(pageSize);
                    }

                    // Always re-fetch the first page and drop the cache.
                    if (pageIndex === 0) {
                        this.assetListResponseCache = [];
                        this.totalCount = UNKNOWN_LENGTH;
                    } else {
                        const cached = this.assetListResponseCache[pageIndex];
                        if (cached) return of({ response: cached, pageIndex });
                    }

                    const mustUsePageToken = pageIndex !== 0;
                    const pageToken = mustUsePageToken
                        ? this.assetListResponseCache[pageIndex - 1]?.nextPageToken
                        : undefined;
                    if (mustUsePageToken) {
                        assertTruthy(pageToken, `Vod Staging: No page token for page index ${pageIndex}`);
                    }
                    return this.stagingService
                        .getVodAssets({
                            pageSize: this.pageSize,
                            query: this.buildQuery(),
                            pageToken,
                            sortOptions: this.tableUtils.buildVoDSortOptions<Omit<Column, 'title'>>(this.activeSort)
                        })
                        .pipe(
                            map((response) => ({ response, pageIndex }))
                        );
                }),
                finalize(() => {
                    this.progressbar.hide();
                })
            )
            .subscribe(({ response, pageIndex }) => {
                this.cdr.markForCheck();

                this.currentPageIndex = pageIndex;

                if (isErrorResponse(response)) {
                    this.snackbar.message('Failed to load staging assets');
                    this.assets = [];

                    //review
                    this.rows = [];

                    // Prevent further navigation after an error.
                    this.totalCount = this.currentPageIndex * this.pageSize;
                    this.refreshActiveAndSelectedItems();
                    return;
                }

                this.assetListResponseCache[pageIndex] = response;
                this.populateColumnMaps(response.assets);
                this.assets = response.assets;

                //review
                this.rows = response.assets;

                this.refreshActiveAndSelectedItems();
                this.storageOptions = this.tableUtils.onMultiSelectOptionUpdate(this.storageOptions, undefined);

                if (!response.nextPageToken) {
                    // This is the last page, set total count which will disable next
                    // page in the paginator.
                    this.totalCount = this.currentPageIndex * this.pageSize + this.assets.length;
                }

                this.loading = false;
                this.progressbar.hide();
                this.sortLoading = false;
            });
    }

    private populateColumnMaps(assets: Original[]) {
      assets.forEach((asset) => {
        // populate schema map
        this.assetService.getSchemaTitle(asset)
          .pipe(take(1))
          .subscribe((val) => {
            if (!this.assetSchemaMap.has(asset.name)) {
              this.assetSchemaMap.set(asset.name, {
                name: asset.name,
                value: val || '--'
              });
            }
          });

        // populate duration column
        if (!this.assetDurationMap.has(asset.name)) {
          this.assetDurationMap.set(asset.name, {
            name: asset.name,
            value: String(asset.duration)
          });
        }
      });
    }

    private clearAssetMaps() {
      this.assetSchemaMap.clear();
      this.assetDurationMap.clear();
    }

    private startResponsiveLayout() {
        this.tableUtils
            .observeWidth(this.elementRef.nativeElement, [
                {
                    name: 'S',
                    minWidth: 0,
                    add: ['select', 'title', 'source', 'date', 'status', 'schema', 'permission', 'location']
                }
            ])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(({ columns }) => {
                assertTruthy(columns, 'At least one breakpoint should be activated.');
                this.cdr.markForCheck();
                this.displayedColumns.set(columns);
            });
    }

    private restorePageSize() {
        const pageSize = Number(this.preferences.load('vod_staging_page_size'));
        const defaultPageSize = this.featureService.featureOn('use-vod-staging-page-size-selector')
            ? DEFAULT_PAGE_SIZE
            : LEGACY_PAGE_SIZE;
        return PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : defaultPageSize;
    }

    private storePageSize(pageSize: number) {
        // Don't store page size when page selector is not available.
        if (this.featureService.featureOff('use-vod-staging-page-size-selector')) {
            return;
        }
        this.preferences.save('vod_staging_page_size', String(pageSize));
    }

    private buildQuery() {
        const selectedStatus = this.statusFilterOptions.find((opt) => opt.selected);
        if (!selectedStatus?.value) return this.userQuery;

        return this.vcmsExpressions.and([this.userQuery, this.vcmsExpressions.is('AssetState', selectedStatus.value)]);
    }

    onRowClick(row: Original, selectedAssetSet: Set<string>, shiftPressed = false) {
        this.selectOrActivate(row, selectedAssetSet, shiftPressed);
    }

    onResetCols() {
        const defaultCols: Column[] = [
            'select',
            'title',
            'source',
            'date',
            'status',
            'schema',
            'permission',
            'location'
        ];
        this.displayedColumns.set(defaultCols);
    }

    onSortDirectionChanged(direction: boolean) {
        this.activeSort.direction = direction ? 'asc' : 'desc';
    }

    /** sorting method used when showListView flag is off */
    onSortOldTable(rows: Original[], activeKey: Column = this.activeSort.active) {
        if (this.activeSort.active !== activeKey) this.activeSort.active = activeKey;

        const sortedRows = this.tableUtils.sortByField(
            rows,
            activeKey,
            this.activeSort.direction !== 'desc'
        ) as Original[];

        this.assets = sortedRows;
        this.cdr.detectChanges();
    }

    addUsersOnSelected() {
        this.stagingService.selectedAssetSet$
            .pipe(
                take(1),
                concatMap((selectedAssets) => {
                    const selectedResourcesDocumentId: Partial<ResourceAccessInfo>[] = [];
                    const selectedPublicAsset: Partial<Asset>[] = [];

                    Array.from(selectedAssets).forEach((assetId) => {
                        const asset = this.assets?.find((f) => f.name === assetId);

                        if (!asset) return;

                        const { permissions, permissionsDocumentId: documentId } = asset;
                        if (permissions && documentId) {
                            selectedResourcesDocumentId.push({ permissions, documentId });
                        } else {
                            selectedPublicAsset.push(asset);
                        }
                    });

                    return this.accessManagementActionsService.openAddUsersAndMakeRestrictOnSelectedIds(
                        selectedResourcesDocumentId as ResourceAccessInfo[],
                        selectedPublicAsset
                    );
                })
            )
            .subscribe({
                next: () => {
                    this.refreshTable();
                }
            });
    }

    makePublicSelected() {
        this.stagingService.selectedAssetSet$
            .pipe(
                take(1),
                concatMap((selectedAssets) => {
                    const selectedResourcesDocumentId: Partial<ResourceAccessInfo>[] = [];

                    Array.from(selectedAssets).forEach((assetId) => {
                        const { permissions, permissionsDocumentId: documentId } =
                            this.assets?.find((f) => f.name === assetId) || {};
                        if (permissions && documentId) {
                            selectedResourcesDocumentId.push({ permissions, documentId });
                        }
                    });
                    const docsId = selectedResourcesDocumentId.map((f) => f.documentId).filter((f) => f) as string[];

                    return this.accessManagementActionsService.openMakePublicSelectedIds(docsId);
                })
            )
            .subscribe({
                next: () => {
                    this.refreshTable();
                }
            });
    }
}
