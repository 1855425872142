import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { BreadcrumbService } from '../../services/breadcrumb.service';
import { ResourceBin } from '../clip-bin-preview/clip-bin-preview';
import { Resource } from '../clip-bin-section/service/resource.service';

export interface BreadcrumbItem {
  id: string;
  name: string;
}

@Component({
  selector: 'mam-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  @Output() isExpandedChange = new EventEmitter<boolean>();
  @Input() resource: Resource | ResourceBin | undefined = undefined;
  @Input() breadcrumbPath: string | undefined = undefined;

  breadcrumbPathTrunc: string | undefined = undefined;
  hasChildren: boolean = false;
  isExpanded: boolean = false;
  folderLevel: number = 0;
  shouldTrunc: boolean = false;

  private breadcrumbs: BreadcrumbItem[] = [];

  constructor(private readonly breadcrumbService: BreadcrumbService, private cdr: ChangeDetectorRef, private router: Router) {
  }

  ngOnInit(): void {
    if (this.resource && this.resource?.directChildrenCount) {
      this.hasChildren = this.resource?.directChildrenCount > 0 || false;
    }
    this.folderLevel = this.resource?.level || 0;
  }

  onClickLocationIcon($event: MouseEvent) {
    if (this.folderLevel === 0) return;
    if (this.resource && this.resource.id) {
      this.breadcrumbService.getFolderBreadcrumbs(this.resource.id).subscribe((breadcrumbs) => {
        this.breadcrumbs = breadcrumbs;
        const fullPath = breadcrumbs.map((breadcrumb) => breadcrumb.name).join(' > ');
        this.breadcrumbPath = fullPath;
        this.breadcrumbPathTrunc = fullPath;
        this.shouldTrunc = this.breadcrumbPathTrunc.length > 17;
        this.isExpanded = !this.isExpanded;
        this.isExpandedChange.emit(this.isExpanded);
        this.cdr.markForCheck();
      });
    }
    $event.stopPropagation();
  }

  onClickPath($event: MouseEvent) {
    if (this.breadcrumbs && this.breadcrumbs.length >= 1) {
      const parent = this.breadcrumbs[this.breadcrumbs.length - 1];
      this.navigateToFolder(parent.id);
    }
    $event.stopPropagation();
  }

  private navigateToFolder(folderId: string): void {
    this.router.navigate(['/folders', folderId], { queryParamsHandling: 'merge' }).then(() => {
      this.cdr.markForCheck();
      return true;
    });
  }
}
