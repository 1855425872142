import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogOpener implements OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private dialog: MatDialog) {}
// eslint-disable-next-line @typescript-eslint/no-explicit-any --- The type of D and R argument is determined dynamically based on the resource service response.
  openDialog<T, D = any, R = any>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any --- The type of dialogComponent argument is determined dynamically based on the resource service response.
    dialogComponent: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any --- The type of dialogOptions argument is determined dynamically based on the resource service response.
    dialogOptions: any,
    data: D,
    resultCallback?: (result: R | undefined) => void
  ): void {
    this.dialog
      .open<T, D, R>(dialogComponent, {
        ...dialogOptions,
        data: data,
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(resultCallback);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
