import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Duration} from 'luxon';
import {firstValueFrom, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {concatMap, distinctUntilChanged, finalize, map, switchMap, takeUntil, tap} from 'rxjs/operators';

import { AccessManagementCutDownActionsService, CutdownRestriction } from '../access_management/services/access_management_cut_down_actions.service';
import {assertTruthy, assumeExhaustive, castExists} from '../asserts/asserts';
import { AuthService } from '../auth/auth_service';
import { environment } from '../environments/environment';
import {ErrorResponse, isErrorResponse} from '../error_service/error_response';
import { FeatureFlagService } from '../feature_flag/feature_flag_service';
import {CutDownDialog} from '../right_panel/cut_down_dialog';
import {StagingService} from '../right_panel/staging_service';
import {MetadataField, NEW_ASSET_COPY_NAME} from '../services/asset_api_service';
import {AssetCopy, AssetService, AssetState, ClipMarking, Original} from '../services/asset_service';
import {DialogService} from '../services/dialog_service';
import {ApiCopyStateDetailStateEnum} from '../services/ias_types';
import {LiveAssetManifestInfo, ManifestService} from '../services/manifest.service';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {TableUtils} from '../services/table_utils';
import {TimezoneService} from '../services/timezone_service';
import {UtilsService} from '../services/utils_service';

const ALL_COLUMNS =
    ['title', 'type', 'duration', 'start', 'end', 'status', 'actions'] as const;

const ALL_COLUMNS_AM =
    ['title', 'type', 'permission', 'duration', 'start', 'end', 'status', 'actions', 'actions-restriction'] as const;

type Column = typeof ALL_COLUMNS[number];

type ColumnAM = typeof ALL_COLUMNS_AM[number];

enum IconState {
  SUCCEEDED = 'SUCCEEDED',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  DEFAULT = 'DEFAULT',
}

/**
 * Displays cut-down list for a live asset.
 */
@Component({
  selector: 'mam-cut-down-details',
  templateUrl: './cut_down_details.ng.html',
  styleUrls: ['./cut_down_details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CutDownDetails implements OnInit, OnDestroy {
  @HostBinding('class.loading') loading = false;
  @Input() containerWidth?: number;
  @Input()
  get asset() {
    return castExists(this.assetInternal);
  }
  set asset(value: Original) {
    if (this.assetInternal === value) return;
    this.assetInternal = value;
    this.assetChanged$.next(value);
  }

  /**
   * Cache to store individual cutdown statuses, to restore cutdown state from
   * when the component is re-created.
   */
  @Input() cutdownCache?: Map<string, AssetCopy[]>;

  // Keep cut-down list separate to prevent unsaved changes propagating to the
  // parent asset object.
  cutdowns: AssetCopy[] = [];

  displayedColumns: (Column | ColumnAM)[] = [...ALL_COLUMNS];

  readonly cutDownDisplayHandler: CutDownDisplayHandler = handlerFactory({mockHandler: new MockDispayHandler(environment.tag)});

  readonly ApiCopyStateDetailStateEnum = ApiCopyStateDetailStateEnum;

  readonly IconState = IconState;

  fetchCutdownsRequest$ = new Subject<
      {asset: Original, disableDetailsViewWhileFetching: boolean}>();

  enableAccessManagement = this.featureFlagService.featureOn('enable-access-management');

  constructor(
      readonly stagingService: StagingService,
      private readonly snackBar: SnackBarService,
      private readonly dialog: MatDialog,
      private readonly progressbar: ProgressbarService,
      private readonly assetService: AssetService,
      private readonly cdr: ChangeDetectorRef,
      private readonly dialogService: DialogService,
      private readonly timezoneService: TimezoneService,
      private readonly manifestService: ManifestService,
      private readonly tableUtils: TableUtils,
      private readonly elementRef: ElementRef,
      readonly utils: UtilsService,
      private readonly featureFlagService: FeatureFlagService,
      private readonly accessManagementCutDownActions: AccessManagementCutDownActionsService,
      readonly authService: AuthService
  ) {
    this.assetChanged$
        .pipe(
            distinctUntilChanged((a, b) => a.name === b.name),
            switchMap(
                asset => this.manifestService.getLiveAssetTimeline(asset)),
            takeUntil(this.destroyed$),
            )
        .subscribe(manifestInfo => {
          this.manifestInfo = manifestInfo;
          this.cdr.markForCheck();
        });

    // Listens to fetchCutdownsRequest$ and fetches cutdown list.
    this.hookCutdownUpdates();

    // Listens to stagingService.cutdownChanged$ and updates cutdown list
    // accordingly.
    this.hookCutdownChangedUpdates();

    // Listens to component size changes.
    this.startResponsiveLayout();


  }

  ngOnInit() {
    assertTruthy(this.asset, 'CutDownDetails: asset must be provided');

    // Draws cached cutdowns immediately if available.
    this.cutdowns = this.cutdownCache?.get(this.asset?.name) ?? [];
    this.stagingService.newCutdown$.pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.refreshCutdownList(this.asset));

    // Sends request to fetch cutdown list.
    // When the live staging table is resized it will cause this component to be
    // re-created. So we don't disable component while fetching to prevent
    // flicker on resizing.
    this.reloadCutdowns(/* disableDetailsViewWhileFetching */ false);

    // Refresh list when asset changes. This is needed to refresh the cutdowns
    // on live asset "approval". Will not be triggered initially because
    // assetChanged$ is a subject that has already fired when asset input was
    // initially set.
    this.assetChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.reloadCutdowns(/* disableDetailsViewWhileFetching */ false);
    });

    const enabledAM = this.featureFlagService.featureOn('enable-access-management');

    if(enabledAM) {
      this.displayedColumns = [...ALL_COLUMNS_AM];
    }
  }

  /** Start cut-down create flow based on the provided offsets. */
  async createCutdown() {
    const marking: ClipMarking|undefined = await firstValueFrom(
        this.dialog.open(CutDownDialog, {data: {asset: this.asset},panelClass:'livepopup'})
            .afterClosed());
    if (!marking) return;

    return this.stagingService.createCutdown(
        this.asset, marking.markIn, marking.markOut);
  }

  async deleteCutdown(copy: AssetCopy) {
    const confirmed = await firstValueFrom(this.dialogService.showConfirmation({
      title: 'Delete cutdown',
      question: 'Are you sure you want to delete this cutdown?',
      primaryButtonText: 'Delete',
    }));

    if (!confirmed) return;

    this.cdr.markForCheck();
    this.toggleLoading(true);

    const result = await firstValueFrom(this.assetService.deleteCopy(copy));

    this.cdr.markForCheck();
    this.toggleLoading(false);

    if (isErrorResponse(result)) {
      this.snackBar.error({
        message: 'Cutdown deletion failed',
        details: result.message,
        doNotLog: true,
      });
      return;
    }

    if(this.enableAccessManagement && (copy as CutdownRestriction)?.documentId) {
      this.accessManagementCutDownActions.makeCutdownPublic((copy as CutdownRestriction).documentId as string);
    }

    this.snackBar.message('Cutdown deleted successfully.');
    this.stagingService.cutdownChanged$.next(
        {name: copy.name, newValue: null, parent: this.asset});
  }

  getApprovableCutdowns(cutdowns = this.cutdowns) {
    return cutdowns.filter(c => c.state === 'STATE_DRAFT');
  }

  async approveCutdown(cutdown: AssetCopy) {
    return this.approveCutdowns([cutdown]);
  }

  async approveCutdowns(cutdowns = this.cutdowns) {
    cutdowns = this.getApprovableCutdowns(cutdowns);
    if (!cutdowns.length) {
      this.snackBar.message('There are no cutdowns to be approved');
      return;
    }

    const cutdownLabel = this.utils.pluralize(
        cutdowns.length, `"${this.getTitle(cutdowns[0])}"`,
        `${cutdowns.length} cutdowns`);

    const confirmed = await firstValueFrom(this.dialogService.showConfirmation({
      title: 'Approve cutdown(s)',
      question: `Are you sure you want to approve ${cutdownLabel}?`,
      primaryButtonText: 'Approve',
    }));

    if (!confirmed) return;

    this.cdr.markForCheck();
    this.toggleLoading(true);

    const results = await firstValueFrom(this.utils.batchApiCalls(
          cutdowns, cutdown => this.assetService.approveCopy(cutdown).pipe(
            concatMap((cutdownApproved) => (this.enableAccessManagement && !isErrorResponse(cutdownApproved))
            ? this.updateCutdownState(cutdownApproved as unknown as CutdownRestriction, cutdown) :  of(cutdownApproved))
          ),
          {abortIfNonAdmin: true}));

    this.cdr.markForCheck();
    this.toggleLoading(false);

    const failures =
        results.filter((r): r is ErrorResponse => isErrorResponse(r));
    const successes =
        results.filter((r): r is AssetCopy => !isErrorResponse(r));

    for (const success of successes) {
      this.stagingService.cutdownChanged$.next(
          {name: success.name, newValue: success, parent: this.asset});
    }

    // Check if we have any failure returned
    if (failures.length) {
      let message = ``;

      if ( failures[0].status === 403 ) {
        // 403 error when approving cutdowns.
        message = `Approval failed (forbidden).`;
      } else {
        message = cutdowns.length > failures.length ?
        `Approval failed for ${failures.length} cutdowns` :
        'Approval failed';
      }

      this.snackBar.error({
        message,
        details: failures[0].message,
        doNotLog: true,
      });
      return;
    }

    this.snackBar.message('Cutdown approved successfully.');
  }

  updateCutdownState(cutdownApproved: AssetCopy, cutdownFromTable: CutdownRestriction): Observable<AssetCopy> {
    if(!cutdownFromTable.documentId) return of(cutdownApproved);

    return this.accessManagementCutDownActions.updateCutDownStatus(cutdownFromTable.documentId, cutdownApproved.state)
    .pipe(
      map(() => {
        (cutdownApproved as CutdownRestriction).documentId = cutdownFromTable.documentId;
        (cutdownApproved as CutdownRestriction).permissions = cutdownFromTable.permissions;
        return cutdownApproved;
      })
    );
  }

  /**
   * Reloads cutdown list
   *
   * @param disableDetailsViewWhileFetching - when set to true fades the cutdown
   *     details view and disables pointer events.
   */
  reloadCutdowns(disableDetailsViewWhileFetching: boolean) {
    this.fetchCutdownsRequest$.next(
        {asset: this.asset, disableDetailsViewWhileFetching});
  }

  trackById(index: number, copy: AssetCopy) {
    return copy.name;
  }

  /** Shown provided cut-down details in the right side panel. */
  setActive(cutdown: AssetCopy, asset: Original) {
    this.stagingService.setActive({cutdowns: [cutdown], cutdownParent: asset});
  }

  /**
   * Formats number of seconds.
   *
   * Example output: `01:15:12`.
   */
  displayOffsetAsTime(offsetInSeconds: number) {
    if (!this.manifestInfo) return;

    const absoluteOffset = this.formatDuration(offsetInSeconds);
    if (isErrorResponse(this.manifestInfo)) {
      return `(${absoluteOffset})`;
    }

    const localStartDate =
        this.timezoneService.convert(this.manifestInfo.start);
    const wallClockTime =
        localStartDate.plus({seconds: offsetInSeconds}).toFormat('HH:mm:ss');
    return `${wallClockTime} (${absoluteOffset})`;
  }

  /**
   * Formats number of seconds.
   *
   * Example output for `4512`: `01:15:12` (1x3600+15x60+12).
   */
  formatDuration(offsetInSeconds: number) {
    return Duration.fromMillis(offsetInSeconds * 1000).toFormat('hh:mm:ss');
  }

  formatCutdownDuration(cutdown: AssetCopy) {
    const duration = this.getCutdownDurationInSeconds(cutdown);
    if (duration == null) return '';

    return this.formatDuration(duration);
  }

  getCutdownDurationInSeconds(cutdown: AssetCopy): number|undefined {
    if (cutdown.isFullCopy) {
      if (!this.manifestInfo) return undefined;

      if (isErrorResponse(this.manifestInfo)) return undefined;

      return this.manifestInfo.durationInSeconds;
    }

    return cutdown.endOffset - cutdown.startOffset;
  }

  getIconState(cutdown: AssetCopy): IconState {
    switch (cutdown.state) {
      case 'STATE_DRAFT':
      case 'STATE_UNSPECIFIED':
        return IconState.DEFAULT;
      case 'STATE_ERROR':
        return IconState.ERROR;
      case 'STATE_PFR_PROCESSING':
      case 'STATE_PFR_SUCCEEDED':
      case 'STATE_QUEUED':
      case 'STATE_VOD_PROCESSING':
        return IconState.IN_PROGRESS;
      case 'STATE_VOD_READY':
        return IconState.SUCCEEDED;
      default:
        assumeExhaustive(cutdown.state);
        return IconState.DEFAULT;
    }
  }

  getTitle(cutdown: AssetCopy) {
    return cutdown.metadata.jsonMetadata[MetadataField.TITLE] ?? '-';
  }

  isCutDownButtonDisplayed(asset: Original) {
    return this.cutDownDisplayHandler.handleRequest({handled: false, asset: asset}).displayCutdowButton;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  navigateToVodAsset(cutdown: AssetCopy) {
    if (!cutdown.vodAssetName) return;
    this.stagingService.navigateToDetailsView(cutdown.vodAssetName);
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
  private readonly assetChanged$ = new Subject<Original>();
  private assetInternal?: Original;
  private manifestInfo?: LiveAssetManifestInfo|ErrorResponse;

  private hookCutdownUpdates() {
    this.fetchCutdownsRequest$
        .pipe(
            tap(request => {
              this.loading = request.disableDetailsViewWhileFetching;
              this.progressbar.show();
            }),
            switchMap(({asset}) => {
              return this.assetService.listCopies(asset).pipe(
                  map(copies => ({asset, copies})));
            }),
            concatMap((assetAndCopies)=> {
              if(!this.enableAccessManagement || assetAndCopies.copies instanceof ErrorResponse) return of(assetAndCopies);

              return this.fillPermissionFromCutDown(assetAndCopies.copies).pipe(
                    map((response)=> ({copies: response, asset:assetAndCopies.asset})));
            }),
            takeUntil(this.destroyed$), finalize(() => {
              this.cdr.markForCheck();
              this.toggleLoading(false);
            }))
        .subscribe(({asset, copies}) => {
          this.cdr.markForCheck();
          this.toggleLoading(false);
          if (isErrorResponse(copies)) return;
          this.refreshCutdownList(asset, copies);
        });
  }

  fillPermissionFromCutDown(copies: AssetCopy[]): Observable<CutdownRestriction[]> {
    return this.accessManagementCutDownActions.getRestrictionAndPopulatePermission(copies);
  }

  private hookCutdownChangedUpdates() {
    this.stagingService.cutdownChanged$.pipe(takeUntil(this.destroyed$))
        .subscribe(({name, newValue, parent}) => {
          if (parent.name !== this.asset.name) return;

          // New copy
          if (name === NEW_ASSET_COPY_NAME) {
            this.stagingService.newCutdown$.next(undefined);
            if (newValue) {
              // New copy was created.
              this.refreshCutdownList(this.asset, [newValue, ...this.cutdowns]);
              this.stagingService.setActive(
                  {cutdowns: [newValue], cutdownParent: parent});
            } else {
              // New copy was discarded.
              this.refreshCutdownList(this.asset);
              if (this.stagingService.getActive()?.cutdowns?.some(
                      c => c.name === NEW_ASSET_COPY_NAME)) {
                this.stagingService.setActive(undefined);
              }
            }
            return;
          }

          // Existing copy
          if (!newValue) {
            // Existing copy was deleted.
            this.refreshCutdownList(
                this.asset, this.cutdowns.filter(c => c.name !== name));
            if (this.stagingService.getActive()?.cutdowns?.find(
                    c => c.name === name)) {
              this.stagingService.setActive(undefined);
            }
            return;
          }

          // Existing copy was updated.
          this.refreshCutdownList(
              this.asset,
              this.cutdowns.map(c => c.name === name ? newValue : c));
        });
  }

  private refreshCutdownList(asset: Original, cutdowns = this.cutdowns) {
    this.cdr.markForCheck();
    // Remove new cutdown if it exists.
    this.cutdowns = cutdowns.filter(c => c.name !== NEW_ASSET_COPY_NAME);
    this.cutdownCache?.set(asset.name, this.cutdowns);
    this.updateParentAssetStats();

    // If new cutdown exists and belongs to the current asset add it to the
    // bottom of the list.
    const newCutdown = this.stagingService.newCutdown$.value;
    if (newCutdown?.parent.name === this.asset.name) {
      this.cutdowns = [newCutdown.cutdown, ...this.cutdowns];
    }
  }

  private startResponsiveLayout() {
    const allColumns = [...ALL_COLUMNS];

    this.tableUtils
        .observeWidth(
            this.elementRef.nativeElement,
            [
              {name: 'XS', minWidth: 0, add: ['title', 'actions']},
              {name: 'S', minWidth: 650, add: ['start', 'end']},
              {name: 'M', minWidth: 800, add: ['type', 'status']},
              {name: 'L', minWidth: 900, add: this.featureFlagService.featureOn('enable-access-management') ? ['permission','duration','actions-restriction']: ['duration']},
            ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({columns}) => {
          this.cdr.markForCheck();
          this.displayedColumns = allColumns.filter(c => columns?.includes(c));
        });
  }

  /**
   * Updates asset's copy stats to update figures displayed in the live staging
   * cutdown status column. Can be used after any cutdown was
   * created/deleted/approved instead of re-fetching the whole asset.
   */
  private updateParentAssetStats() {
    const stats = this.asset.copyStats;
    stats.totalCount = this.cutdowns.length;
    stats.approvedCount = this.cutdowns.length;
    stats.errorCount = 0;
    stats.completedCount = 0;

    for (const cutdown of this.cutdowns) {
      switch (cutdown.state) {
        case 'STATE_DRAFT':
          stats.approvedCount--;
          break;
        case 'STATE_ERROR':
          stats.errorCount++;
          break;
        case 'STATE_VOD_READY':
          stats.completedCount++;
          break;
        default:
          break;
      }
    }
  }

  private toggleLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.progressbar.show();
    } else {
      this.progressbar.hide();
    }
  }

  async restrictCutdown(cutdown: CutdownRestriction) {
    this.accessManagementCutDownActions
      .openRestrictDialog(cutdown, this.asset.name, this.asset.permissions ?? [])
      .pipe(
        concatMap(() =>
          this.accessManagementCutDownActions
            .getRestrictionAndPopulatePermission([cutdown])
            .pipe(
              tap(([cutdownRestriction]) => this.addUsers(cutdownRestriction)))
        )
      ).subscribe();
  }

  async addUsers(cutdown: CutdownRestriction) {
     this.accessManagementCutDownActions
        .openAccessManagement(cutdown?.permissions ?? [], cutdown.documentId as string)
        .pipe(
          concatMap((updated: boolean) =>
            updated
              ? this.accessManagementCutDownActions.getRestrictionAndPopulatePermission([cutdown])
              : of([cutdown])
          ),
          map(([result]) => result)
        ).subscribe({
      next: (confirmCutdown) => {
        const { permissions, documentId } = confirmCutdown;
        cutdown.permissions = permissions;
        cutdown.documentId = documentId;
        this.cdr.detectChanges();
      },
    });
  }

    async makeAssetPublic(cutdown: CutdownRestriction){
      this.accessManagementCutDownActions.
      openPublicDialog(cutdown.documentId as string)
        .subscribe({
          next: () => {
            cutdown.documentId = undefined;
            cutdown.permissions = undefined;
            this.cdr.detectChanges();
          }
    });
    }



}

export interface CutDownDisplayHandler {
  handleRequest: (context: CutdownDisplayContext) => CutdownDisplayContext;
}

export class CutdownDisplayContext {
  displayCutdowButton?: boolean;
  handled: boolean = false;
  asset: Original;
  handledReason?: string;

  constructor(asset: Original) {
    this.asset = asset;
  }
}

export class MockDispayHandler implements CutDownDisplayHandler {
  isMockProfile: boolean;

  constructor(env: string) {
    this.isMockProfile = env === 'mock';
  }

  handleRequest(context: CutdownDisplayContext): CutdownDisplayContext {
    return this.isMockProfile ? enrichContext(context, { displayCutdowButton: this.isMockProfile, handled: this.isMockProfile, handledReason: 'mock always shows button' }) : context;
  }
}

export class AssetStateDispayHandler implements CutDownDisplayHandler {
  handleRequest(context: CutdownDisplayContext): CutdownDisplayContext {
    return context.asset.state === AssetState.ENDED ? enrichContext(context, { displayCutdowButton: true, handled: true, handledReason: 'live event is ended' }) : context;
  }
}

export class ApprovedDispayHandler implements CutDownDisplayHandler {
  handleRequest(context: CutdownDisplayContext): CutdownDisplayContext {
    return context.asset.approved ? context : enrichContext(context, { displayCutdowButton: true, handled: true, handledReason: 'asset is not approved' });
  }
}

export class MissingRenditionsDispayHandler implements CutDownDisplayHandler {
  handleRequest(context: CutdownDisplayContext): CutdownDisplayContext {
    return context.asset?.renditions?.length > 0 ? context : enrichContext(context, { displayCutdowButton: false, handled: true, handledReason: 'there are no renditions' });
  }
}

export class ClipDispayHandler implements CutDownDisplayHandler {
  handleRequest(context: CutdownDisplayContext): CutdownDisplayContext {
    return context.asset?.original ? enrichContext(context, { displayCutdowButton: false, handled: true, handledReason: 'asset is a clip' }) : context;
  }
}

export class MissingAssetHandler implements CutDownDisplayHandler {
  handleRequest(context: CutdownDisplayContext): CutdownDisplayContext {
    return context.asset ? context : enrichContext(context, { displayCutdowButton: false, handled: true, handledReason: 'asset does not exist' });
  }
}

export class CompositeHandler implements CutDownDisplayHandler {
  chain: CutDownDisplayHandler[];
  constructor(handlers: CutDownDisplayHandler[]) {
    this.chain = handlers;
  }

  handleRequest(context: CutdownDisplayContext): CutdownDisplayContext {
    const selectedLink: CutDownDisplayHandler | undefined = this.chain.find((link) => link.handleRequest(context).handled);
    const resolved: CutdownDisplayContext | undefined = selectedLink?.handleRequest(context);
    return selectedLink && resolved ? enrichContext(context, resolved) :
      enrichContext(context, { displayCutdowButton: true, handled: true, handledReason: 'default show button' });
  }
}

const enrichContext = (existingContext: CutdownDisplayContext, updates: Partial<CutdownDisplayContext>): CutdownDisplayContext => {
  return { ...existingContext, ...updates };
};

export interface ChainDefinition {
  missingAssetHandler: MissingAssetHandler;
  mockHandler: MockDispayHandler;
  assetStatusHandler: AssetStateDispayHandler;
  approvedAssetHandler: ApprovedDispayHandler;
  missingRenditionsHandler: MissingRenditionsDispayHandler;
  clipDisplayHandler: ClipDispayHandler;
}

export const handlerFactory = (overrides: Partial<ChainDefinition>): CutDownDisplayHandler => {
  const propotypeChain: ChainDefinition = {
        mockHandler: new MockDispayHandler('not mock'),
        assetStatusHandler: new AssetStateDispayHandler(),
        approvedAssetHandler: new ApprovedDispayHandler(),
        missingRenditionsHandler: new MissingRenditionsDispayHandler(),
        clipDisplayHandler: new ClipDispayHandler(),
        missingAssetHandler: new MissingAssetHandler()
  };
  const chain: ChainDefinition = { ...propotypeChain, ...overrides };
  return new CompositeHandler([chain.missingAssetHandler,
                                chain.mockHandler,
                                chain.missingRenditionsHandler,
                                chain.assetStatusHandler,
                                chain.approvedAssetHandler,
                                chain.clipDisplayHandler]);
};
