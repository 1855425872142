<ng-container
  *ngIf="transferService.transferSelectedSite$ | async as transferSelectedSite">
  <button
    mat-stroked-button
    class="site-select"
    mam-firebase-ga="Open site picker"
    [mam-firebase-resource]="transferSelectedSite.name"
    [matMenuTriggerFor]="sitesMenu">
    <mat-icon class="exports-domain-icon">domain</mat-icon>
    <span>{{transferSelectedSite.siteId | uppercase}}</span>
    <mat-icon iconPositionEnd class="arrow">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #sitesMenu="matMenu">
    <button *ngFor="let site of selectableSites$ | async" mat-menu-item
      (click)="transferService.selectTransferSite(site)" mam-firebase-ga="Select a site"
      [mam-firebase-resource]="site.name">
      <mat-icon>domain</mat-icon>
      <span>{{site.siteId | uppercase}}</span>
    </button>
  </mat-menu>
</ng-container>

<div class="actions-search-bar-container">
  <div class="search-bar-container"
       [class.loading]="exportService.progressbar.visible$ | async">
    <mat-form-field appearance="outline"
      class="search-bar"
      subscriptSizing="dynamic"
    >
      <mat-icon matPrefix>search</mat-icon>
      <input type="text"
         matInput
         placeholder="Search by title"
         aria-label="Search by title"
         autocomplete="off"
         [formControl]="exportService.search">

      <button
        *ngIf="exportService.search.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        [mam-firebase-ga]="'Clear {{exportType}} export query'"
        (click)="exportService.search.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="calendar-navigation">
      <button mat-flat-button class="today"
        [disabled]="exportService.todayButtonDisabled$ | async"
        [mam-firebase-ga]="'Go to today in {{exportType}} export monitor'"
        aria-label="Today"
        (click)="exportService.today()">
        Today
      </button>
      <button class="previous"
        matTooltip="Previous day"
        matTooltipPosition="above"
        mat-icon-button
        [mam-firebase-ga]="'Go to previous day in {{exportType}} export monitor'"
        aria-label="Previous day"
        (click)="exportService.previousDay()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button class="next"
        matTooltip="Next day"
        matTooltipPosition="above"
        mat-icon-button
        [mam-firebase-ga]="'Go to next day in {{exportType}} export monitor'"
        aria-label="Next day"
        [disabled]="exportService.todayButtonDisabled$ | async"
        (click)="exportService.nextDay()">
        <mat-icon>chevron_right</mat-icon>
      </button>
      <span class="date-label">{{exportService.selectedDateLabel$ | async}}</span>
    </div>
  </div>
</div>


<ng-container *ngIf="exportService.items$ | async as items">
  @if (showListView) {
    <!-- ListViews table - feature flag ON -->
    <mam-ui-table id="ExportMonitorTable"
      [cols]="cols"
      [data]="items"
      [options]="{
        tableInfo: {
          name: tableIdName,
          id: tableId
          },
        multi: true,
        loading: (transferService.processing$ | async) || false,
        mainColIdx: 1
      }"
      tableType="site-selector"
      [activeSort]="activeSort"
      (sortClick)="onSort($event, items)"
      (rowClick)="onRowClick($event)"
      [emptyMessage]="emptyMessage"
    >
      <ng-template #cellTpl let-row let-col="col">
        <!-- Icon -->
        <ng-container *ngIf="col.cellTpl === 'iconTpl'">
          <div class="table-icon">
            <mat-icon *ngIf="!isRowError(row.status)">insert_drive_file</mat-icon>
            <mat-icon *ngIf="isRowError(row.status)"
              class="error"
              [matTooltip]="row.errorMessage"
              matTooltipClass="close-from-top">
              error
            </mat-icon>
          </div>
        </ng-container>

        <!-- Title -->
        <ng-container *ngIf="col.cellTpl === 'titleTpl'">
          <div class="main-column">
            <div class="icon-with-text">
              <span matTooltip="{{row.title}}"
                matTooltipPosition="below"
                matTooltipClass="mobile-tooltip">
                {{row.title}}
              </span>
            </div>
          </div>
        </ng-container>

        <!-- Clip bin -->
        <ng-container *ngIf="col.cellTpl === 'clipBinTpl'">
          <mam-export-monitor-clipbin-cell [title]="row.title" [clipBinName]="row.clipBinName"></mam-export-monitor-clipbin-cell>
        </ng-container>

        <!-- Duration -->
        <ng-container *ngIf="col.cellTpl === 'durationTpl'">
            {{ displayDuration ? (row.duration | durationOrTime) : '--'}}
        </ng-container>

        <!-- Status PFR -->
        <ng-container *ngIf="col.cellTpl === 'statusTpl'">
          <ng-container *ngIf="!isRowError(row.status)">
            <div class="icon-with-text status-column-icon">
              <ng-container *ngIf="row.status === 'Completed'">
                <mat-icon
                  class="icon-completed"
                  matTooltip="Completed"
                  matTooltipPosition="below">
                  check_circle
                </mat-icon>
                <span
                  matTooltip="{{row.exportFolder}}"
                  matTooltipPosition="below"
                  matTooltipClass="mobile-tooltip">
                  {{ row.status }}
                </span>
              </ng-container>
              <ng-container *ngIf="row.status === 'Pending'">
                <mat-icon
                  class="icon-pending"
                  matTooltip="Pending"
                  matTooltipPosition="below">
                  pending
                </mat-icon>
                <span>{{ row.status }}</span>
              </ng-container>
              <ng-container *ngIf="row.status === 'Downloading'">
                <mat-icon
                  class="icon-pending"
                  matTooltip="Downloading"
                  matTooltipPosition="below">
                  downloading
                </mat-icon>
                <span>{{ row.status }}</span>
              </ng-container>

              <ng-container
                *ngIf="row.status !== 'Completed' && row.status !== 'Pending' && row.status !== 'Downloading'">
                {{ row.status }}
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="isRowError(row.status)">
            <button mat-flat-button
                    [disabled]="isRowRetried(row)"
                    (click)="retryOnClick(row); $event.stopPropagation();"
                    mam-firebase-ga="Retry"
                    [mam-firebase-resource]="row.name"
                    [matTooltip]="row.errorMessage"
                    [ngClass]="'asset-table-button'">
                    Retry
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="col.cellTpl === 'expandTpl'">
          <button
            mat-icon-button
            mam-firebase-ga="{{isRowExpanded(row) ? 'Expand' : 'Collapse'}} transfer table row"
            [mam-firebase-resource]="row.id"
            [attr.aria-label]="isRowExpanded(row) ? 'Collapse row' : 'Expand row'">
            <mat-icon>
              {{isRowExpanded(row) ? 'expand_less' : 'expand_more'}}
            </mat-icon>
          </button>
        </ng-container>

      </ng-template>

      <ng-template #multiTpl let-row>
        <mam-transfer-details
          [task]="row.rawTask">
        </mam-transfer-details>
      </ng-template>

    </mam-ui-table>
  }
  @else {
    <!-- Standard table - feature flag OFF -->
    <table mat-table
    multiTemplateDataRows
    [trackBy]="trackByItem"
    [dataSource]="items">

      <!-- Name Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td class="main-column"
            mat-cell
            *matCellDef="let row"
            [title]="row.title">
          <div class="icon-with-text">
            <mat-icon *ngIf="!isRowError(row.status)">insert_drive_file</mat-icon>
            <mat-icon *ngIf="isRowError(row.status)"
                      class="error"
                      [matTooltip]="row.errorMessage"
                      matTooltipClass="close-from-top">
                      error
            </mat-icon>
            <span matTooltip="{{row.title}}"
                  matTooltipPosition="below"
                  matTooltipClass="mobile-tooltip">
                  {{row.title}}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- File name -->
      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File Name</th>
        <td mat-cell *matCellDef="let row;">
          <span matTooltip="{{row.fileName}}"
                matTooltipPosition="below"
                matTooltipClass="mobile-tooltip">
                {{row.fileName}}
          </span>
        </td>
      </ng-container>

      <!-- Update Date Column -->
      <ng-container matColumnDef="updateTime">
        <th mat-header-cell *matHeaderCellDef>Update Time</th>
        <td mat-cell *matCellDef="let row">
          {{row.updateTime | tzdate:'MMM d, y, h:mm a'}}
        </td>
      </ng-container>

      <!-- Export Folder -->
      <ng-container matColumnDef="exportFolder">
        <th mat-header-cell *matHeaderCellDef>Export Folder</th>
        <td mat-cell *matCellDef="let row;">
          <span matTooltip="{{row.exportFolder}}"
                matTooltipPosition="below"
                matTooltipClass="mobile-tooltip">
                {{row.exportFolder}}
          </span>
        </td>
      </ng-container>

      <!-- User / Modified by -->
      <ng-container matColumnDef="user">
        <th
          mat-header-cell
          *matHeaderCellDef>
            <mam-sort-col-btn
            [rows]="items"
            [key]="'user'"
            colName="Modified by"
            (mamSortByField)="onSortByField($event)">
              Modified by
            </mam-sort-col-btn>
        </th>
        <td mat-cell *matCellDef="let row;">
          <span matTooltip="{{row.user}}"
                matTooltipPosition="below"
                matTooltipClass="mobile-tooltip">
                {{row.user}}
          </span>
        </td>
      </ng-container>

      <!-- Clip bin -->
      <ng-container
        matColumnDef="clipBin">
        <th mat-header-cell *matHeaderCellDef>Clip Bin</th>
        <td mat-cell *matCellDef="let row;">
          <mam-export-monitor-clipbin-cell [clipBinName]="row.clipBinName"></mam-export-monitor-clipbin-cell>
        </td>
      </ng-container>

      <!-- PFR Status -->
      <ng-container matColumnDef="pfrStatus">
        <th *matHeaderCellDef mat-header-cell>PFR Status</th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="!isRowError(row.status)">
            <div class="icon-with-text status-column-icon">
              <ng-container *ngIf="row.status === 'Completed'">
                <mat-icon
                  class="icon-completed"
                  matTooltip="Completed"
                  matTooltipPosition="below">
                  check_circle
                </mat-icon>
                <span
                  matTooltip="{{row.exportFolder}}"
                  matTooltipPosition="below"
                  matTooltipClass="mobile-tooltip"
                  class="text-ellipsis">
                  {{ row.status }}
                </span>
              </ng-container>
              <ng-container *ngIf="row.status === 'Pending'">
                <mat-icon
                  class="icon-pending"
                  matTooltip="Pending"
                  matTooltipPosition="below">
                  pending
                </mat-icon>
                <span class="text-ellipsis">{{ row.status }}</span>
              </ng-container>
              <ng-container *ngIf="row.status === 'Downloading'">
                <mat-icon
                  class="icon-pending"
                  matTooltip="Downloading"
                  matTooltipPosition="below">
                  downloading
                </mat-icon>
                <span class="text-ellipsis">{{ row.status }}</span>
              </ng-container>
              <ng-container
                *ngIf="row.status !== 'Completed' && row.status !== 'Pending' && row.status !== 'Downloading'">
                <span class="text-ellipsis">{{ row.status }}</span>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="isRowError(row.status)">
            <button
              mat-flat-button
              [disabled]="isRowRetried(row)"
              (click)="retryOnClick(row); $event.stopPropagation();"
              mam-firebase-ga="Retry"
              [mam-firebase-resource]="row.name"
              [matTooltip]="row.errorMessage"
              [ngClass]="'asset-table-button'">
              Retry
            </button>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedLegacyColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedLegacyColumns;"></tr>
    </table>


    <div *ngIf="items.length === 0" class="empty-message">
      No {{exportType}} exports found.
    </div>
  }

</ng-container>
