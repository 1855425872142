export enum BreakpointType {
    XXL = 'XXL',
    XL = 'XL',
    LG = 'LG',
    MD = 'MD',
    SM = 'SM'
}

export interface BreakpointSettings {
    mediaQuery: string;
    paginationMultiplier: number;
}

export const CUSTOM_BREAKPOINTS = {
    XS_MAX: '(max-width: 360px)',
    SM_MIN: '(min-width: 400px) and (max-width: 719px)',
    MD_MIN: '(min-width: 720px) and (max-width: 959px)',
    LG_MIN: '(min-width: 960px) and (max-width: 1199px)',
    XL_MIN: '(min-width: 1200px) and (max-width: 1599px)',
    XXL_MIN: '(min-width: 1600px)'
};

export const BreakpointConfig: Record<BreakpointType, BreakpointSettings> = {
    [BreakpointType.XXL]: { mediaQuery: CUSTOM_BREAKPOINTS.XXL_MIN, paginationMultiplier: 6 },
    [BreakpointType.XL]: { mediaQuery: CUSTOM_BREAKPOINTS.XL_MIN, paginationMultiplier: 5 },
    [BreakpointType.LG]: { mediaQuery: CUSTOM_BREAKPOINTS.LG_MIN, paginationMultiplier: 4 },
    [BreakpointType.MD]: { mediaQuery: CUSTOM_BREAKPOINTS.MD_MIN, paginationMultiplier: 3 },
    [BreakpointType.SM]: { mediaQuery: CUSTOM_BREAKPOINTS.SM_MIN, paginationMultiplier: 2 }
};

export const FIXED_ROW_NUMBER = 4;
