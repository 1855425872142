<mat-card
    [mam-firebase-resource]="folder.id"
    [ngClass]="{
    'folder_card--list': viewMode === 'list',
    'folder_card--grid mam-elevation-1': viewMode === 'grid'
  }"
    appearance="outlined"
    class="folder_card"
    mam-firebase-ga="Open clip folder from its preview"
    (click)="navigateToFolder(folder.id)"
>
    <div class="container-thumbnails">
        <div class="card-header-image hidden-on-mobile">
            @if (clips && clips.length > 0) {
              @for (asset of clips | slice:0:3; track trackClip($index, asset)) {
                <mam-asset-thumbnail [asset]="asset"> </mam-asset-thumbnail>
              }
            }
        </div>
        <!-- Show all active -->
        @if (showAllFolders) {
        <div class="folder-owner">
            @if ((folder.ownerName && folder.ownerName.length) || (folder.owner && folder.owner.length)) {
            <span class="folder-owner-icon"
                >{{ (folder!.ownerName || folder!.ownerName?.length ? folder.ownerName : folder.owner)![0].toUpperCase()
                }}</span
            >
            } @else {
            <mat-icon class="folder-owner-icon" role="img" aria-hidden="true"> person </mat-icon>
            }
            <p class="folder-owner-text" title="{{ folder.createdBy }}">
                {{ folder.ownerName || folder.ownerName?.length ? folder.ownerName : folder.owner }}
            </p>
        </div>
        }
    </div>

    <div class="card-content">
        <div class="card-content-width">
            <div
                [attr.title]="folder.displayName || folder.name"
                class="card-content-title"
            >
                <div class="folder-title">
                    <mat-icon class="folder-title-icon">folder</mat-icon>
                    <p class="folder-title-text">{{ folder.displayName || folder.name }}</p>
                </div>
            </div>
            <div class="card-footer">
                <!-- Breadcrumb Icon -->
                <mam-breadcrumb
                  *ngIf="(folder.children?.length !== 0) && (folder?.level !== 0)"
                  class="card-footer__breadcrumb"
                  (isExpandedChange)="onIsExpandedChange($event)"
                  [resource]="folder"
                ></mam-breadcrumb>
                <!-- Item count -->
                <div [ngPlural]="getAssetCount(folder)" class="card-content-subtitle">
                    <ng-template ngPluralCase="=0">No items</ng-template>
                    <ng-template ngPluralCase="=1">1 item</ng-template>
                    <ng-template ngPluralCase="other">{{ folder.directChildrenCount }} items</ng-template>
                    <ng-template ngPluralCase="=-1"></ng-template>
                </div>
            </div>
        </div>

        @if (shouldShow3DotMenu()) {
        <button
            mat-icon-button
            class="folder-button main__icon--size"
            [matMenuTriggerFor]="menu"
            aria-label="Open folder Menu"
            (click)="$event.stopPropagation()"
            mam-firebase-ga="Open folder menu"
            [mam-firebase-resource]="folder.id"
        >
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="folder-options-menu">
            @if (shouldShowOtherButtons()) {
            <button
                mat-menu-item
                (click)="openRenameFolder()"
                mam-firebase-ga="Open clip folder rename dialog"
                [mam-firebase-resource]="folder.id"
            >
                Rename
            </button>
            @if (shouldShowMoveButton()) {
            <button
                mat-menu-item
                (click)="openMoveFolder()"
                mam-firebase-ga="Open clip folder move dialog"
                [mam-firebase-resource]="folder.id"
            >
                Move
            </button>
            }
            <button
                mat-menu-item
                (click)="openDeleteFolder()"
                class="delete-button"
                mam-firebase-ga="Select folder deletion"
                [mam-firebase-resource]="folder.id"
            >
                Delete folder
            </button>
            }
        </mat-menu>
        }
    </div>
</mat-card>
