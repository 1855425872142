<div class="breadcrumb-container">
  <div class="breadcrumb-inner" [ngClass]="{ 'expanded': isExpanded }">
    <mat-chip-set
      aria-label="Folder Location"
      class="breadcrumb-icon"
      [ngClass]="{ 'd-none': folderLevel === 0 }"
      (click)="onClickLocationIcon($event)"
      >
      <mat-chip>
        <div class="breadcrumb-content">
          <mat-icon class="material-icons-outlined">location_on</mat-icon>
        </div>
      </mat-chip>
    </mat-chip-set>
    @if (isExpanded) {
      <div
        (click)="onClickPath($event)"
        class="breadcrumb-text"
        [ngClass]="{'hover-text': shouldTrunc}"
        >
        <img class="folder-open-icon" src="../../images/folder_open.svg" alt="Folder Open Icon Light" />
        <span class="text-content" [ngClass]="{'p-left': shouldTrunc}"> {{ breadcrumbPathTrunc }} </span>
      </div>
    }
  </div>
</div>
