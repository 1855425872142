<div class="clip-bin-search-panel">
  <!-- Title and create bin button -->
  <div class="header-container">
    <mam-clip-bin-owner-selector
        *ngIf="clipbinsOwner$ | async as clipbinsOwner"
        [owner]="clipbinsOwner"
        (ownerChange)="clipbinsOwner$.next($event)">
    </mam-clip-bin-owner-selector>

    <div class="create-button-container" matTooltip="Enter bin name in searchbar" [matTooltipDisabled]="false">
      <button
          class="create-bin-button"
          mat-button
          mam-firebase-ga="Create clipbin from clipbins search"
          [disabled]="createDisabled()"
          [matTooltip]="createDisabled() ? 'Enter bin name in searchbar and ensure it has no special characters or reserved words' : ''"
          (click)="createBin()">
        Create bin
      </button>
    </div>
  </div>
  <div class="error-container">
    <p
      class="error"
      *ngIf="hasNotAllowedCharacters$ | async"
    >Special characters !&#64;#$%^&*() are not allowed.</p>

    <p
      class="error"
      *ngIf="wordNotAllowed$ | async"
    >The word 'Home' is not allowed as a bin or folder name.</p>
  </div>

  <!-- Search input -->
  <mat-form-field class="clip-bin-search-form dialog__input-search--spacing" appearance="outline" subscriptSizing="dynamic">
    <mat-icon class="search__icon--spacing" matPrefix>search</mat-icon>
    <input
        #searchInput
        cdkFocusInitial
        matInput
        [formControl]="searchControl"
        placeholder="Search clip bins or create clip bin"
        autocomplete="off"
        aria-label="Search clip bins"
        (keydown)="$event.stopPropagation()"/>
  </mat-form-field>

  <!-- List of clipbins -->
  <ng-container *ngIf="bins$ | async as bins">
    <div infinite-scroll
        *ngIf="bins.length; else noBins;"
        (nearBottom)="onNearBottomForBinsArea()"
        #scrollableView
        class="clipbins-search-results">
      <ng-container *ngFor="let clipbin of bins; trackBy: trackName">
        <ng-container
            *ngTemplateOutlet="isMultipleOptions ? clipbinCheckboxItem : clipbinItem; context: {clipbin: clipbin}">
        </ng-container>
      </ng-container>
    </div>

    <!-- Case no clipbin (at all or from search)  -->
    <ng-template #noBins>
      <div class="no-bins">No clip bin found or created.</div>
    </ng-template>
  </ng-container>
</div>

<!-- Template for a clipbin row with checkbox for multi-select -->
<ng-template #clipbinCheckboxItem let-clipbin="clipbin">
  <div *ngIf="clipbin">
    <mat-checkbox
        [aria-label]="clipbin.title"
        [value]="clipbin"
        color="primary"
        [checked]="selectedBins.has(clipbin.name)"
        (change)="onChange(clipbin.name, $event.checked)">
      <div class="clipbin-titles">
        <h1 [attr.title]="clipbin.title">{{ clipbin.title }}</h1>
        <h2>{{ clipbin.assetCount }} clips</h2>
      </div>
    </mat-checkbox>
  </div>
  <mat-divider></mat-divider>
</ng-template>

<!-- Template for a clipbin row with single-select marker -->
<ng-template #clipbinItem let-clipbin="clipbin">
  <div class="clipbin-item"
      (click)="onChange(clipbin.name, true)">
    <mat-icon
        [class.selected]="selectedBins.has(clipbin.name)">
      done
    </mat-icon>
    <div class="clipbin-titles">
      <h1 [attr.title]="clipbin.title">{{ clipbin.title }}</h1>
      <h2>{{ clipbin.assetCount }} clips</h2>
    </div>
  </div>
  <mat-divider></mat-divider>
</ng-template>
