<!-- 1. Trigger change detection when on of these changes. -->
<ng-container *ngIf="{
  pendingApproval: stagingService.pendingApproval$ | async,
  approvedAssetSet: stagingService.approvedItemSet$ | async,
  isEditing: stagingService.isEditing$ | async,
  selectedAssetSet: stagingService.selectedAssetSet$ | async,
  activeItems: stagingService.activeItems$ | async,
  activeItemSet: stagingService.activeItemSet$ | async
} as tplState">

  <!-- 2. Check for filteredAssets data -->
  <ng-container *ngIf="filteredAssets">

    <!-- 3. Validate assets and get selected assets -->
    <ng-container
      *ngIf="tplState.pendingApproval &&
        tplState.approvedAssetSet &&
        tplState.isEditing != null &&
        tplState.selectedAssetSet &&
        tplState.activeItemSet &&
      getSelectionInfo(filteredAssets, tplState.selectedAssetSet) as selectionInfo"
    >

      <!-- Actions / Search bar -->
      <div class="actions-search-bar-container">
        <div class="action-bar-container">
          <div class="video-actions action_checkbox">

            <!-- (De)Select all toggle. -->
            <mat-checkbox
                color="primary"
                [disabled]="!filteredAssets.length || loading || tplState.isEditing"
                (change)="selectionInfo.areAll
                            ? stagingService.select([])
                            : stagingService.select(selectionInfo.selectableItems)"
                [checked]="selectionInfo.areAll"
                [indeterminate]="selectionInfo.indeterminate"
                [aria-label]="tableUtils.allCheckboxLabel(selectionInfo)"
                [matTooltip]="tableUtils.allCheckboxLabel(selectionInfo)"
                matTooltipPosition="above"
                mam-firebase-ga="Toggle selection for all assets in live staging">
            </mat-checkbox>

          <!-- Actions for selected assets. -->
          <ng-container *ngIf="selectionInfo.areAny">
            <!-- Approve -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="approve-selected main__icon--size"
                aria-label="Approve selected assets"
                matTooltip="Approve selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Approve selected assets in live staging"
                (click)="approve(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">check_circle</mat-icon>
            </button>

            <!-- Add to clip bin -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="add-selected-to-bins main__icon--size"
                aria-label="Add selected assets to clip bins"
                matTooltip="Add selected assets to clip bins"
                matTooltipPosition="above"
                mam-firebase-ga="Add selected assets to clip bins in live staging"
                (click)="addClipsToBins(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">add_box</mat-icon>
            </button>

            <!-- Edit -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="edit-selected main__icon--size"
                aria-label="Edit selected assets"
                matTooltip="Edit selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Bulk edit in live staging"
                (click)="edit(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
            </button>

            <!-- On-prem TTL Extension -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="extend-ttl-selected main__icon--size"
                aria-label="Extend TTL on selected assets"
                matTooltip="Extend TTL on selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Extend TTL on selected assets in live staging"
                (click)="extendAssetsTtl(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">more_time</mat-icon>
            </button>

            <!-- Access Management Actions -->
            <div class="bulk-actions-access" *ngIf="isEnabledAccessManagement && authService.isAdmin">
              <button
              mam-firebase-ga="Add users for restriction"
              [disabled]="loading || tplState.isEditing" id="add-users" (click)="addUsersOnSelected(); $event.stopPropagation()" matTooltip="Add users" mat-icon-button>
                  <mat-icon>person_add</mat-icon>
              </button>
              <button
              mam-firebase-ga="Make public"
              [disabled]="loading || tplState.isEditing" id="make-public" (click)="makePublicSelected(); $event.stopPropagation()" matTooltip="Make public" mat-icon-button>
                  <mat-icon>public</mat-icon>
              </button>
            </div>

            <!-- On-prem purge -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                (click)="purgeAssets(selectionInfo.selectedItems)"
                class="purge-selected main__icon--size"
                aria-label="Delete on-prem files"
                matTooltip="Delete on-prem files"
                matTooltipPosition="above"
                mam-firebase-ga="Purge live staging selection">
              <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
            </button>

            <!-- Delete -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="delete-selected main__icon--size"
                aria-label="Delete selected assets"
                matTooltip="Delete selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Delete selected assets in live staging"
                (click)="deleteAssets(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
            </button>
          </ng-container>
        </div>

        <div class="video-actions">
          <button
              [disabled]="loading || tplState.isEditing"
              class="refresh-button main__icon--size"
              mat-icon-button
              aria-label="Refresh the list"
              matTooltip="Refresh the list"
              matTooltipPosition="above"
              mam-firebase-ga="Refresh table in live staging"
              (click)="refreshTable()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>

        <div class="search-bar-container">
          <mat-form-field
              appearance="outline"
              class="search-bar"
              subscriptSizing="dynamic"
              [class.disabled]="tplState.isEditing">
            <mat-icon matPrefix>search</mat-icon>
            <input type="text"
                [readonly]="tplState.isEditing"
                matInput
                [formControl]="search"
                placeholder="Search live assets"
                aria-label="Search live assets"
                autocomplete="off">
            <button [disabled]="tplState.isEditing"
                *ngIf="search.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                mam-firebase-ga="Clear search query in live staging"
                (click)="search.setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div class="calendar-navigation">
            <button
                mat-flat-button
                class="today"
                [disabled]="tplState.isEditing || (todayButtonDisabled$ | async)"
                mam-firebase-ga="Go to today in live staging"
                aria-label="Today"
                (click)="today()">
              Today
            </button>
            <button
                [disabled]="tplState.isEditing"
                class="previous"
                matTooltip="Previous day"
                matTooltipPosition="above"
                mat-icon-button
                mam-firebase-ga="Go to previous day in live staging"
                aria-label="Previous day"
                (click)="$event.stopPropagation(); previousDay()">
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
                [disabled]="tplState.isEditing"
                id="ButtonNextDay"
                class="next"
                matTooltip="Next day"
                matTooltipPosition="above"
                mat-icon-button
                mam-firebase-ga="Go to next day in live staging"
                aria-label="Next day"
                (click)="$event.stopPropagation(); nextDay()">
              <mat-icon>chevron_right</mat-icon>
            </button>
            <span class="date-label">{{selectedDateLabel$ | async}}</span>
          </div>
        </div>
      </div>

      @if (showListView) {
        <!-- ListViews table - feature flag ON -->
        <mam-ui-table id="LiveStagingTable"
          [cols]="cols"
          [data]="filteredAssets || []"
          [options]="{
            tableInfo: {
              name: 'liveStagingTable',
              id: tableId
            },
            multi: true,
            mainColIdx: 1
          }"
          tableType="site-selector"
          emptyMessage="No assets available"
          [expandedRows]="expandedRows"
          [selectedRows]="tplState.selectedAssetSet"
          [activeRows]="tplState.activeItemSet"
          [approvedRows]="tplState.approvedAssetSet"
          [activeSort]="activeSort"
          (sortClick)="onSort($event)"
          (rowClick)="onRowClick($event,tplState.selectedAssetSet)"
          (containerResize)="containerSize = $event"
        >

          <ng-template #headerTpl let-col>
            <ng-container *ngIf="col.headerTpl === 'sourceTpl'">
              <mam-multiselect-table-header headerName="Source" [options]="sourceFilterOptions"
                (toggle)="onSourceFilterChanged($event)">
              </mam-multiselect-table-header>
            </ng-container>
          </ng-template>

          <ng-template #cellTpl let-row let-col="col">
            <ng-container *ngIf="col.cellTpl === 'selectTpl'">
              <div (click)="
                $event.stopPropagation();
                toggleSelection(row, tplState.selectedAssetSet, $event.shiftKey)">
                <mat-checkbox
                  *ngIf="canBeSelected(row)"
                  [disabled]="tplState.isEditing"
                  color="primary"
                  (click)=" $event.stopPropagation()"
                  (change)="toggleSelection(row, tplState.selectedAssetSet)"
                  [checked]="tplState.selectedAssetSet.has(row.name)"
                  [aria-label]="tableUtils.checkboxLabel(row, tplState.selectedAssetSet)"
                  mam-firebase-ga="Toggle asset checkbox in live staging"
                  [mam-firebase-resource]="row.name">
                </mat-checkbox>
              </div>
            </ng-container>

            <!-- Title -->
            <ng-container *ngIf="col.cellTpl === 'titleTpl'">
              <div class="icon-with-text">
                <mam-asset-info-icon-button
                    [asset]="row"
                    [icon]="getStatusIcon(row)"
                    [redSpinner]="true"
                    [showIconForExternalUsers]="true"
                    [tooltip]="getStatusTooltip(row)">
                </mam-asset-info-icon-button>
                <span matTooltipClass="mobile-tooltip" matTooltip="{{getMetadataValue(row, COLUMN_TO_METADATA_KEY.title)}}"
                    [title]="getMetadataValue(row, COLUMN_TO_METADATA_KEY.title)">
                  {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.title)}}
                </span>
              </div>
            </ng-container>

            <!-- Source-->
            <ng-container *ngIf="col.cellTpl === 'sourceTpl'">
              {{row.source || '-'}}
            </ng-container>

            <!-- Type -->
            <ng-container *ngIf="col.cellTpl === 'typeTpl'">
              {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.type)}}
            </ng-container>

            <!-- Description -->
            <ng-container *ngIf="col.cellTpl === 'descriptionTpl'">
              {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.description)}}
            </ng-container>

            <!-- Sport -->
            <ng-container *ngIf="col.cellTpl === 'sportTpl'">
              {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.sport)}}
            </ng-container>

             <!-- Permission Column -->
            <ng-container *ngIf="col.cellTpl === 'permissionTpl'">
              <div class="restriction-cell-container">
                @if (row.permissionsDocumentId) {
                   <mam-access-restriction-icon size="small"></mam-access-restriction-icon>
                   <span class="mat-mdc-menu-item-text"> Restrict </span>
                 } @else {
                   <mat-icon>public</mat-icon><span> Public</span>
                 }
              </div>
            </ng-container>

            <!-- Start time -->
            <ng-container *ngIf="col.cellTpl === 'startTpl'">
              {{row.eventStartTime | tzdate : 'HH:mm (Z)'}}
            </ng-container>

            <!-- End time -->
            <ng-container *ngIf="col.cellTpl === 'endTpl'">
              {{row.eventEndTime | tzdate : 'HH:mm (Z)'}}
            </ng-container>

            <!-- Duration -->
            <ng-container *ngIf="col.cellTpl === 'durationTpl'">
                {{row?.duration | duration  }}
            </ng-container>

            <!-- Camera -->
            <ng-container *ngIf="col.cellTpl === 'cameraTpl'">
              {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.camera)}}
            </ng-container>

            <!-- Courtesy -->
            <ng-container *ngIf="col.cellTpl === 'courtesyTpl'">
              {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.courtesy)}}
            </ng-container>

            <!-- Cutdown -->
            <ng-container *ngIf="col.cellTpl === 'cutdownTpl'">
              {{formatCutdownStatus(row)}}
            </ng-container>

            <!-- Expand row -->
            <ng-container *ngIf="col.cellTpl === 'expandTpl'">
              <div>
                <button
                  (click)="$event.stopPropagation(); this.toggleRowExpanded(row)"
                  mat-icon-button
                  mam-firebase-ga="{{isRowExpanded(row) ? 'Expand' : 'Collapse'}} asset table row"
                  [mam-firebase-resource]="row.name"
                  [attr.aria-label]="isRowExpanded(row) ? 'Collapse row' : 'Expand row'">
                  <mat-icon>
                    {{isRowExpanded(row) ? 'expand_less' : 'expand_more'}}
                  </mat-icon>
                </button>
              </div>
            </ng-container>

            <ng-template #multiTpl let-row>
              <mam-cut-down-details
                *ngIf="isRowExpanded(row)"
                [asset]="row"
                [containerWidth]="containerSize"
                [cutdownCache]="cutdownCache"
              >
                </mam-cut-down-details>
            </ng-template>

          </ng-template>

        </mam-ui-table>
      }
      @else {
        <!-- Standard table - feature flag OFF -->
        <table
          mat-table
          matSort
          class="legacy-table"
          [matSortDisableClear]="true"
          multiTemplateDataRows
          [trackBy]="tableUtils.trackByName"
          [dataSource]="filteredAssets"
          [matSortActive]="currentSort.active"
          [matSortDirection]="currentSort.direction"
          (matSortChange)="sortData($event)"
          [class.has-selection]="selectionInfo.areAny">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <!-- Expand checkbox clickable area by also listening to td.click -->
            <td
              mat-cell
              *matCellDef="let asset"
              (click)="
                  $event.stopPropagation();
                  toggleSelection(asset, tplState.selectedAssetSet, $event.shiftKey)">
              <mat-checkbox
                *ngIf="canBeSelected(asset)"
                [disabled]="tplState.isEditing"
                color="primary"
                [checked]="tplState.selectedAssetSet.has(asset.name)"
                [aria-label]="tableUtils.checkboxLabel(asset, tplState.selectedAssetSet)"
                mam-firebase-ga="Toggle asset checkbox in live staging"
                [mam-firebase-resource]="asset.name">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Title Column -->
          <ng-container matColumnDef="title">
            <th
              mat-header-cell
              mat-sort-header
              mam-firebase-ga="Sort by title in live staging"
              *matHeaderCellDef>
            <span matTooltipClass="mobile-tooltip"
                  [matTooltip]="getColumnTitleTooltip(COLUMN_TO_METADATA_KEY.title)">
              Title
            </span>
            </th>
            <td
              mat-cell
              *matCellDef="let asset"
              class="main-column">
              <div class="icon-with-text">
                <mam-asset-info-icon-button
                  [asset]="asset"
                  [icon]="getStatusIcon(asset)"
                  [showIconForExternalUsers]="true"
                  [tooltip]="getStatusTooltip(asset)">
                </mam-asset-info-icon-button>
                <span matTooltipClass="mobile-tooltip"
                      matTooltip="{{getMetadataValue(asset, COLUMN_TO_METADATA_KEY.title)}}"
                      [title]="getMetadataValue(asset, COLUMN_TO_METADATA_KEY.title)">
                {{ getMetadataValue(asset, COLUMN_TO_METADATA_KEY.title) }}
              </span>
              </div>
            </td>
          </ng-container>

          <!-- Source Column -->
          <ng-container matColumnDef="source">
            <th
              mat-header-cell
              *matHeaderCellDef>
              <mam-sort-col-btn
                [rows]="filteredAssets"
                [key]="'source'"
                colName="Source"
                (mamSortByField)="onSortByField($event)">
                Source
              </mam-sort-col-btn>
            </th>
            <td
              mat-cell
              *matCellDef="let asset"
              [title]="asset.source">
              {{ asset.source }}
            </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="type">
            <th
              mat-header-cell
              mat-sort-header
              mam-firebase-ga="Sort by type in live staging"
              [matTooltip]="getColumnTitleTooltip(COLUMN_TO_METADATA_KEY.type)"
              *matHeaderCellDef>
              Type
            </th>
            <td
              mat-cell
              *matCellDef="let asset"
              [title]="getMetadataValue(asset, COLUMN_TO_METADATA_KEY.type)">
              {{ getMetadataValue(asset, COLUMN_TO_METADATA_KEY.type) }}
            </td>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <th
              mat-header-cell
              mat-sort-header
              mam-firebase-ga="Sort by description in live staging"
              [matTooltip]="getColumnTitleTooltip(COLUMN_TO_METADATA_KEY.description)"
              *matHeaderCellDef>
              Description
            </th>
            <td
              mat-cell *matCellDef="let asset"
              [title]="getMetadataValue(asset, COLUMN_TO_METADATA_KEY.description)">
              {{ getMetadataValue(asset, COLUMN_TO_METADATA_KEY.description) }}
            </td>
          </ng-container>

          <!-- Sport Column -->
          <ng-container matColumnDef="sport">
            <th mat-header-cell
                mat-sort-header
                mam-firebase-ga="Sort by sport in live staging"
                [matTooltip]="getColumnTitleTooltip(COLUMN_TO_METADATA_KEY.sport)"
                *matHeaderCellDef>
              Sport
            </th>
            <td
              mat-cell
              *matCellDef="let asset"
              [title]="getMetadataValue(asset, COLUMN_TO_METADATA_KEY.sport)">
              {{ getMetadataValue(asset, COLUMN_TO_METADATA_KEY.sport) }}
            </td>
          </ng-container>

        <!-- Permission Column -->
        <ng-container matColumnDef="permission" *ngIf="isEnabledAccessManagement">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Permission
          </th>
          <td mat-cell *matCellDef="let asset">
            <div class="restriction-cell-container">
              @if (asset.permissionsDocumentId){
                 <mam-access-restriction-icon size="small"></mam-access-restriction-icon>
                 <span class="mat-mdc-menu-item-text"> Restrict </span>
               } @else {
                 <mat-icon>public</mat-icon><span> Public</span>
               }
             </div>
          </td>
        </ng-container>

        <!-- Event Start Column -->
        <ng-container matColumnDef="start">
          <th
            mat-header-cell
            mat-sort-header
            mam-firebase-ga="Sort by event start in live staging"
            matTooltip="Live event scheduled start time"
            *matHeaderCellDef>
            Start
          </th>
          <!-- Title attribute: Monday, June 15, 2015 at 21:03:01 GMT-07:00.  -->
          <td
            mat-cell
            *matCellDef="let asset"
            [title]="asset.eventStartTime | tzdate : 'EEEE, MMMM d, y, HH:mm:ss zzzz'">
            <!-- e.g. 21:03 (-0700)-->
            {{ asset.eventStartTime | tzdate : 'HH:mm (Z)' }}
          </td>
        </ng-container>

        <!-- Event End Column -->
        <ng-container matColumnDef="end">
          <th
            mat-header-cell
            mat-sort-header
            mam-firebase-ga="Sort by event end in live staging"
            matTooltip="Live event scheduled stop time"
            *matHeaderCellDef>
            End
          </th>
          <!-- Title attribute: Monday, June 15, 2015 at 22:03:01 GMT-07:00.  -->
          <td
            mat-cell
            *matCellDef="let asset"
            [title]="asset.eventEndTime | tzdate : 'EEEE, MMMM d, y, HH:mm:ss zzzz'">
            <!-- e.g. 22:03 (-0700)-->
            {{ asset.eventEndTime | tzdate : 'HH:mm (Z)' }}
          </td>
        </ng-container>

        <!-- Camera Label Column -->
        <ng-container matColumnDef="camera">
          <th
            mat-header-cell
            mat-sort-header
            mam-firebase-ga="Sort by camera label in live staging"
            [matTooltip]="getColumnTitleTooltip(COLUMN_TO_METADATA_KEY.camera)"
            *matHeaderCellDef>
            Camera label
          </th>
          <td
            mat-cell
            *matCellDef="let asset"
            [title]="getMetadataValue(asset, COLUMN_TO_METADATA_KEY.camera)">
            {{ getMetadataValue(asset, COLUMN_TO_METADATA_KEY.camera) }}
          </td>
        </ng-container>

        <!-- Courtesy Column -->
        <ng-container matColumnDef="courtesy">
          <th
            mat-header-cell
            mat-sort-header
            mam-firebase-ga="Sort by courtesy in live staging"
            [matTooltip]="getColumnTitleTooltip(COLUMN_TO_METADATA_KEY.courtesy)"
            *matHeaderCellDef>
            Courtesy
          </th>
          <td mat-cell
              *matCellDef="let asset"
              [title]="getMetadataValue(asset, COLUMN_TO_METADATA_KEY.courtesy)">
            {{ getMetadataValue(asset, COLUMN_TO_METADATA_KEY.courtesy) }}
          </td>
        </ng-container>

        <!-- Cutdown Column -->
        <ng-container matColumnDef="cutdown">
          <th
            mat-header-cell
            mat-sort-header
            mam-firebase-ga="Sort by cutdown state in live staging"
            matTooltip="Asset cutdown status"
            *matHeaderCellDef>
            Cutdown
          </th>
          <td mat-cell
              *matCellDef="let asset"
              [title]="formatCutdownStatus(asset)">
            {{ formatCutdownStatus(asset) }}
          </td>
        </ng-container>

        <!-- Expand Column -->
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let asset;"
            mam-firebase-ga="{{isExpanded(asset) ? 'Collapse' : 'Expand'}} Cut-down Details"
            [mam-firebase-resource]="asset.name"
            (click)="$event.stopPropagation(); !tplState.isEditing && toggleExpansion(asset, tplState.activeItems)">
            <button
              *featureFlagOn="'use-copy-api-cutdown'"
              [class.disabled]="tplState.isEditing"
              mat-icon-button
              attr.aria-label="{{isExpanded(asset) ? 'Collapse' : 'Expand'}} Cutdown Details"
              matTooltip="{{isExpanded(asset) ? 'Collapse' : 'Expand'}} Cutdown Details"
              [matTooltipDisabled]="tplState.isEditing">
              <mat-icon>
                {{ isExpanded(asset) ? 'expand_less' : 'expand_more' }}
              </mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Cutdown details -->
        <ng-container matColumnDef="cutdownDetails">
          <td mat-cell
              *matCellDef="let asset;"
              [attr.colspan]="displayedColumns.length">
            <mam-cut-down-details
              *ngIf="isExpanded(asset)"
              [asset]="asset"
              [cutdownCache]="cutdownCache">
            </mam-cut-down-details>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let asset; columns: displayedColumns;"
            mam-firebase-ga="Select asset in live staging"
            [mam-firebase-resource]="asset.name"
            [class.selected]="tplState.selectedAssetSet.has(asset.name)"
            [class.active]="tplState.activeItemSet.has(asset.name)"
            [class.approved]="asset.approved"
            [class.deleted]="asset.isDeleted"
            [class.error]="hasError(asset)"
            (click)="selectOrActivate(asset, tplState.selectedAssetSet, $event.shiftKey)"
            [class.expanded]="isExpanded(asset)">
        </tr>

        <tr mat-row
            *matRowDef="let asset; columns: ['cutdownDetails'];"
            class="details"
            [hidden]="!isExpanded(asset)">
        </tr>
      </table>

        <div *ngIf="filteredAssets && filteredAssets.length === 0 && !loading" class="empty-message">
          No assets available
        </div>
      }

    </ng-container><!-- 3 -->

  </ng-container><!-- 2 -->

</ng-container><!-- 1 -->
